import axios from 'axios';

// Create an instance of Axios with default configuration
const axiosInstance = axios.create({
    //baseURL: `${process.env.REACT_APP_ANC_API}`
});

// Set the Authorization header with your authentication token
axiosInstance.defaults.headers.common['Authorization'] = `basic ${process.env.REACT_APP_ANC_API_HEADER_KEY}`;

export default axiosInstance;