import React, { useState } from "react";
import VerificationEmailImage from "../../assets/verification_email.jpg";
import { useNavigate, useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import "./EmailVerification.css";
import { emailRegex } from "../../Common/regex";
import Spinner from "react-bootstrap/Spinner";
import Footer from "../ancfooter";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { default as axios } from "../../config/axios-config";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/Verify";

const EmailVerification = () => {
  const { formName, agencyName, agentName } = useParams();
  let navigate = useNavigate();
  const styles = {
    verification__container: {
      marginTop: "35%",
    },

    resent__email: {
      width: "76%",
    },
    form: {
      display: "flex",
      flexDirection: "column",
    },
    submit__button: {
      width: "30%",
    },
  };

  const loader = (type = "3dot") => {
    if (type === "3dot") {
      return (
        <ThreeDots
          height="40"
          width="80"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperStyle={{ justifyContent: "center", paddingBottom: "10px" }}
          wrapperClass=""
          visible={true}
        />
      );
    } else {
      return (
        <Spinner animation="border" role="status" style={{ "margin-right": "20px" }}>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );
    }
  };

  const [verificationEmail, setVerificationEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleVerifyEmail = (e) => {
    e.preventDefault();

    if (verificationEmail === "" || verificationEmail.trim().length === 0) {
      setErrorMessage("Please enter a verification email");
    } else if (!verificationEmail.match(emailRegex)) {
      setErrorMessage("Please enter a valid email address");
    }
    setIsLoading(true);

    axios
      .get(`${DEVURL}/RetrievePasscode/${formName}/${verificationEmail}/${agencyName}/${agentName||null}`)
      .then((response) => {
        if (response.status === 200) {
          navigate(`/Verification?verify=${response.data}`);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="container">
      <div className="section-spacing m-lg-5">
        <div className="row">
          <div align="center">
            <Card style={{ width: "28rem", marginTop: "2rem" }}>
              <Card.Img variant="top" />
              <Card.Body>
                <Card.Title>
                  <h1>Enter an email to retrieve information</h1>
                </Card.Title>
                <Card.Subtitle className="mt-3 mb-3">
                  {/*Your account is protected with two-factor authentication. We've sent you an email. Please enter the code below.*/}
                </Card.Subtitle>
                <Card.Text>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="example...abc@gmail.com"
                    value={verificationEmail}
                    onChange={(e) => setVerificationEmail(e.target.value)}
                  />
                  {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                </Card.Text>
                <Card.Text></Card.Text>
                <Card.Text>
                  {isLoading && (
                    <div className="col-md-12">
                      <div className="product-card text-center">{loader()}</div>
                    </div>
                  )}
                  <Button variant="primary" onClick={handleVerifyEmail}>
                    Submit
                  </Button>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EmailVerification;
