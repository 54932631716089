import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import Stepper from "react-stepper-horizontal";
import Agencyheader from "../Agencyheader";
import ThankyouResponse from "../Thankyoupage";
import Footer from "../ancfooter";
import Step1 from "./Step1";
import Step2 from "./Step2";
import useAgencyInfo from "../../hooks/useAgencyInfo.hook";
import { useParams } from "react-router-dom";
import InvalidURL from "../InvalidURL/InvalidURL";
const Master = () => {
  const { agencyName, agentName } = useParams();
  const { agencyId, agentId, isValidUrl } = useAgencyInfo(agencyName, agentName);
  const [step, setStep] = useState(1);
  const steps = [{ title: "General Information" }, { title: "Contractor Information" }, { title: "Finish" }];
  var activeStep = step;
  if (step === 1) {
    activeStep = 0;
  } else {
    activeStep = activeStep - 1;
  }

  const loader = () => {
    return (
      <ThreeDots
        height="40"
        width="80"
        color="#4fa94d"
        ariaLabel="bars-loading"
        wrapperStyle={{ justifyContent: "center", paddingBottom: "10px" }}
        wrapperClass=""
        visible={true}
      />
    );
  };

  return isValidUrl === false ? (
    <InvalidURL/>
  ) : (
    isValidUrl === true && (
      <div>
        <Agencyheader />
        <div className="container">
          <div className="main-wrap">
            <h2>General Contractor Insurance Application</h2>
            <Stepper steps={steps} activeStep={activeStep} />
            {step === 1 && <Step1 stepHandler={setStep} loader={loader} agencyId={agencyId} agentId={agentId} />}
            {step === 2 && <Step2 stepHandler={setStep} loader={loader} agentId={agentId}/>}
            {step === 3 && <ThankyouResponse />}
          </div>
        </div>
        <Footer />
      </div>
    )
  );
};

export default Master;
