// reducers/index.js
import { combineReducers } from 'redux';
import someDataReducer from './someDataReducer';
import authReducer from './authReducer';

const rootReducer = combineReducers({
    someData: someDataReducer, // Replace 'someData' with your data slice
    auth: authReducer,
});

export default rootReducer;
