import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import BusinessHour from "../../Common/BusinessHour";
import { formatTelNumber, formattedDate } from "../../Common/Utils";
import { emailRegex, nameRegex } from "../../Common/regex";
import { default as axios } from "../../config/axios-config";
import PlaceComponent from "../healthcare/GooglePlace";
import useExitConfirmationHandler from "../../hooks/useExitConfirmationHandler.hook";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/Salon";
const DEVURL_VERIFY = process.env.REACT_APP_ANC_API + "/api/Verify";

const validationSchema = yup.object().shape({
  firstName: yup.string().trim().required("First name is required").matches(nameRegex, "Please enter a valid name").label("First name"),
  lastName: yup.string().trim().required("Last name is required").matches(nameRegex, "Please enter a valid name").label("Last name"),
  businessName: yup.string().trim().required("Business name is required").label("Business name"),
  dba: yup.string().nullable(true).matches(nameRegex, "Please enter a valid dba").label("DBA"),
  hoursOfOperation: yup.string().trim().typeError("Please enter a valid time").required("Hours of operation is required").label("Hours of operation"),
  businessType: yup
    .array()
    .typeError("Business type is required")
    .min(1, "Business type is required")
    .of(yup.string())
    .required("Business type is required")
    .label("Business type"),
  locationAddress: yup.string().trim().required("Location address is required").label("Location address"),
  mailingAddress: yup.string().trim().required("Mailing address is required").label("Mailing address"),
  mobileNum: yup
    .string()
    .trim()
    .required("Mobile number is required")
    .matches(/^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/, "Please enter valid mobile number")
    .label("Mobile number"),
  workNum: yup
    .string()
    .nullable(null)
    .matches(/^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[0-9]{3}[\s\-]?[0-9]{4}$|^$/, "Please enter valid work number")
    .label("Work number"),

  email: yup.string().trim().required("Email is required").matches(emailRegex, "Enter a valid email").label("Email address"),
  coverage: yup.array().typeError("Coverage is required").min(1, "Coverage is required").of(yup.string()).required("Coverage is required").label("Coverage"),
  effectiveDate: yup
    .string()
    .trim()
    .required("Effective date is required")
    .test("is-future-date", "Date must be today or future date", (value) => {
      if (!value) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate >= currentDate;
    })
    .label("Effective date"),
});

const Step1 = ({ stepHandler, loader, agencyId, agentId }) => {
  const navigate = useNavigate();
  const handleExitButton = useExitConfirmationHandler();
  const query = new URLSearchParams(useLocation().search);
  const verifyId = query.get("verifyid");
  const id = query.get("primaryid");
  const mode = query.get("mode");
  const isView = mode ? (mode === "view" ? true : false) : "";
  const [sameAsAddress, setSameAsAddress] = useState(false);
  const [garageAddress, setGarageAddress] = useState("");
  const queryParams = new URLSearchParams(window.location.search);
  const salonId = query.get("primaryid");
  const [hoursOfOperation, setHoursOfOperation] = useState(["", ""]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    control,
    watch,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      businessName: "",
      dba: "",
      hoursOfOperation: "",
      businessType: [],
      locationAddress: "",
      locationCity: "",
      locationState: "",
      locationZip: "",
      mailingAddress: "",
      mailingCity: "",
      mailingState: "",
      mailingZip: "",
      workNum: "",
      mobileNum: "",
      email: "",
      coverage: [],
      effectiveDate: null,
      agencyId: agencyId,
      agentId: agentId,
      customerId: "",
      salonId: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const [isLoading, setLoading] = useState(false);
  const [addesss, setAddress] = useState("");
  const mobileNum = watch("mobileNum");
  const workNum = watch("workNum");
  const locationCity = watch("locationCity");
  const locationState = watch("locationState");
  const locationZip = watch("locationZip");

  const getLocation = async (data) => {
    let address_components = data.address_components;
    let formatted_address = data.formatted_address;
    setValue("locationAddress", formatted_address);
    setAddress(formatted_address);
    formatted_address = formatted_address.split(",");
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        zip_code = data.long_name + zip_code;
      }
      // if (data.types.includes("postal_code_suffix")) {
      //     zip_code =  zip_code +'-'+ data.long_name;
      // }
      if (data.types.includes("locality")) {
        setValue("locationCity", data.long_name);
      }
      if (data.types.includes("administrative_area_level_1")) {
        setValue("locationState", data.long_name);
      }
    });
    setValue("locationZip", zip_code);
  };

  const getGarageAddress = async (data) => {
    let address_components = data?.address_components;
    let formatted_address = data?.formatted_address;
    setValue("mailingAddress", formatted_address);
    formatted_address = formatted_address.split(",");
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        zip_code = data.long_name + zip_code;
      }
      // if (data.types.includes("postal_code_suffix")) {
      //     zip_code =  zip_code +'-'+ data.long_name;
      // }
      if (data.types.includes("locality")) {
        setValue("mailingCity", data.long_name);
      }
      if (data.types.includes("administrative_area_level_1")) {
        setValue("mailingState", data.long_name);
      }
    });
    setValue("mailingZip", zip_code);
    return false;
  };

  const fillupStep1HotelInfo = (salon) => {
    if (salon) {
      setValue("agencyId", agencyId);
      
      setValue("customerId", salon?.customerInfo?.customerId);
      setValue("firstName", salon.customerInfo.firstName);
      setValue("lastName", salon.customerInfo.lastName);
      setValue("businessName", salon.customerInfo.businessName);
      setValue("dba", salon.customerInfo.dbaname);
      setValue("hoursOfOperation", salon.salonInfo.hoursOfOperation);
      let hrOfOperation = salon.salonInfo.hoursOfOperation;
      hrOfOperation = hrOfOperation.split("-");
      setHoursOfOperation(hrOfOperation);
      setValue("businessType", salon.salonInfo.typeOfBusiness);
      setValue("locationAddress", salon.customerInfo.locationAddress);
      setValue("locationCity", salon.customerInfo.locationCity);
      setValue("locationState", salon.customerInfo.locationState);
      setValue("locationZip", salon.customerInfo.locationZip);
      setValue("mailingAddress", salon.customerInfo.mailingAddress);
      setValue("mailingCity", salon.customerInfo.mailingCity);
      setValue("mailingState", salon.customerInfo.mailingState);
      setValue("mailingZip", salon.customerInfo.mailingZip);
      setValue("workNum", salon.customerInfo.workNum);
      setValue("mobileNum", salon.customerInfo.mobileNum);
      setValue("email", salon.customerInfo.email);
      setValue("coverage", salon.salonInfo?.coverage);
      setValue("effectiveDate", formattedDate(salon?.salonInfo?.effectiveDate));
      setAddress(salon.customerInfo.locationAddress);
      setGarageAddress(salon.customerInfo.mailingAddress);
      if (salon.customerInfo.mailingAddress === salon.customerInfo.locationAddress) {
        setSameAsAddress(true);
      }
    }
  };

  const getStep1HotelInfo = (salon) => {
    const salonInfoOb = {};
    salonInfoOb.customerId = salon.customerInfo.customerId;
    salonInfoOb.firstName = salon.customerInfo.firstName;
    salonInfoOb.lastName = salon.customerInfo.lastName;
    salonInfoOb.businessName = salon.customerInfo.businessName;
    salonInfoOb.dba = salon.customerInfo.dbaname;
    salonInfoOb.hoursOfOperation = salon.salonInfo.hoursOfOperation;
    salonInfoOb.businessType = salon.salonInfo.typeOfBusiness;
    salonInfoOb.locationAddress = salon.customerInfo.locationAddress;
    salonInfoOb.locationCity = salon.customerInfo.locationCity;
    salonInfoOb.locationState = salon.customerInfo.locationState;
    salonInfoOb.locationZip = salon.customerInfo.locationZip;
    salonInfoOb.mailingAddress = salon.customerInfo.mailingAddress;
    salonInfoOb.mailingCity = salon.customerInfo.mailingCity;
    salonInfoOb.mailingState = salon.customerInfo.mailingState;
    salonInfoOb.mailingZip = salon.customerInfo.mailingZip;
    salonInfoOb.workNum = salon.customerInfo.workNum;
    salonInfoOb.mobileNum = salon.customerInfo.mobileNum;
    salonInfoOb.email = salon.customerInfo.email;
    salonInfoOb.coverage = salon.salonInfo.coverage;
    salonInfoOb.effectiveDate = salon.salonInfo.effectiveDate;
    return salonInfoOb;
  };

  const getStep2HotelInfo = (salon) => {
    const salonInfoOb = {};
    setValue("salonId", salon.salonInfo.salonId);
    salonInfoOb.salonId = salon.salonInfo.salonId;
    salonInfoOb.yearOfConstruction = salon.salonInfo.yearOfConstruction;
    salonInfoOb.workType = salon.salonInfo.workType;
    salonInfoOb.noOfLocation = salon.salonInfo.noOfLocation;
    salonInfoOb.noOfBuildingEachLocation = salon.salonInfo.noOfBuildingEachLocation;
    salonInfoOb.noOfBeauticians = salon.salonInfo.noOfBeauticians;
    salonInfoOb.busInterruptionLimit = salon.salonInfo.busInterruptionLimit;
    salonInfoOb.signLimit = salon.salonInfo.signLimit;
    salonInfoOb.moneyNsecurity = salon.salonInfo.moneyNsecurity;
    salonInfoOb.theftLimit = salon.salonInfo.theftLimit;
    salonInfoOb.buildingValue = salon.salonInfo.buildingValue;
    salonInfoOb.equipmentValue = salon.salonInfo.equipmentValue;
    salonInfoOb.sqftspace = salon.salonInfo.sqftspace;
    salonInfoOb.partTimeEmployee = salon.salonInfo.partTimeEmployee;
    salonInfoOb.totalFullTimeEmployee = salon.salonInfo.totalFullTimeEmployee;
    salonInfoOb.licensedStatus = salon?.salonInfo?.licensedStatus?.toString();
    salonInfoOb.priorLoss = salon.salonInfo.priorLoss;
    salonInfoOb.claimType = salon.salonInfo.claimType;
    salonInfoOb.claimDate = formattedDate(salon.salonInfo.claimDate);
    salonInfoOb.lossRunYear = salon.salonInfo.lossRunYear;
    salonInfoOb.anyClaim = salon?.salonInfo?.anyClaim?.toString();
    salonInfoOb.lastRoofUpdate = salon.salonInfo.lastRoofUpdate === null ? null : formattedDate(salon.salonInfo.lastRoofUpdate);
    salonInfoOb.lastWiringUpdate = salon.salonInfo.lastWiringUpdate === null ? null : formattedDate(salon.salonInfo.lastWiringUpdate);
    salonInfoOb.lastPlumbingUpdate = salon.salonInfo.lastPlumbingUpdate === null ? null : formattedDate(salon.salonInfo.lastPlumbingUpdate);

    salonInfoOb.lastHeating = salon.salonInfo.lastHeating === null ? null : formattedDate(salon.salonInfo.lastHeating);

    return salonInfoOb;
  };

  const getStep3HotelInfo = (salon) => {
    console.log("data", salon);
    const salonInfoOb = {};
    salonInfoOb.agentId = salon.customerInfo.agentId;
    salonInfoOb.annualGrossSale = salon.salonInfo.annualGrossSale;
    salonInfoOb.converageLimit = salon.salonInfo.converageLimit;
    salonInfoOb.anyProferssionalLib = salon?.salonInfo?.anyProferssionalLib?.toString();
    salonInfoOb.malPracticeCoverage = salon?.salonInfo?.malPracticeCoverage?.toString();
    salonInfoOb.malPracticeCoverageAmt = salon.salonInfo.malPracticeCoverageAmt;
    salonInfoOb.totalPayroll = salon.salonInfo.totalPayroll;
    salonInfoOb.feinNumber = salon.salonInfo.feinNumber;
    salonInfoOb.noOfEmployee = salon.salonInfo.noOfEmployee;
    salonInfoOb.bankDetails = salon.salonInfo.bankDetails;
    salonInfoOb.propValueByBank = salon.salonInfo.propValueByBank;
    salonInfoOb.termsCondition = salon.salonInfo.termsCondition;
    return salonInfoOb;
  };

  const fillupStep1HotelInfoFromLocalStorage = async (formData) => {
    if (formData) {
      console.log("forma", formData);
      setValue("firstName", formData.firstName);
      setValue("lastName", formData.lastName);
      setValue("businessName", formData.businessName);
      setValue("dba", formData.dba);
      setValue("hoursOfOperation", formData.hoursOfOperation);
      let hrOfOperation = formData.hoursOfOperation;
      hrOfOperation = hrOfOperation.split("-");
      setHoursOfOperation(hrOfOperation);
      setValue("businessType", formData.businessType);
      setValue("locationAddress", formData.locationAddress);
      setValue("locationCity", formData.locationCity);
      setValue("locationState", formData.locationState);
      setValue("locationZip", formData.locationZip);
      setValue("mailingAddress", formData.mailingAddress);
      setValue("mailingCity", formData.mailingCity);
      setValue("mailingState", formData.mailingState);
      setValue("mailingZip", formData.mailingZip);
      setValue("workNum", formData.workNum);
      setValue("mobileNum", formData.mobileNum);
      setValue("email", formData.email);
      setValue("coverage", formData?.coverage);
      setValue("effectiveDate", formattedDate(formData.effectiveDate));
      setAddress(formData.locationAddress);
      setGarageAddress(formData.mailingAddress);
      if (formData.mailingAddress === formData.locationAddress) {
        setSameAsAddress(true);
      }
    }
  };

  const setHotelInfoInLocalStorage = (data) => {
    localStorage.setItem("step1", JSON.stringify(getStep1HotelInfo(data)));
    localStorage.setItem("step2", JSON.stringify(getStep2HotelInfo(data)));
    localStorage.setItem("step3", JSON.stringify(getStep3HotelInfo(data)));
    localStorage.setItem("customerId", data?.customerInfo?.customerId);
  };

  const getTimeRange = async (range) => {
    setValue("hoursOfOperation", range);
  };

  const isPasscodeActive = (verifyId) => {
    setLoading(true);
    axios
      .get(`${DEVURL_VERIFY}/IsActivePasscode/${verifyId}`)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          if (response.data !== 1) {
            navigate(`/verification?verify=${verifyId}`);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.status === 404) {
          navigate(`/verification?verify=${verifyId}`);
        }
      });
  };

  const loadInitialGasStationInfo = () => {
    const formData = JSON.parse(localStorage.getItem("step1"));
    if (!formData) {
      setLoading(true);
      axios
        .get(`${DEVURL}/GetSalonInfoBySalonId/${id}`)
        .then((response) => {
          setLoading(false);
          fillupStep1HotelInfo(response.data);
          setHotelInfoInLocalStorage(response.data);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else if (formData) {
      fillupStep1HotelInfoFromLocalStorage(formData);
    }
  };

  useEffect(() => {
    if (verifyId) {
      isPasscodeActive(verifyId);
    }
    loadInitialGasStationInfo();
  }, []);

  const handleFormSubmit = (data) => {
    localStorage.setItem("step1", JSON.stringify(data));
    stepHandler(2);
  };

  if (isLoading) {
    return loader();
  }

  return (
    <>
      <form className="tab-wizard wizard-circle wizard needs-validation" onSubmit={handleSubmit(handleFormSubmit)}>
        <section>
          <h4 className="wrap-title mb-3">General Information</h4>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">First Name</label>
                <span className="required-mark">*</span>
                <input type="text" className="form-control" placeholder="Enter First Name" readOnly={isView} {...register("firstName")} />
                <p className="text-danger">{errors.firstName && <p>{errors.firstName.message}</p>}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">Last Name</label>
                <span className="required-mark">*</span>
                <input type="text" className="form-control" placeholder="Enter Last Name" readOnly={isView} {...register("lastName")} />
                <p className="text-danger">{errors.lastName && <p>{errors.lastName.message}</p>}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">Business Name</label>
                <span className="required-mark">*</span>
                <input type="text" className="form-control" placeholder="Enter Business Name" readOnly={isView} {...register("businessName")} />
                <p className="text-danger">{errors.businessName && <p>{errors.businessName.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">DBA</label>
                <input type="text" className="form-control" placeholder="Enter DBA" readOnly={isView} {...register("dba")} />
                <p className="text-danger">{errors.dba && <p>{errors.dba.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  <div className="d-flex">
                    Hours of Operation<span className="required-mark">*</span> &nbsp;
                    <div>
                      <Badge bg="info">(Start and End Time)</Badge>
                    </div>
                  </div>
                </label>
                {/*// for edit pass the start= 09:30 and end = 18:30 */}
                <BusinessHour onTimeRangeChange={getTimeRange} timeRange={hoursOfOperation} />

                <p className="text-danger">{errors.hoursOfOperation && <p>{errors.hoursOfOperation.message}</p>}</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">
                  Work Number
                  {/* <span className="required-mark">*</span> */}
                </label>
                <Controller
                  name="workNum"
                  control={control}
                  render={({ field }) => (
                    <input {...field} type="text" class="form-control" placeholder="(XXX) XXX-XXXX" value={formatTelNumber(workNum)} {...register("workNum")} />
                  )}
                />
                <p className="text-danger">{errors.workNum?.message}</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">
                  Mobile Number<span className="required-mark">*</span>
                </label>
                <Controller
                  name="mobileNum"
                  control={control}
                  render={({ field }) => (
                    <input {...field} type="text" class="form-control" placeholder="(XXX) XXX-XXXX" value={formatTelNumber(mobileNum)} {...register("mobileNum")} />
                  )}
                />

                <p className="text-danger">{errors.mobileNum?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">Email Address</label>
                <span className="required-mark">*</span>
                <input type="email" className="form-control" placeholder="Enter Email Address" readOnly={isView} {...register("email")} />
                <p className="text-danger">{errors.email && <p>{errors.email.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">Effective Date</label>
                <span className="required-mark">*</span>
                <input type="date" max="2099-12-31" className="form-control" placeholder="Enter Effective Date" readOnly={isView} {...register("effectiveDate")} />
                <p className="text-danger">{errors.effectiveDate && <p>{errors.effectiveDate.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mb-3">
                <label className="brand-label">
                  Location Address<span className="required-mark">*</span>
                </label>
                <PlaceComponent
                  getLocationAddress={getLocation}
                  value={addesss}
                  onChange={(e) => {
                    setAddress(e.target.value);
                    setValue("locationAddress", e.target.value);
                    setValue("locationCity", null);
                    setValue("locationState", null);
                    setValue("locationZip", null);
                  }}
                  type="profile-update"
                  name="locationAddress"
                  control={control}
                ></PlaceComponent>
                <p className="text-danger">{errors.locationAddress && <p>{errors.locationAddress?.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mb-3">
                <label className="brand-label" style={{ display: "flex" }}>
                  Mailing Address<span className="required-mark">*</span>&nbsp;
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={sameAsAddress}
                      onChange={(e) => {
                        setSameAsAddress(e.target.checked);
                        setValue("mailingAddress", addesss);
                        setValue("mailingCity", locationCity);
                        setValue("mailingState", locationState);
                        setValue("mailingZip", locationZip);
                        setGarageAddress(addesss);
                      }}
                      id="same-as"
                    />
                    <label className="form-check-label text-capitalize" htmlFor="flexCheckDefault">
                      Same As Location Address
                    </label>
                  </div>
                </label>

                <PlaceComponent
                  getLocationAddress={getGarageAddress}
                  value={garageAddress}
                  onChange={(e) => {
                    setGarageAddress(e.target.value);
                    setValue("mailingAddress", e.target.value);
                    setValue("mailingZip", null);
                    setValue("mailingState", null);
                    setValue("mailingCity", null);
                  }}
                  type="profile-update"
                  readOnly={sameAsAddress}
                  name="mailingAddress"
                  control={control}
                >
                  {" "}
                </PlaceComponent>
                <p className="text-danger">{errors.mailingAddress && <p>{errors.mailingAddress.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mb-3">
                <label class="brand-label">
                  Business Type<span className="required-mark">*</span>
                </label>
                <div className="row ">
                  <div className="col-md-6">
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="beautySalonCheck" value="Beauty salon" {...register("businessType")} />
                      <label className="form-check-label text-capitalize" for="beautySalonCheck">
                        Beauty salon
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="nailSalonCheck" value="Nail salon" {...register("businessType")} />
                      <label className="form-check-label text-capitalize" for="nailSalonCheck">
                        Nail salon
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="barberShopCheck" value="Barber shop" {...register("businessType")} />
                      <label className="form-check-label text-capitalize" for="barberShopCheck">
                        Barber shop
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="henaSalonCheck" value="Henna salon" {...register("businessType")} />
                      <label className="form-check-label text-capitalize" for="henaSalonCheck">
                        Henna salon
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="othersCheck" value="Others" {...register("businessType")} />
                      <label className="form-check-label text-capitalize" for="othersCheck">
                        Others
                      </label>
                    </div>
                  </div>
                </div>
                <p className="text-danger">{errors.businessType && <p>{errors.businessType.message}</p>}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="mb-3">
                <label class="brand-label">
                  Coverage<span className="required-mark">*</span>
                </label>
                <div className="row ">
                  <div className="col-md-6">
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="propertyCheck" value="Property" {...register("coverage")} />
                      <label className="form-check-label text-capitalize" for="propertyCheck">
                        Property
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="generalLiabilityCheck" value="General Liability" {...register("coverage")} />
                      <label className="form-check-label text-capitalize" for="generalLiabilityCheck">
                        General Liability
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="umbrellaCheck" value="Umbrella" {...register("coverage")} />
                      <label className="form-check-label text-capitalize" for="umbrellaCheck">
                        Umbrella
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="professionalLiabilityCheck" value="Professional liability" {...register("coverage")} />
                      <label className="form-check-label text-capitalize" for="professionalLiabilityCheck">
                        Professional liability
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="workerCompensationCheck" value="Worker’s compensation" {...register("coverage")} />
                      <label className="form-check-label text-capitalize" for="workerCompensationCheck">
                        Worker’s compensation
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="EPLICheck" value="EPLI" {...register("coverage")} />
                      <label className="form-check-label text-capitalize" for="EPLICheck">
                        EPLI
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="commercialAutoCheck" value="Commercial auto" {...register("coverage")} />
                      <label className="form-check-label text-capitalize" for="commercialAutoCheck">
                        Commercial auto
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="cyberCheck" value="Cyber" {...register("coverage")} />
                      <label className="form-check-label text-capitalize" for="cyberCheck">
                        Cyber
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="equipmentBreakdownCheck" value="Equipment breakdown" {...register("coverage")} />
                      <label className="form-check-label text-capitalize" for="equipmentBreakdownCheck">
                        Equipment breakdown
                      </label>
                    </div>

                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" id="othersCheck" value="Others" {...register("coverage")} />
                      <label className="form-check-label text-capitalize" for="othersCheck">
                        Others
                      </label>
                    </div>
                  </div>
                </div>
                <p className="text-danger">{errors.coverage?.message}</p>
              </div>
            </div>
          </div>
        </section>
        <div className="text-end">
          {salonId && (
            <button type="button" onClick={() => handleExitButton("Salon Insurance Application")} className="btn btn-danger ms-2 text-capitalize">
              Exit if no changes required
            </button>
          )}
          <button className="btn btn-primary ms-2">Next</button>
        </div>
      </form>
    </>
  );
};

export default Step1;
