import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AgencyHeader from "./Agencyheader";
import Footer from "./ancfooter";

const DEVURL =
  process.env.REACT_APP_ANC_API + "/api/Acord/GenerateRestaurantAcord";
const DEVURL_VERIFY = process.env.REACT_APP_ANC_API + "/api/Verify";

const AccordForm = () => {
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("htappid");
  const agencyId = query.get("id");
  const primaryid = query.get("primaryid");
  const verifyId = query.get("verifyid");
  const [isLoading, setLoading] = useState(false);

  //   const isPasscodeActive = (verifyId) => {
  //     setLoading(true);
  //     axios
  //       .get(`${DEVURL_VERIFY}/IsActivePasscode/${verifyId}`)
  //       .then((response) => {
  //         console.log(response);
  //         if (response.status === 200) {
  //           setLoading(false);
  //           if (response.data !== 1) {
  //             navigate(`/verification?verify=${verifyId}`);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //         if (error?.response?.status === 404) {
  //           navigate(`/verification?verify=${verifyId}`);
  //         }
  //       });
  //   };

  //   const downloadForm = () => {
  //     try {
  //       setLoading(true);
  //       axios
  //         .get(`${DEVURL}/${agencyId}/${primaryid}`)
  //         .then((response) => {
  //           setLoading(false);
  //           console.log("response", response);
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           setLoading(false);
  //         });
  //     } catch (error) {
  //       console.log("error", error);
  //     }
  //   };

  //   useEffect(() => {
  //     if (verifyId) {
  //       isPasscodeActive(verifyId);
  //     }
  //     downloadForm();
  //   }, [agencyId, primaryid]);
  return (
    <div>
      <AgencyHeader />
      <div className="container">
        <div className="main-wrap">
          <h2 className="text-center">Accord Form</h2>
          <div className="text-center">
            <Link to={`${DEVURL}/${agencyId}/${primaryid}`}>
              <button className="btn btn-primary">Download Accord Form</button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AccordForm;
