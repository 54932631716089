import { IconBuilding, IconBuildingStore, IconMail, IconPhone, IconUser } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAgencyInfo from "../../hooks/useAgencyInfo.hook";
import Footer from "../ancfooter";
import axios from "axios";
import { useSelector } from "react-redux";
import InvalidURL from "../InvalidURL/InvalidURL";

const SmartLinkConfiguation = () => {
  const { agencyName, agentName } = useParams();
  const { data, agencyId, agentId, isValidUrl } = useAgencyInfo(agencyName, agentName);
  const [productLists, setProductLists] = useState(null);
  const DEVURL = process.env.REACT_APP_ANC_DOMAIN;
  const DEVURL_BE = process.env.REACT_APP_ANC_API;
  console.log("data", data, agencyId, agentId);
  const cards = [
    {
      id: "healthcare",
      title: "Healthcare",
      content: "Healthcare content",
      img: "/assets/images/medical-symbol.png",
      url: `${DEVURL}/quote/${agentName == null ? `${agencyName}` : `${agencyName}/${agentName}`}`,
      productId: "myHealthcare",
    },

    {
      id: "home",
      title: "Home",
      content: "Home content",
      img: "/assets/img/icons/home.png",
      url: `${DEVURL}/homeform/${agentName == null ? `${agencyName}` : `${agencyName}/${agentName}`}`,
      productId: "mypersonalline",
    },
    {
      id: "auto",
      title: "Auto",
      content: "Auto content",
      img: "/assets/img/icons/auto.png",
      url: `${DEVURL}/autoform/${agentName == null ? `${agencyName}` : `${agencyName}/${agentName}`}`,
      productId: "mypersonalline",
    },
    {
      id: "gas-station",
      title: "Gas Station",
      content: "Gas content",
      img: "/assets/img/icons/gas-station.png",
      url: `${DEVURL}/gasstationform/${agentName == null ? `${agencyName}` : `${agencyName}/${agentName}`}`,
      productId: "mybusinessline",
    },

    {
      id: "restaurant",
      title: "Restaurant",
      content: "Restaurant content",
      img: "/assets/img/icons/restaurant.png",
      url: `${DEVURL}/restaurantform/${agentName == null ? `${agencyName}` : `${agencyName}/${agentName}`}`,
      productId: "mybusinessline",
    },
    {
      id: "salon",
      title: "Salon",
      content: "Auto content",
      img: "/assets/img/icons/salon.png",
      url: `${DEVURL}/salonForm/${agentName == null ? `${agencyName}` : `${agencyName}/${agentName}`}`,
      productId: "mybusinessline",
    },
    {
      id: "general-contractor",
      title: "General Contractor",
      content: "Auto content",
      img: "/assets/images/support.png",
      url: `${DEVURL}/generalContractorForm/${agentName == null ? `${agencyName}` : `${agencyName}/${agentName}`}`,
      productId: "mybusinessline",
    },
    {
      id: "shopping-mall",
      title: "Shopping Mall",
      content: "Auto content",
      img: "/assets/img/icons/shopping-mall.png",
      url: `${DEVURL}/shoppingMallForm/${agentName == null ? `${agencyName}` : `${agencyName}/${agentName}`}`,
      productId: "mybusinessline",
    },
    {
      id: "general-store",
      title: "General Business",
      content: "Auto content",
      icon: <IconBuildingStore className="prod-img" color="#2dad70" />,
      url: `${DEVURL}/generalStoreForm/${agentName == null ? `${agencyName}` : `${agencyName}/${agentName}`}`,
      productId: "mybusinessline",
    },
    {
      id: "hotel",
      title: "Hotel/Motel",
      content: "Auto content",
      img: "/assets/images/hotel.png",
      url: `${DEVURL}/hotelForm/${agentName == null ? `${agencyName}` : `${agencyName}/${agentName}`}`,
      productId: "mybusinessline",
    },
  ];
  const getActiveProductData = () => {
    const url = agentId ? `${DEVURL_BE}/api/Products/GetProduct/${agencyId}/${agentId}` : `${DEVURL_BE}/api/Products/GetProduct/${agencyId}`;
    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          let product = response.data;
          product = product.map((item) => {
            item.activated = item.isUseStatus ?? 0;
            return item;
          });
          setProductLists(product);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status === 404) {
        } else {
        }
      });
  };
  const activatedLines = cards?.filter((nextStep) => {
    const correspondingProduct = productLists?.find((product) => product?.productId?.toLowerCase() === nextStep?.productId?.toLowerCase());
    return correspondingProduct && correspondingProduct?.isUseStatus === 1;
  });

  useEffect(() => {
    if (agencyId) getActiveProductData();
  }, [agencyId]);

  return isValidUrl === false ? (
    <InvalidURL />
  ) : (
    isValidUrl === true && (
      <div>
        <section
          className=" top-position1 bg-img cover-background left-overlay-dark contact-banner"
          data-overlay-dark="6"
          style={{
            backgroundImage: `url('/assets/img/banner/anc-contact-banner.jpg')`,
            padding: "10px 0 150px",
          }}
        >
          <div class="row mx-2" style={{ marginBottom: "100px" }}>
            <div class="col-sm-9"></div>
            <div class="col-sm-3">
              {data && (
                <table class="table table-borderless " style={{ backgroundColor: "#fff", width: "auto", borderRadius: "16px" }}>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          color: "#1C2120",
                        }}
                      >
                        <IconBuilding color="#1C2120" /> {data?.agencyName}
                      </td>
                    </tr>
                    {data?.agent_info_list && (
                      <tr>
                        <td
                          style={{
                            fontWeight: "600",
                            fontSize: "18px",
                            color: "#1C2120",
                          }}
                        >
                          <IconUser /> {`${data?.agent_info_list.firstName} ${data.agent_info_list.lastName}`}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          color: "#1C2120",
                        }}
                      >
                        <IconMail /> {data?.agent_info_list != null ? data.agent_info_list.email : data?.email}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          // color: "#fff",
                          color: "#1C2120",
                        }}
                      >
                        <IconPhone /> {data?.agent_info_list != null ? data.agent_info_list.phoneNo : data?.phone}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
          <div className="container">
            <div className="page-title">
              <div className="row">
                <div className="col-md-12">
                  <h1
                    style={{
                      fontWeight: "800",
                      letterSpacing: "1px",
                      lineHeight: "1",
                      marginBottom: "15px",
                      color: "#fff",
                    }}
                  >
                    Services
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="d-inline-block p-2 border border-width-2 border-white top-20 right-10 ani-move position-absolute rounded-circle z-index-1"></div>
          <div className="d-inline-block p-2 border border-width-2 border-white bottom-10 left-10 ani-left-right position-absolute z-index-1"></div>
          <img src="/assets/img/content/brand-shape-5.png" className="position-absolute top-20 left-5 ani-top-bottom z-index-1" alt="..." />
        </section>
        <section className="py-1 my-1 mt-2">
          <div className="container">
            <div className="row">
              {activatedLines.map((card, index) => (
                <a href={card?.url} target="_blank" key={index} className="col-md-4" rel="noreferrer">
                  <div className="brand-preview-card mt-2 card-link card-link-height">
                    {card.icon ? card.icon : <img className="prod-img prod-img-preview" src={card.img} alt="" />}
                    <h6 className="brand-title text-center mb-3 text-black" type="button">
                      {card.title}
                    </h6>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    )
  );
};

export default SmartLinkConfiguation;
