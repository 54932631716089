import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import * as yup from "yup";
import { cellRegex, emailRegex, floatRegex, nameRegex } from "../../Common/regex";
import { default as axios } from "../../config/axios-config";
import { ThreeDots } from "react-loader-spinner";
import Agencyheader from "../Agencyheader";
import ThankyouResponse from "../Thankyoupage";
import Footer from "../ancfooter";
import PlaceComponent from "../healthcare/GooglePlace";
import useAgencyInfo from "../../hooks/useAgencyInfo.hook";
import InvalidURL from "../InvalidURL/InvalidURL";
import useExitConfirmationHandler from "../../hooks/useExitConfirmationHandler.hook";
import { formatTelNumber } from "../../Common/Utils";
import { NumericFormat } from "react-number-format";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/HomeApplication/";
const DEVURL_VERIFY = process.env.REACT_APP_ANC_API + "/api/Verify";
const DEVURL_MANAGEAGENCY = process.env.REACT_APP_ANC_API + "/api/manageagency";

localStorage.removeItem("step2");
localStorage.removeItem("step1");

const getValidationSchema = (agentId) => {
  let baseSchema = {
    roofDate: yup
      .string()
      .nullable(true)
      .test("is-past-date", "Date must be a past date", (value) => {
        if (!value) {
          return true;
        }
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const inputDate = new Date(value);
        inputDate.setHours(0, 0, 0, 0);
        return inputDate < currentDate;
      })
      .label("Roof date"),

    electricalDate: yup
      .string()
      .nullable(true)
      .typeError("Please enter valid date")
      .test("is-past-date", "Date must be a past date", (value) => {
        if (!value) {
          return true;
        }
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const inputDate = new Date(value);
        inputDate.setHours(0, 0, 0, 0);

        return inputDate < currentDate;
      })
      .label("Electrical date"),

    plumbingDate: yup
      .string()
      .nullable(true)
      .typeError("Please enter valid date")
      .test("is-past-date", "Date must be a past date", (value) => {
        if (!value) {
          return true;
        }
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const inputDate = new Date(value);
        inputDate.setHours(0, 0, 0, 0);

        return inputDate < currentDate;
      })
      .label("Plumbing date"),
    hvacDate: yup
      .string()
      .nullable(true)
      .typeError("Please enter valid date")
      .test("is-past-date", "Date must be a past date", (value) => {
        if (!value) {
          return true;
        }
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const inputDate = new Date(value);
        inputDate.setHours(0, 0, 0, 0);

        return inputDate < currentDate;
      })
      .label("Havc date"),
    propertyAddress: yup.string().trim().required("Address is required").label("Address"),
    firstName: yup.string().trim().matches(nameRegex, "Please enter a valid name").max(45).required("First name is required").label("First name"),
    currentHomeValue: yup.string().trim().nullable(true).matches(floatRegex, "Please enter a valid current home value").label("Current home value"),
    lastName: yup.string().trim().matches(nameRegex, "Please enter a valid name").max(45).required("Last name is required").label("Last name"),
    cell: yup.string().trim().required("Mobile number is required").matches(cellRegex, "Please enter a valid mobile number").label("Mobile number"),
    workPhone: yup
      .string()
      .trim()
      .nullable(true)
      .matches(/^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[0-9]{3}[\s\-]?[0-9]{4}$|^$/, "Please enter a valid work phone number")
      .label("Work mobile number"),
    email: yup.string().trim().required("Email address is required").matches(emailRegex, "Enter a valid email address").label("Email address"),
    dob: yup
      .date()
      .typeError("Date of birth is a required")
      .test("is-age-15-or-older", "User age must be 15 or older", (value) => {
        if (!value) {
          return true;
        }
        const birthYear = value.getFullYear();
        const currentYear = new Date().getFullYear();
        return currentYear - birthYear >= 15;
      })
      .required("Date of birth is required")
      .label("Date of birth"),
    spouseFirstName: yup.string().trim().matches(nameRegex, "Please enter a valid name").max(45).label("First name"),
    spouseLastName: yup.string().trim().matches(nameRegex, "Please enter a valid name").max(45).label("Last name"),
    spouseDob: yup
      .date()
      .nullable(true)
      // .typeError("Date of birth is a required")
      .test("is-age-15-or-older", "Spouse age must be 15 or older", (value) => {
        if (!value) {
          return true;
        }
        const birthYear = value.getFullYear();
        const currentYear = new Date().getFullYear();
        return currentYear - birthYear >= 15;
      })
      .label("Date of birth"),
    insurancePremium: yup.string().nullable(true).matches(/^\d+$/, "Please enter a valid amount"),
  };
  if (agentId == null) {
    baseSchema = {
      ...baseSchema,
      agentId: yup.string().required("Selecting an agent is required.").notOneOf(["", null, undefined], "You must select a valid agent."),
    };
  }
  return yup.object().shape(baseSchema);
};
const HomeApplication = () => {
  const handleExitButton = useExitConfirmationHandler();
  const { agencyName, agentName } = useParams();
  const { agencyId, agentId, isValidUrl } = useAgencyInfo(agencyName, agentName);
  const validationSchema = getValidationSchema(agentId);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
    control,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      cell: "",
      workPhone: "",
      spouseFirstName: "",
      spouseLastName: "",
      spouseDob: null,
      dob: null,
      propertyAddress: "",
      city: "",
      state: "",
      zipCode: "",
      currentHomeValue: null,
      DiscountsCheck: [],
      carrierName: "",
      renewalDate: null,
      insurancePremium: null,
      carrierDuration: null,
      apt: "",
      claimDate: null,
      claimReason: "",
      roofDate: null,
      electricalDate: null,
      plumbingDate: null,
      hvacDate: null,
      agentId: "",
      propertyAnyUpdate: [],
    },
    resolver: yupResolver(validationSchema),
  });

  const [isLoading, setLoading] = useState(false);
  const [addesss, setAddress] = useState("");
  const [agentList, setAgentList] = useState();
  const [garageAddress, setGarageAddress] = useState("");
  const [sameAsAddress, setSameAsAddress] = useState(false);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  // const agencyId = queryParams.get("id");
  // const agentId = queryParams.get("agentid");
  const primaryId = queryParams.get("primaryid");
  const verifyId = queryParams.get("verifyid");
  const propertyAnyUpdate = watch("propertyAnyUpdate");
  const mobile = queryParams.get("mobile");
  const emailAddress = queryParams.get("emailAddress");
  const zip = queryParams.get("zip");
  const income = queryParams.get("income");
  const dob = queryParams.get("dob");
  const gender = queryParams.get("gender");
  const name = queryParams.get("name");
  const cell = watch("cell");
  const workPhone = watch("workPhone");
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);
  const [isThanksPage, setIsThanksPage] = useState(false);
  console.log("getValues agentId", getValues("agentId"));
  const formattedDate = (date) => {
    if (date != null) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
    return null;
  };

  const setStep1ToLocalStorage = (data) => {
    localStorage.setItem("step1", JSON.stringify(data));
  };

  const setPrimaryDataFromEdit = (primaryInfo) => {
    console.log(primaryInfo);
    setValue("agentId", primaryInfo.agentId);
    setValue("firstName", primaryInfo.customerFirstName);
    setValue("lastName", primaryInfo.customerLastName);
    setValue("email", primaryInfo.customerEmail);
    setValue("cell", primaryInfo.customerMobileNum);
    setValue("workPhone", primaryInfo.customerWorkNum);
    setValue("spouseFirstName", primaryInfo.spouseFirstName);
    setValue("spouseLastName", primaryInfo.spouseLastName);
    setValue("spouseDob", formattedDate(primaryInfo.dateOfBirth));
    setValue("dob", formattedDate(primaryInfo.customerDateOfBirth));
    setValue("propertyAddress", primaryInfo.propertyAddress);
    setValue("city", primaryInfo.propertyCity);
    setValue("state", primaryInfo.propertyState);
    setValue("zipCode", primaryInfo.propertyZipeCode);
    setValue("currentHomeValue", primaryInfo.currentHomeValue);
    setValue("DiscountsCheck", primaryInfo.discountsCheck);
    setValue("carrierName", primaryInfo.carrierName);
    setValue("renewalDate", formattedDate(primaryInfo.renewalDate));
    setValue("insurancePremium", primaryInfo.premiumAmount);
    setValue("carrierDuration", primaryInfo.duration);
    setValue("apt", primaryInfo.propertyAddress);
    setValue("claimDate", formattedDate(primaryInfo.dateOfClaim));
    setValue("claimReason", primaryInfo.claimReason);
    setValue("roofDate", formattedDate(primaryInfo.isRoofUpdated));
    setValue("electricalDate", formattedDate(primaryInfo.isWiringUpdated));
    setValue("plumbingDate", formattedDate(primaryInfo.isPlumbingUpdated));
    setValue("hvacDate", formattedDate(primaryInfo.isHeatingUpdated));
    setAddress(primaryInfo.propertyAddress);
  };

  const loader = () => {
    return (
      <ThreeDots
        height="40"
        width="80"
        color="#4fa94d"
        ariaLabel="bars-loading"
        wrapperStyle={{ justifyContent: "center", paddingBottom: "10px" }}
        wrapperClass=""
        visible={true}
      />
    );
  };

  const isPasscodeActive = (verifyId) => {
    setLoading(true);
    axios
      .get(`${DEVURL_VERIFY}/IsActivePasscode/${verifyId}`)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          if (response.data !== 1) {
            navigate(`/verification?verify=${verifyId}`);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const insertHomeApplicationInfo = async (data) => {
    data.agencyId = agencyId;
    await axios
      .post(`${DEVURL}CreateHomeApplication`, data)
      .then((res) => {
        if (res.status === 200) {
          reset();
          setIsThanksPage(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const getHomeApplicationInfoById = (id) => {
    // https://dev-ancapi.azurewebsites.net/api/HomeApplication/GetHomeApplicationByHomeAppId/9d7e05ff-e3e8-4dd3-ab17-2938d2db821c
    axios
      .get(`${DEVURL}GetHomeApplicationByHomeAppId/${id}`)
      .then((res) => {
        if (res.status === 200) {
          console.log("data", res.data);
          setPrimaryDataFromEdit(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAgentName = () => {
    if (agentList?.length) {
      const agent = agentList?.find((agent) => agent.id === getValues("agentId"));
      console.log("agent", agent, getValues("agentId"));
      return `${agent?.firstName} ${agent?.lastName}`;
    }
  };

  const updateHomeApplicationInfo = async (data) => {
    data.HomeAppId = primaryId;
    await axios
      .put(`${DEVURL}UpdateHomeApplication`, data)
      .then((res) => {
        if (res.status === 200) {
          reset();
          setIsThanksPage(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getAgentList = async () => {
    await axios
      .get(`${DEVURL_MANAGEAGENCY}/GetAgentInfoByAgency/${agencyName}`)
      .then((response) => {
        if (response.status === 200) {
          setAgentList(response.data.agent_info_list);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (agencyName) getAgentList();
  }, [agencyName]);

  useEffect(() => {
    if (verifyId) {
      isPasscodeActive(verifyId);
    }
    if (primaryId) {
      getHomeApplicationInfoById(primaryId);
    }
  }, []);

  const onChangeRecaptcha = (value) => {
    setIsCaptchaSuccess(true);
  };

  const handleFormSubmit = async (data) => {
    data = {
      CustomerFirstName: data?.firstName,
      CustomerLastName: data?.lastName,
      CustomerDateOfBirth: data?.dob,
      CustomerMobileNum: data?.cell,
      CustomerEmail: data?.email,
      CustomerWorkNum: data?.workPhone,
      SpouseFirstName: data?.spouseFirstName,
      SpouseLastName: data?.spouseLastName,
      DateOfBirth: data?.spouseDob,
      PropertyAddress: data.propertyAddress,
      PropertyCity: data.city,
      PropertyZipeCode: data.zipCode,
      PropertyState: data.state,
      CurrentHomeValue: data?.currentHomeValue,
      CentralMonitorDis: 1,
      GatedComDis: 1,
      WaterShutDis: 1,
      SprinklerDis: 1,
      UlComplaintDis: 1,
      IsRoofUpdated: data?.roofDate,
      IsWiringUpdated: data.electricalDate,
      IsPlumbingUpdated: data.plumbingDate,
      IsHeatingUpdated: data.hvacDate,
      DateOfClaim: data?.claimDate,
      ClaimReason: data?.claimReason,
      AgencyId: agencyId,
      AgentId: agentId == null ? data.agentId : agencyId,
      DiscountsCheck: data.DiscountsCheck,
      carrierName: data.carrierName,
      carrierDuration: data.carrierDuration,
      renewalDate: data.renewalDate,
      premiumAmount: data.insurancePremium,
    };
    // console.log(data);
    setStep1ToLocalStorage(data);
    Swal.fire({
      title: "Are you sure you want to submit the form?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        console.log("inside sweer alert");
        // Call the onSubmit function when the user clicks "OK".
        if (!primaryId) {
          insertHomeApplicationInfo(data);
        } else {
          updateHomeApplicationInfo(data);
        }
      } else {
        // The user clicked "Cancel," so exit without taking any action.
        return;
      }
    });
  };
  const getLocation = async (data) => {
    let address_components = data?.address_components;
    let formatted_address = data?.formatted_address;
    setAddress(formatted_address);
    formatted_address = formatted_address.split(",");
    setValue("propertyAddress", formatted_address[0] ?? "");
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        zip_code = data.long_name + zip_code;
      }
      // if (data.types.includes("postal_code_suffix")) {
      //     zip_code =  zip_code +'-'+ data.long_name;
      // }
      if (data.types.includes("locality")) {
        setValue("city", data.long_name);
      }
      if (data.types.includes("administrative_area_level_1")) {
        setValue("state", data.long_name);
      }
    });
    setValue("zipCode", zip_code);
  };
  const getGarageAddress = async (data) => {
    let formatted_address = data?.formatted_address;
    setValue("garrageStreetAddress", formatted_address);
    setAddress(formatted_address);
    return false;
  };

  return isValidUrl === false ? (
    <InvalidURL />
  ) : (
    isValidUrl === true && (
      <>
        <Agencyheader />
        <div className="container">
          <div className="main-wrap">
            <div className="wizard-content">
              <h2>Home Insurance Application</h2>
              <form onSubmit={handleSubmit(handleFormSubmit)} className="tab-wizard wizard-circle wizard needs-validation">
                {isLoading && (
                  <ThreeDots
                    height="40"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="bars-loading"
                    wrapperStyle={{ justifyContent: "center", paddingBottom: "10px" }}
                    wrapperClass=""
                    visible={true}
                  />
                )}
                {!isThanksPage && !isLoading && (
                  <section>
                    <h4 className="wrap-title mb-3">Home information:</h4>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="brand-label">
                            First Name<span className="required-mark">*</span>
                          </label>
                          <input id="firstName" type="text" className="form-control" placeholder="Enter First Name" {...register("firstName")} />
                          <p className="text-danger mt-2">
                            <p>{errors?.firstName?.message}</p>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="brand-label">
                            Last Name<span className="required-mark">*</span>
                          </label>
                          <input id="lastName" type="text" className="form-control" placeholder="Enter Last Name" {...register("lastName")} />
                          <p className="text-danger mt-2">{errors.lastName && <p>{errors.lastName.message}</p>}</p>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <div className="mb-3">
                          <label className="brand-label">
                            Email Address<span className="required-mark">*</span>
                          </label>
                          <input id="email" type="email" className="form-control" placeholder="Enter Email Address" {...register("email")} />
                          <p className="text-danger mt-2">{errors.email && <p>{errors.email.message}</p>}</p>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <div className="mb-3">
                          <label className="brand-label">
                            Mobile Number
                            <span className="required-mark">*</span>
                          </label>
                          <input id="cellPhone" type="text" className="form-control" placeholder="(XXX) XXX-XXXX" value={formatTelNumber(cell)} {...register("cell")} />
                          <p className="text-danger mt-2">{errors.cell && <p>{errors.cell.message}</p>}</p>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <div className="mb-3">
                          <label className="brand-label">Work Mobile Number</label>
                          <input id="workPhone" type="text" className="form-control" placeholder="(XXX) XXX-XXXX" value={formatTelNumber(workPhone)} {...register("workPhone")} />
                          <p className="text-danger mt-2">{errors.workPhone && <p>{errors.workPhone.message}</p>}</p>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <div className="mb-3">
                          <label className="brand-label">
                            Date of Birth<span className="required-mark">*</span>
                          </label>
                          <input id="birthday" type="date" max="2099-12-31" className="form-control" placeholder="Enter Enrollment Date" {...register("dob")} />
                          <p className="text-danger mt-2">{errors.dob && <p>{errors.dob.message}</p>}</p>
                        </div>
                      </div>

                      {/* Garrage Address  */}
                    </div>

                    <div className="row my-3">
                      <h5 className=" wrap-title  mb-3">Spouse (if applicable):</h5>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="brand-label">First Name</label>
                          <input id="firstName" type="text" className="form-control" placeholder="Enter First Name" {...register("spouseFirstName")} />
                          <p className="text-danger mt-2">{errors.spouseFirstName && <p>{errors.spouseFirstName.message}</p>}</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="brand-label">Last Name</label>
                          <input id="lastName" type="text" className="form-control" placeholder="Enter Last Name" {...register("spouseLastName")} />
                          <p className="text-danger mt-2">{errors.spouseLastName && <p>{errors.spouseLastName.message}</p>}</p>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <div className="mb-3">
                          <label className="brand-label">Date of Birth</label>
                          <input id="birthday" type="date" max="2099-12-31" className="form-control" placeholder="Enter Enrollment Date" {...register("spouseDob")} />
                          <p className="text-danger mt-2">{errors.spouseDob && <p>{errors.spouseDob?.message}</p>}</p>
                        </div>
                      </div>

                      <h4 className=" wrap-title  mb-3">Property information:</h4>
                      <div className="col-md-4 col-lg-4">
                        <div className="mb-3">
                          <label className="brand-label">
                            Property address<span className="required-mark">*</span>
                          </label>
                          <PlaceComponent
                            getLocationAddress={getLocation}
                            value={addesss}
                            onChange={(e) => {
                              setAddress(e.target.value);
                              setValue("propertyAddress", e.target.value);
                              setValue("city", null);
                              setValue("state", null);
                              setValue("zipCode", null);
                            }}
                            type="profile-update"
                          ></PlaceComponent>
                          <p className="text-danger mt-2">{errors.propertyAddress && <p>{errors.propertyAddress.message}</p>}</p>
                        </div>
                        <div className="mb-3">
                          <label className="brand-label">Current home value (Optional)</label>
                          <Controller
                            name="currentHomeValue"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <NumericFormat
                                allowNegative={false}
                                {...field}
                                className="form-control"
                                thousandSeparator={true}
                                prefix="$"
                                onValueChange={(values) => {
                                  setValue("currentHomeValue", values.floatValue);
                                }}
                                placeholder="Enter Home Value"
                                {...register("currentHomeValue")}
                              />
                            )}
                          />
                          <p className="text-danger mt-2">{errors.currentHomeValue && <p>{errors.currentHomeValue?.message}</p>}</p>
                        </div>
                      </div>

                      <div className="col-md-3 col-lg-8 px-5">
                        <h5 className=" wrap-title  mb-3">Discounts:</h5>
                        <div className="row">
                          <div className="col-md-6 col-lg-6">
                            <label className="brand-label d-grid">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="burglarAlarm" value="burglarAlarm" {...register("DiscountsCheck")} />
                                <label className="form-check-label text-capitalize" htmlFor="burglarAlarm">
                                  Centrally monitored burglar alarm
                                </label>
                              </div>
                            </label>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <label className="brand-label d-grid">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="gatedCommunity" value="gatedCommunity" {...register("DiscountsCheck")} />
                                <label className="form-check-label text-capitalize" htmlFor="gatedCommunity">
                                  Gated community
                                </label>
                              </div>
                            </label>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <label className="brand-label d-grid">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="WaterShutOff" value="WaterShutOff" {...register("DiscountsCheck")} />
                                <label className="form-check-label text-capitalize" htmlFor="WaterShutOff">
                                  Water shut-off device
                                </label>
                              </div>
                            </label>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <label className="brand-label d-grid">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="ULCompliantroofCredit" value="ULCompliantroofCredit" {...register("DiscountsCheck")} />
                                <label className="form-check-label text-capitalize" htmlFor="ULCompliantroofCredit">
                                  UL Compliant roof credit
                                </label>
                              </div>
                            </label>
                          </div>
                          <div className="col-md-6 col-lg-6">
                            <label className="brand-label d-grid">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="ResidentialSprinkler" value="ResidentialSprinkler" {...register("DiscountsCheck")} />
                                <label className="form-check-label text-capitalize" htmlFor="ResidentialSprinkler">
                                  Residential sprinkler
                                </label>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>

                      <h4 className=" wrap-title  mb-3">Last update year:</h4>
                      <div className="col-md-6 col-lg-3">
                        <div className="mb-3">
                          <label className="brand-label">Last Roof updated?</label>
                          <input id="roofDate" type="date" max="2099-12-31" className="form-control" placeholder="Enter Last Roof Update" {...register("roofDate")} />
                          <p className="text-danger">{errors.roofDate && <p>{errors.roofDate.message}</p>}</p>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="mb-3">
                          <label className="brand-label">Last Wiring updated?</label>
                          <input id="electricalDate" type="date" max="2099-12-31" className="form-control" placeholder="Enter Last Wiring Update" {...register("electricalDate")} />
                          <p className="text-danger">{errors.electricalDate && <p>{errors.electricalDate.message}</p>}</p>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="mb-3">
                          <label className="brand-label">Last Plumbing updated?</label>
                          <input type="date" max="2099-12-31" className="form-control" placeholder="Enter Last Plumbing Update" id="plumbingDate" {...register("plumbingDate")} />
                          <p className="text-danger">{errors.plumbingDate && <p>{errors.plumbingDate.message}</p>}</p>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="mb-3">
                          <label className="brand-label">Last Heating updated?</label>
                          <input
                            type="date"
                            max="2099-12-31"
                            className="form-control"
                            placeholder="Enter Last Heating Update"
                            id="hvacDate"
                            name="hvacDate"
                            {...register("hvacDate")}
                          />
                          <p className="text-danger">{errors.hvacDate && <p>{errors.hvacDate.message}</p>}</p>
                        </div>
                      </div>

                      <h4 className="mt-2 wrap-title  mb-3">Claim information (if applicable):</h4>
                      <div className="col-md-6 col-lg-4">
                        <div className="mb-3">
                          <label className="brand-label d-grid">
                            <label className="form-check-label text-capitalize" htmlFor="claimDate">
                              Date of claim
                            </label>
                          </label>
                          <input id={"claimDate"} type="date" max="2099-12-31" className="form-control" placeholder="Date of claim" {...register("claimDate")} />
                        </div>
                        <p className="text-danger">{errors.claimDate?.message}</p>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="mb-3">
                          <label className="brand-label d-grid">
                            <label className="form-check-label text-capitalize" htmlFor="claimReason">
                              Claim Reason
                            </label>
                          </label>
                          <input type="text" id="claimReason" className="form-control" placeholder="Claim Reason" {...register("claimReason")} />
                        </div>
                        <p className="text-danger">{errors.claimReason?.message}</p>
                      </div>
                    </div>
                    <div className="row">
                      <h4 className=" wrap-title  mb-3">Current insurance carrier’s information: (optional)</h4>
                      <div className="col-md-6 col-lg-3">
                        <div className="mb-3">
                          <label className="brand-label">Carrier Name:</label>
                          <input id="carrierName" type="text" className="form-control" placeholder="Enter  Carrier Name" {...register("carrierName")} />
                          <p className="text-danger mt-2">{errors.carrierName && <p>{errors.carrierName.message}</p>}</p>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="mb-3">
                          <label className="brand-label">Renewal Date:</label>
                          <input id="renewalDate" type="date" max="2099-12-31" className="form-control" placeholder="Enter  Renewal Date " {...register("renewalDate")} />
                          <p className="text-danger mt-2">{errors.renewalDate && <p>{errors.renewalDate.message}</p>}</p>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="mb-3">
                          <label className="brand-label">Premium Amount:</label>

                          <Controller
                            name="insurancePremium"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <NumericFormat
                                allowNegative={false}
                                {...field}
                                className="form-control"
                                thousandSeparator={true}
                                prefix="$"
                                onValueChange={(values) => {
                                  setValue("insurancePremium", values.floatValue);
                                }}
                                placeholder="Current insurance premium"
                                {...register("insurancePremium")}
                              />
                            )}
                          />
                          <p className="text-danger mt-2">{errors.insurancePremium && <p>{errors.insurancePremium.message}</p>}</p>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="mb-3">
                          <label className="brand-label">Duration With Current Carrier:</label>
                          <input id="carrierDuration" type="text" className="form-control" placeholder="Carrier Duration" {...register("carrierDuration")} />
                          <p className="text-danger mt-2">{errors.carrierDuration && <p>{errors.carrierDuration.message}</p>}</p>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-10">
                        <div className="mb-3">
                          <label className="brand-label ">
                            <Badge bg="warning" text="dark">
                              {" "}
                              <b>Note: If possible, please send us your current insurance policy to compare the coverages.</b>
                            </Badge>
                          </label>
                        </div>
                      </div>
                      {agentId == null ? (
                        <div className="row">
                          <div className="col-md-6 col-lg-3">
                            {primaryId ? (
                              <label className="brand-label">
                                Selected Agent: &nbsp;
                                <span>{getAgentName()}</span>                              </label>
                            ) : (
                              <div className="mb-3">
                                <label className="brand-label">
                                  {primaryId ? "Selected Agent" : "Select your contact person"}
                                  <span className="required-mark">*</span>
                                </label>
                                <select class="form-select" aria-label="Default select example" {...register("agentId")} disabled={primaryId}>
                                  <option value={""} selected>
                                    Select Agent
                                  </option>
                                  {agentList?.length ? (
                                    agentList.map((agent) => (
                                      <option value={agent.id}>
                                        {agent?.firstName} {agent?.lastName}
                                      </option>
                                    ))
                                  ) : (
                                    <option disabled>No agent found</option>
                                  )}
                                </select>
                                <p className="text-danger mt-2">{errors.agentId && <p>{errors.agentId.message}</p>}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                      <div className="col-md-12 col-lg-12 mt-1">
                        <div className="mb-3">
                          {primaryId && (
                            <div className="text-center mb-3">
                              <button type="button" onClick={() => handleExitButton("Home Insurance Application")} className="btn btn-danger ms-2 text-capitalize">
                                Exit if no changes required
                              </button>
                            </div>
                          )}
                          <div className="d-flex align-items-baseline gap-10">
                            <input className="me-2" type="checkbox" id="terms" {...register("terms")} />
                            <label className="brand-label ">
                              I understand loss runs are required to receive a quote and I hereby allow the agent to retrieve information using the above-mentioned business
                              information and provide me with quote(s) for my perusal. I also understand that quotes are for information and marketing purpose only.
                            </label>
                          </div>

                          <p className="text-danger mt-2">{errors.terms && <p>{errors.terms.message}</p>}</p>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <ReCAPTCHA sitekey="6LeS4L8mAAAAAEGzyClAMce6777ssfu5VYovTkaW" onChange={onChangeRecaptcha} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-end">
                      <button className="btn btn-primary" disabled={!isCaptchaSuccessful}>
                        Submit
                      </button>
                    </div>
                  </section>
                )}
                {isThanksPage && <ThankyouResponse />}
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  );
};

export default HomeApplication;
