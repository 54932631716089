import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import "../App.css";
import { IconBuilding, IconPhone, IconUser } from "@tabler/icons-react";
import { IconMail } from "@tabler/icons-react";
import { useParams } from "react-router-dom";
import useAgencyInfo from "../hooks/useAgencyInfo.hook";
import { useSelector } from "react-redux";

export const AgencyHeader = ({ shouldShowEmail = false }) => {
  const { agencyName, agentName } = useParams();
  // const agencyId = useSelector((state) => state.auth.user.id);
  const { data: agencyData } = useAgencyInfo(agencyName, agentName);
  const [show, setShow] = useState(false);
  const closeSidebar = () => setShow(false);
  const showSidebar = () => setShow(true);
  const [agencydata, Setagencydata] = React.useState(null);
  const [agencylogo, Setagencylogo] = React.useState(null);
  const configVal = process.env.REACT_APP_ANC_API;

  const [isLoading, setLoading] = useState(""); // Loading state

  const queryParams = new URLSearchParams(window.location.search);
  const agent = queryParams.get("id");

  const showheader = queryParams.get("showheader");

  useEffect(() => {
    if (agencyData?.id) getAgencyData();
  }, [agencyData]);

  const getAgencyData = async () => {
    const getAgencyUrl = configVal + "/api/Agency?agencyId=" + agencyData.id;
    const getReqestOption = {
      method: "GET",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        Authorization: `basic ${process.env.REACT_APP_ANC_API_HEADER_KEY}`,
      },
    };
    const res = await fetch(getAgencyUrl, getReqestOption);
    const data = await res.json();
    // console.log(data);
    if (data) {
      localStorage.setItem("agencyInfo", JSON.stringify(data));
      data.base64logo = `data:image/jpeg;base64, ${data.base64Logo}`;

      Setagencydata(data.displayName);
      let logo = data.base64logo;
      logo = logo.replace("data:image/jpeg;base64, data:image/jpeg;base64,", "data:image/jpeg;base64,");
      Setagencylogo(logo);
    }

    setLoading(false);
  };
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <header>
      <div className="container pt-0 pb-0">
        <div className="d-flex  justify-content-between">
          <div className="logo">
            <table>
              <tbody>
                <tr>
                  <td>{agencyData?.id && <img src={agencylogo} alt={"Your agency logo"} />}</td>
                </tr>
                <tr></tr>
              </tbody>
            </table>
          </div>
          <div>
            {agencyData && (
              <table class="table table-borderless " style={{ backgroundColor: "#fff", width: "auto", borderRadius: "16px" }}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        fontSize: "18px",
                        color: "#1C2120",
                      }}
                    >
                      <IconBuilding color="#1C2120" /> {agencyData?.agencyName}
                    </td>
                  </tr>
                  {agencyData?.agent_info_list && (
                    <tr>
                      <td
                        style={{
                          fontWeight: "500",
                          fontSize: "18px",
                          color: "#1C2120",
                        }}
                      >
                        <IconUser /> {`${agencyData?.agent_info_list.firstName} ${agencyData.agent_info_list.lastName}`}
                      </td>
                    </tr>
                  )}

                  {shouldShowEmail && (
                    <tr>
                      <td
                        style={{
                          fontWeigh5: "500",
                          fontSize: "18px",
                          // color: "#fff",
                          color: "#1C2120",
                        }}
                      >
                        <IconMail /> {agencyData?.agent_info_list != null ? agencyData.agent_info_list.email : agencyData?.email}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td
                      style={{
                        fontWeigh5: "500",
                        fontSize: "18px",
                        // color: "#fff",
                        color: "#1C2120",
                      }}
                    >
                      <IconPhone /> {agencyData?.agent_info_list != null ? agencyData.agent_info_list.phoneNo : agencyData?.phone}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default AgencyHeader;
