import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { default_logo } from "../../assets/default_logo";
import { default as axios } from "../../config/axios-config";
import { getAllDataTogether } from "../helper/Helper";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/agency";
const DEVURLPRODUCT = process.env.REACT_APP_ANC_API + "/api/products";

const LeftBar = ({ active, getProfileDetails }) => {
  const dispatch = useDispatch();
  let allData = useSelector((state) => state.someData);
  const userData = useSelector((state) => state.auth.user);
  if (allData?.data.length <= 0 && userData) {
    getAllDataTogether(userData.id, userData.agentId, dispatch);
  }
  console.log("userData", userData);
  const [profileDetails, setProDetails] = useState(null);
  const [activeProducts, setActiveProducts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [base64Logo, setBase64Logo] = useState(null);
  const getProductData = () => {
    if (allData?.data?.leftMenuLinks) {
      setActiveProducts(allData?.data?.leftMenuLinks);
    }
    setLoading(true);
    const url = userData.agentId ? `${DEVURLPRODUCT}/GetLeftMenuLink/${userData.id}/${userData.agentId}` : `${DEVURLPRODUCT}/GetLeftMenuLink/${userData.id}`;
    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          let product = response.data;
          product = product.map((item) => {
            item.activated = item.isUseStatus;
            return item;
          });
          localStorage.setItem("activeProducts", JSON.stringify(product));
          setActiveProducts(product);
        }
      })
      .catch((error) => {
        setLoading(false);
        // handleNotifications("Something is wrong with get products! ", 'error');
        if (error?.response?.data?.status === 404) {
        } else {
        }
      });
  };
  const GetAccountDetails = () => {
    if (allData?.data?.agencyInfo) {
      let data = allData?.data?.agencyInfo;
      // data.base64logo = data.base64Logo ? `data:image/jpeg;base64, ${data.base64Logo}` : null;
      setProDetails(data);
    }
    axios
      .get(`${DEVURL}/getAgencyById/${userData.id}`)
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          // data.base64logo = data.base64Logo ? `data:image/jpeg;base64, ${data.base64Logo}` : null;
          setProDetails(data);
          localStorage.setItem("agency_data", JSON.stringify(response.data));
          setBase64Logo(`data:image/jpeg;base64, ${response.data.base64Logo}`);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status === 404) {
        } else {
        }
      });
  };

  useEffect(() => {}, []);
  useEffect(() => {
    const loginId = localStorage.getItem("LoginId");
    if (!loginId) {
      navigate("/");
    }
    if (userData.id) {
      localStorage.setItem("AgencyId", userData.id);
    }
    GetAccountDetails();
    getProductData();
  }, []);

  const navigate = useNavigate();

  const active_tab = (type) => {
    if (type === active) {
      return "sidebar-active-item";
    }
  };

  const singOut = () => {
    localStorage.clear();
    window.location.replace("/");
  };

  return (
    <div className="col-auto col-md-3 col-xl-2 px-0 ">
      <div
        className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white sidebar-content-alignment bg-sidebar ms-0"
        style={{ zIndex: 99999, height: "100vh", overflow: "auto" }}
      >
        <div className="sidebar-logo">
          {/*<img src="https://www.agentsandcarriers.com/img/logos/anc-logo-trans.png" />*/}
          <img src="/assets/images/my-policy-vault-logo.png" />
        </div>
        <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
          {userData.emailAddress === "contact@agentsandcarriers.com" && (
            <li className={"nav-item sidebar-item " + active_tab("control-panel")}>
              <Link className="nav-link px-0 align-middle" to="/myvault/control-panel">
                <i className="fs-4 bi-speedometer2"></i> <span className="ms-1 d-none d-sm-inline">Control Panel</span>
              </Link>
            </li>
          )}
          <li className={"nav-item sidebar-item " + active_tab("dashboard")}>
            <Link className="nav-link px-0 align-middle" to="/myvault">
              <i className="fs-4 bi-house"></i> <span className="ms-1 d-none d-sm-inline">Home</span>
            </Link>
          </li>
          {userData.userType === 1 && (
            <li className={"nav-item sidebar-item " + active_tab("dashboard")}>
              <Link className="nav-link px-0 align-middle" to="/myvault/products">
                <i className="fs-4 bi-bag"></i> <span className="ms-1 d-none d-sm-inline">Products</span>
              </Link>
            </li>
          )}
          <li className={"nav-item sidebar-item " + active_tab("dashboard")}>
            <Link
              className="nav-link px-0 align-middle"
              to={`/myvault/smartLink/${userData.agentId == null ? `${userData.agencyName}` : `${userData.agencyName}/${userData.userId}`}`}
            >
              <i className="fs-4 bi-link-45deg"></i> <span className="ms-1 d-none d-sm-inline">Smart Link</span>
            </Link>
          </li>
          {userData.emailAddress === "contact@agentsandcarriers.com" && (
            <li className={"nav-item sidebar-item " + active_tab("dashboard")}>
              <Link
                className="nav-link px-0 align-middle"
                to={`/myvault/smartcard/${userData.agentId == null ? `${userData.agencyName}` : `${userData.agencyName}/${userData.userId}`}`}
              >
                <i className="fs-4 bi-credit-card"></i> <span className="ms-1 d-none d-sm-inline">Smart Card</span>
              </Link>
            </li>
          )}
          <li className={"nav-item sidebar-item " + active_tab("activity")}>
            <a href="#Activity" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
              <i className="fs-5 bi bi-dice-4"></i> <span className="ms-1 d-none d-sm-inline">Activity</span>{" "}
            </a>
            <ul className="collapse nav flex-column" id="Activity" data-bs-parent="#menu">
              {activeProducts?.map((item, index) => (
                <>
                  {item.productId !== "mypersonalline" && item.productId !== "mybusinessline" && (
                    <li className={"w-100 " + active_tab("activity")} key={index}>
                      <Link className="nav-link px-0 align-middle" to={"/myvault/activity/" + item.productId.toLowerCase()}>
                        <i className="ms-2 bi bi-hospital-fill"></i>
                        <span className="ms-1 d-none d-sm-inline ">{item.productName}</span>
                      </Link>
                    </li>
                  )}

                  {item.productId === "mypersonalline" && (
                    <>
                      <li className={"w-100 " + active_tab("activity")}>
                        <Link className="nav-link px-0 align-middle" to={"/myvault/activity/home"}>
                          <i className="ms-2 bi bi-hospital-fill"></i>
                          <span className="ms-1 d-none d-sm-inline ">Home</span>
                        </Link>
                      </li>
                      <li className={"w-100 " + active_tab("activity")}>
                        <Link className="nav-link px-0 align-middle" to={"/myvault/activity/auto"}>
                          <i className="ms-2 bi bi-hospital-fill"></i>
                          <span className="ms-1 d-none d-sm-inline ">Auto</span>
                        </Link>
                      </li>
                    </>
                  )}
                  {item.productId === "mybusinessline" && (
                    <>
                      <li className={"w-100 " + active_tab("activity")}>
                        <Link className="nav-link px-0 align-middle" to={"/myvault/activity/restaurantform"}>
                          <i className="ms-2 bi bi-hospital-fill"></i>
                          <span className="ms-1 d-none d-sm-inline ">Restaurant</span>
                        </Link>
                      </li>
                      <li className={"w-100 " + active_tab("activity")}>
                        <Link className="nav-link px-0 align-middle" to={"/myvault/activity/gasstation"}>
                          <i className="ms-2 bi bi-hospital-fill"></i>
                          <span className="ms-1 d-none d-sm-inline ">Gas Station</span>
                        </Link>
                      </li>
                      <li className={"w-100 " + active_tab("activity")}>
                        <Link className="nav-link px-0 align-middle" to={"/myvault/activity/salon"}>
                          <i className="ms-2 bi bi-hospital-fill"></i>
                          <span className="ms-1 d-none d-sm-inline ">Salon/Spa</span>
                        </Link>
                      </li>
                      <li className={"w-100 " + active_tab("activity")}>
                        <Link className="nav-link px-0 align-middle" to={"/myvault/activity/generalContractor"}>
                          <i className="ms-2 bi bi-hospital-fill"></i>
                          <span className="ms-1 d-none d-sm-inline">General Contractor</span>
                        </Link>
                      </li>
                      <li className={"w-100 " + active_tab("activity")}>
                        <Link className="nav-link px-0 align-middle" to={"/myvault/activity/shoppingMall"}>
                          <i className="ms-2 bi bi-hospital-fill"></i>
                          <span className="ms-1 d-none d-sm-inline">Shopping Center</span>
                        </Link>
                      </li>
                      <li className={"w-100 " + active_tab("activity")}>
                        <Link className="nav-link px-0 align-middle" to={"/myvault/activity/generalStore"}>
                          <i className="ms-2 bi bi-hospital-fill"></i>
                          <span className="ms-1 d-none d-sm-inline">General Business</span>
                        </Link>
                      </li>
                      <li className={"w-100 " + active_tab("activity")}>
                        <Link className="nav-link px-0 align-middle" to={"/myvault/activity/hotelForm"}>
                          <i className="ms-2 bi bi-hospital-fill"></i>
                          <span className="ms-1 d-none d-sm-inline">Hotel Commercial</span>
                        </Link>
                      </li>
                    </>
                  )}
                </>
              ))}
            </ul>
          </li>
          {userData.userType === 1 && (
            <li className={"nav-item sidebar-item " + active_tab("agents")}>
              <Link className="nav-link px-0 align-middle" to="/myvault/agents">
                <i className="fs-4  bi-people"></i> <span className="ms-1 d-none d-sm-inline">Agent Management</span>
              </Link>
            </li>
          )}
          {userData.userType === 1 && (
            <li className={"sidebar-item " + active_tab("account-details")}>
              <a href="#submenu3" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
                <i className="fs-4 bi-gear"></i> <span className="ms-1 d-none d-sm-inline">Settings</span>{" "}
              </a>
              <ul className="collapse nav flex-column ms-1" id="submenu3" data-bs-parent="#menu">
                <li className={"w-100 " + active_tab("account-details")}>
                  <Link className="nav-link px-0 align-linear" to="/myvault/account-details">
                    <i className="fs-6 'bi bi-person"></i>
                    <span className="ms-1 d-none d-sm-inline">Account Details</span>
                  </Link>
                </li>
                {/* <li className={"nav-item sidebar-item " + active_tab("agents")}>
                  <Link className="nav-link px-0 align-middle" to="/myvault/agents">
                    <i className="fs-4 bi-bag"></i> <span className="ms-1 d-none d-sm-inline">Agents</span>
                  </Link>
                </li> */}
              </ul>
            </li>
          )}
        </ul>
        <hr />
        <div className="dropdown pb-4">
          {userData.userType === 1 && profileDetails && (
            <>
              <a href="#" className="d-flex align-items-center text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                <img
                  src={profileDetails?.base64Logo ? `data:image/jpeg;base64, ${profileDetails?.base64Logo.replace("data:image/jpeg;base64,", "")}` : default_logo}
                  alt="hugenerd"
                  width="30"
                  height="30"
                  className="rounded-circle"
                />

                <span className="d-none d-sm-inline mx-1">
                  {profileDetails?.firstName || profileDetails?.lastName
                    ? !profileDetails?.firstName && !profileDetails?.lastName
                      ? !profileDetails?.agencyName
                      : (profileDetails?.firstName ?? "") + " " + (profileDetails?.lastName ?? "")
                    : profileDetails?.agencyName}
                </span>
              </a>
              <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                <li>
                  <a className="dropdown-item" href="#" onClick={singOut}>
                    Sign out
                  </a>
                </li>
              </ul>
            </>
          )}
          {userData.userType === 2 && userData.name != null && (
            <>
              <a href="#" className="d-flex align-items-center text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                <img
                  src={profileDetails?.base64Logo ? `data:image/jpeg;base64, ${profileDetails?.base64Logo.replace("data:image/jpeg;base64,", "")}` : default_logo}
                  alt="hugenerd"
                  width="30"
                  height="30"
                  className="rounded-circle"
                />
                <span className="d-none d-sm-inline mx-1">{userData.name}</span>
              </a>
              <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                <li>
                  <a className="dropdown-item" href="#" onClick={singOut}>
                    Sign out
                  </a>
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
// const mapDispatchToProps = {
//     updateData,
// };
//
// export default  connect(null, mapDispatchToProps)(LeftBar);
export default LeftBar;
