import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import * as yup from "yup";
import { currentYear, floatRegex } from "../../Common/regex";
import { useLocation } from "react-router-dom";
import useExitConfirmationHandler from "../../hooks/useExitConfirmationHandler.hook";

const validationSchema = yup.object().shape({
  lastRoofUpdate: yup
    .string()
    .trim()
    .nullable(true)
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);
      return inputDate < currentDate;
    })
    .label("Roof date"),

  lastWireUpdate: yup
    .string()
    .trim()
    .nullable(true)
    .typeError("Please enter valid date")
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Electrical date"),

  lastPlumbingUpdate: yup
    .string()
    .trim()
    .nullable(true)
    .typeError("Please enter valid date")
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Plumbing date"),
  lastHeatUpdate: yup
    .string()
    .trim()
    .nullable(true)
    .typeError("Please enter valid date")
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Havc date"),
  claimDate: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .when("anyClaim", (anyClaim, schema) => {
      console.log("anyClaim", anyClaim);
      if (anyClaim && anyClaim.includes("1")) {
        return schema.required("Claim Date is required");
      } else return schema.notRequired();
    })
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Claim date"),
  yearOfBuild: yup
    .string()
    .trim()
    .required("Year built is required")
    .matches(floatRegex, "Please enter a valid no. of years")
    .test("is-past-or-current-year", "Year must be in the past or current year", function (value) {
      return value <= currentYear;
    })
    .label("Year built"),
  lossRunYear: yup
    .string()
    .trim()
    .nullable(true)
    .matches(floatRegex, "Please enter a valid no. of years")
    .test("is-past-or-current-year", "Year must be in the past or current year", function (value) {
      return value <= currentYear;
    })
    .label("Loss run"),
  noOfBuilding: yup.string().trim().required("No. of buildings is required").matches(floatRegex, "Please enter a valid no. of buildings").label("No. of buildings"),
  buildingValue: yup.string().trim().required("Building value is required").matches(floatRegex, "Please enter a valid building value").nullable(true).label("Building value"),
  pumpValue: yup.string().trim().required("Pump value is required").matches(floatRegex, "Please enter a valid canopy value").nullable(true).label("Pump value"),
  isAutomaticShutPump: yup.string().required("Automatic shutting system is required").label("Automatic shutting system"),
  canopyValue: yup.string().trim().required("Canopy value is required").matches(floatRegex, "Please enter a valid canopy value").nullable(true).label("Canopy value"),
  // propaneSale: yup
  //   .string()
  //   .required("Propane sales is required")
  //   .label("Propane sales"),
  moneyOrder: yup.string().required("Money order is required").label("Money order"),
  signValue: yup.string().trim().nullable(true).matches(floatRegex, "Please enter a valid signed value").label("Signed value"),
  protectiveSafeguard: yup
    .array()
    .typeError("Protective safeguard is required")
    .min(1, "Protective safeguard is required")
    .of(yup.string())
    .required("Protective safeguard is required")
    .label("Protective safeguard"),
  securityLimit: yup
    .string()
    .trim()
    .required("Money & securities limit is required")
    .matches(floatRegex, "Please enter valid money & securities limit")
    .label("Money & securities limit"),

  bppLimit: yup.string().trim().required("Content/BPP limit is required").matches(floatRegex, "Please enter valid content/bpp limit").label("Content/BPP limit"),
  numOfPump: yup.string().trim().required("No. of pumps is required").matches(floatRegex, "Please enter valid no. of pupms").label("No. of pumps"),
  isCheckCashing: yup.string().required("Check cashing is required").label("Check cashing"),
  sprinkerType: yup.string().required("Full/Partial sprinkler is required").label("Full/Partial sprinkler"),

  anyClaim: yup.string().required("Claims is required").label("Claims"),

  claimType: yup
    .string()
    .trim()
    .when("anyClaim", (anyClaim, schema) => {
      if (anyClaim && anyClaim.includes("1")) {
        return schema.required("Claim is required");
      } else return schema.notRequired();
    })
    .label("Claim"),
});

const Step2 = ({ stepHandler, loader }) => {
  const handleExitButton  = useExitConfirmationHandler();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    watch,
  } = useForm({
    defaultValues: {
      yearOfBuild: null,
      noOfBuilding: null,
      buildingValue: null,
      pumpValue: null,
      isAutomaticShutPump: "",
      canopyValue: null,
      propaneSale: null,
      moneyOrder: null,
      signValue: null,
      protectiveSafeguard: [],
      securityLimit: null,
      priorLossAmt: null,
      atmMachineFlag: [],
      bppLimit: null,
      numOfPump: null,
      isCheckCashing: null,
      sprinkerType: "",
      lossRunYear: null,
      anyClaim: null,
      claimType: "",
      lastRoofUpdate: null,
      lastWireUpdate: null,
      lastPlumbingUpdate: null,
      lastHeatUpdate: null,
      claimDate: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const claimsValue = watch("anyClaim");
  const protectiveSafeguard = watch("protectiveSafeguard");
  const atmMachineFlag = watch("atmMachineFlag");
  const lastHeatUpdate = watch("lastHeatUpdate");
  const lastRoofUpdate = watch("lastRoofUpdate");
  const lastPlumbingUpdate = watch("lastPlumbingUpdate");
  const lastWireUpdate = watch("lastWireUpdate");
  const query = new URLSearchParams(useLocation().search);
  const primaryId = query.get("primaryid");
  const fillupStep2GasStationInfoFromLocalStorage = (formData) => {
    setValue("yearOfBuild", formData.yearOfBuild);
    setValue("noOfBuilding", formData.noOfBuilding);
    setValue("buildingValue", formData.buildingValue);
    setValue("pumpValue", formData.pumpValue);
    setValue("isAutomaticShutPump", formData.isAutomaticShutPump);
    setValue("canopyValue", formData.canopyValue);
    setValue("propaneSale", formData.propaneSale);
    setValue("moneyOrder", formData.moneyOrder);
    setValue("signValue", formData.signValue);
    setValue("protectiveSafeguard", formData.protectiveSafeguard);
    setValue("securityLimit", formData.securityLimit);
    setValue("priorLossAmt", formData.priorLossAmt);
    setValue("lastRoofUpdate", formData.lastRoofUpdate);
    setValue("lastWireUpdate", formData.lastWireUpdate);
    setValue("lastPlumbingUpdate", formData.lastPlumbingUpdate);
    setValue("lastHeatUpdate", formData.lastHeatUpdate);
    setValue("atmMachineFlag", formData.atmMachineFlag);
    setValue("bppLimit", formData.bppLimit);
    setValue("numOfPump", formData.numOfPump);
    setValue("isCheckCashing", formData.isCheckCashing);
    setValue("sprinkerType", formData.sprinkerType);
    setValue("lossRunYear", formData.lossRunYear);
    setValue("anyClaim", formData.anyClaim);
    setValue("claimDate", formData.claimDate);
    setValue("claimType", formData.claimType);
    setValue("gasstationinfocol", formData.gasstationinfocol);
    setValue("primaryId", formData.primaryId);
    setValue("customerId", formData.customerId);
  };

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("step2"));
    if (formData) {
      fillupStep2GasStationInfoFromLocalStorage(formData);
    }
  }, []);
  console.log("last", lastWireUpdate);

  useEffect(() => {
    if (claimsValue?.includes("0")) {
      setValue("claimDate", null);
      setValue("claimType", "");
    }
    if (typeof lastHeatUpdate === "string" && lastHeatUpdate.length === 0) {
      setValue("lastHeatUpdate", null);
    }
    if (typeof lastWireUpdate === "string" && lastWireUpdate.length === 0) {
      setValue("lastWireUpdate", null);
    }
    if (typeof lastPlumbingUpdate === "string" && lastPlumbingUpdate.length === 0) {
      setValue("lastPlumbingUpdate", null);
    }
    if (typeof lastRoofUpdate === "string" && lastRoofUpdate.length === 0) {
      setValue("lastRoofUpdate", null);
    }
  }, [claimsValue, lastHeatUpdate, lastRoofUpdate, lastPlumbingUpdate, lastWireUpdate]);

  const setStep2ToLocalStorage = (data) => {
    localStorage.setItem("step2", JSON.stringify(data));
  };

  const handleFormSubmit = (data) => {
    if (data.lastRoofUpdate?.trim().length === 0) {
      data.lastRoofUpdate = null;
    }
    if (data.lastWireUpdate?.trim().length === 0) {
      data.lastWireUpdate = null;
    }
    if (data.lastPlumbingUpdate?.trim().length === 0) {
      data.lastPlumbingUpdate = null;
    }
    if (data.lastHeatUpdate?.trim().length === 0) {
      data.lastHeatUpdate = null;
    }
    stepHandler(3);
    setStep2ToLocalStorage(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)} className="tab-wizard wizard-circle wizard needs-validation">
        <section>
          <h4 class="wrap-title mb-3">Building Information</h4>
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label">
                  Year Built?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="yearOfBuild"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="yearOfBuild"
                      className="form-control"
                      name="yearOfBuild"
                      onValueChange={(values) => {
                        setValue("yearOfBuild", values.value);
                      }}
                      placeholder="Enter Number of Buildings"
                      {...register("yearOfBuild")}
                    />
                  )}
                />

                <p className="text-danger">{errors.yearOfBuild?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label">
                  No. of Buildings?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="noOfBuilding"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="noOfBuilding"
                      className="form-control"
                      thousandSeparator={true}
                      name="noOfBuilding"
                      onValueChange={(values) => {
                        setValue("noOfBuilding", values.floatValue);
                      }}
                      placeholder="Enter Number of Buildings"
                      {...register("noOfBuilding")}
                    />
                  )}
                />
                <p className="text-danger">{errors.noOfBuilding?.message}</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label">
                  Building Value?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="buildingValue"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="buildingValue"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="buildingValue"
                      onValueChange={(values) => {
                        setValue("buildingValue", values.floatValue);
                      }}
                      placeholder="Enter Building Value"
                      {...register("buildingValue")}
                    />
                  )}
                />

                <p className="text-danger">{errors.buildingValue && <p>{errors.buildingValue.message}</p>}</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label">
                  Pump Value?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="pumpValue"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="pumpValue"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="pumpValue"
                      onValueChange={(values) => {
                        setValue("pumpValue", values.floatValue);
                      }}
                      placeholder="Enter Pump Value"
                      {...register("pumpValue")}
                    />
                  )}
                />

                <p className="text-danger">{errors.pumpValue && <p>{errors.pumpValue.message}</p>}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label">
                  Canopy Value?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="canopyValue"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="canopyValue"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="canopyValue"
                      onValueChange={(values) => {
                        setValue("canopyValue", values.floatValue);
                      }}
                      placeholder="Enter Canopy Value"
                      {...register("canopyValue")}
                    />
                  )}
                />

                <p className="text-danger">{errors.canopyValue && <p>{errors.canopyValue.message}</p>}</p>
              </div>
            </div>
            
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label">Sign Value?</label>
                <Controller
                  name="signValue"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="signValue"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="signValue"
                      onValueChange={(values) => {
                        setValue("signValue", values.floatValue);
                      }}
                      placeholder="Enter Sign Value"
                      {...register("signValue")}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label d-grid">
                  <div>
                    Money & Securities Limit?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <Controller
                  name="securityLimit"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="securityLimit"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="securityLimit"
                      onValueChange={(values) => {
                        setValue("securityLimit", values.floatValue);
                      }}
                      placeholder="Enter Money & Securities Limit"
                      {...register("securityLimit")}
                    />
                  )}
                />
              </div>
              <p className="text-danger">{errors.securityLimit?.message}</p>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label d-grid">Prior Losses</label>
                <Controller
                  name="priorLossAmt"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="priorLossAmt"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="priorLossAmt"
                      onValueChange={(values) => {
                        setValue("priorLossAmt", values.floatValue);
                      }}
                      placeholder="Enter prior losses"
                      {...register("priorLossAmt")}
                    />
                  )}
                />
              </div>
              <p className="text-danger">{errors.priorLossAmt?.message}</p>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label">
                  Content/BPP Limit?
                  <span className="required-mark">*</span>
                </label>
                <Controller
                  name="bppLimit"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="bppLimit"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="bppLimit"
                      onValueChange={(values) => {
                        setValue("bppLimit", values.floatValue);
                      }}
                      placeholder="Enter Content/BPP Limit"
                      {...register("bppLimit")}
                    />
                  )}
                />

                <p className="text-danger">{errors.bppLimit && <p>{errors.bppLimit.message}</p>}</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label">
                  No. of Pumps?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="numOfPump"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="numOfPump"
                      className="form-control"
                      thousandSeparator={true}
                      name="numOfPump"
                      onValueChange={(values) => {
                        setValue("numOfPump", values.floatValue);
                      }}
                      placeholder="Enter No. of Pumps"
                      {...register("numOfPump")}
                    />
                  )}
                />

                <p className="text-danger">{errors.numOfPump && <p>{errors.numOfPump.message}</p>}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                  Money Order?<span className="required-mark">*</span>
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="moneyOrderYes" value="1" {...register("moneyOrder")} />
                  <label class="form-check-label" for="moneyOrderYes">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="moneyOrderNo" value="0" {...register("moneyOrder")} />
                  <label class="form-check-label" for="moneyOrderNo">
                    No
                  </label>
                </div>
                <p className="text-danger">{errors.isCheckCashing && <p>{errors.isCheckCashing.message}</p>}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                    Propane Sales?
                    {/* <span className="required-mark">*</span> */}
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="propaneExchange" value="Exchange" {...register("propaneSale")} />
                  <label class="form-check-label" for="propaneExchange">
                    Exchange
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="propaneRefill" value="Refill" {...register("propaneSale")} />
                  <label class="form-check-label" for="propaneRefill">
                    Refill
                  </label>
                </div>
                {/* <p className="text-danger">
                  {errors.propaneSale && <p>{errors.propaneSale.message}</p>}
                </p> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Last Roof Updated?</label>
                <input id="lastRoofUpdate" type="date" max="2099-12-31" className="form-control" placeholder="Enter Last Roof Update Date" {...register("lastRoofUpdate")} />
                <p className="text-danger">{errors.lastRoofUpdate && <p>{errors.lastRoofUpdate.message}</p>}</p>
              </div>
            </div>
            {/* <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                    Check Cashing?<span className="required-mark">*</span>
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="checkCashingYes" value="1" {...register("isCheckCashing")} />
                  <label class="form-check-label" for="checkCashingYes">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="checkCashingNo" value="0" {...register("isCheckCashing")} />
                  <label class="form-check-label" for="checkCashingNo">
                    No
                  </label>
                </div>
                <p className="text-danger">{errors.isCheckCashing && <p>{errors.isCheckCashing.message}</p>}</p>
              </div>
            </div> */}
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Last Wiring Updated?</label>
                <input id="lastWireUpdate" type="date" max="2099-12-31" className="form-control" placeholder="Enter Last Wiring Update Date" {...register("lastWireUpdate")} />
                <p className="text-danger">{errors.lastWireUpdate && <p>{errors.lastWireUpdate.message}</p>}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Last Plumbing Updated?</label>
                <input
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Last Plumbing Update Date"
                  id="lastPlumbingUpdate"
                  {...register("lastPlumbingUpdate")}
                />
                <p className="text-danger">{errors.lastPlumbingUpdate && <p>{errors.lastPlumbingUpdate.message}</p>}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Last Heating Updated?</label>
                <input
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Last Heating Update Date"
                  id="lastHeatUpdate"
                  name="lastHeatUpdate"
                  {...register("lastHeatUpdate")}
                />
                <p className="text-danger">{errors.lastHeatUpdate && <p>{errors.lastHeatUpdate.message}</p>}</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                    Check Cashing?<span className="required-mark">*</span>
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="checkCashingYes" value="1" {...register("isCheckCashing")} />
                  <label class="form-check-label" for="checkCashingYes">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="checkCashingNo" value="0" {...register("isCheckCashing")} />
                  <label class="form-check-label" for="checkCashingNo">
                    No
                  </label>
                </div>
                <p className="text-danger">{errors.isCheckCashing && <p>{errors.isCheckCashing.message}</p>}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                    {" "}
                    Full/Partial Sprinkler
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="fullSprinklerCheck" value="Full" {...register("sprinkerType")} />
                  <label class="form-check-label" for="fullSprinklerCheck">
                    Full
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="partialSprinklerCheck" value="Partial" {...register("sprinkerType")} />
                  <label class="form-check-label" for="partialSprinklerCheck">
                    Partial
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="noneSprinklerCheck" value="None" {...register("sprinkerType")} />
                  <label class="form-check-label" for="noneSprinklerCheck">
                    None
                  </label>
                </div>
                <p className="text-danger">{errors.sprinkerType && <p>{errors.sprinkerType.message}</p>}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                    {" "}
                    Any Claims in past 5 years?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="claimYes" value="1" {...register("anyClaim")} />
                  <label class="form-check-label" for="claimYes">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="claimNo" value="0" {...register("anyClaim")} />
                  <label class="form-check-label" for="claimNo">
                    No
                  </label>
                </div>
                <p className="text-danger">{errors.anyClaim && <p>{errors.anyClaim.message}</p>}</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>Claim Type?</div>
                </label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="propertyCheck"
                    value="Property"
                    disabled={!claimsValue?.includes("1")}
                    {...register("claimType")}
                  />
                  <label class="form-check-label" for="propertyCheck">
                    Property
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="GLICheck"
                    value="GL"
                    disabled={!claimsValue?.includes("1")}
                    {...register("claimType")}
                  />
                  <label class="form-check-label" for="GLICheck">
                    GL
                  </label>
                </div>

                <p className="text-danger">{errors.claimType && <p>{errors.claimType.message}</p>}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Claim Date?</label>
                <input
                  id="claimDate"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Claim Date"
                  disabled={!claimsValue?.includes("1")}
                  {...register("claimDate")}
                />
                <p className="text-danger">{errors.claimDate && <p>{errors.claimDate.message}</p>}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label">Loss Run (Years)</label>
                <Controller
                  name="lossRunYear"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="lossRunYear"
                      className="form-control"
                      name="lossRunYear"
                      onValueChange={(values) => {
                        setValue("lossRunYear", values.value);
                      }}
                      placeholder="Enter Loss Run (Years)"
                      {...register("lossRunYear")}
                    />
                  )}
                />
                <p className="text-danger">{errors.lossRunYear && <p>{errors.lossRunYear.message}</p>}</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-6">
              <div class="mb-3">
                <label class="brand-label">
                  Automatic Shut of System for Pumps
                  <span className="required-mark">*</span>
                </label>
                <div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="pumpYes" value="yes" {...register("isAutomaticShutPump")} />

                    <label class="form-check-label" for="pumpYes">
                      Yes
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="pumpsNo" value="no" {...register("isAutomaticShutPump")} />
                    <label class="form-check-label" for="pumpsNo">
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">{errors.isAutomaticShutPump && <p>{errors.isAutomaticShutPump.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label className="brand-label">
                  Protective Safeguard?
                  <span className="required-mark">*</span>{" "}
                </label>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="cctvCheck" value="CCTV Camera" {...register("protectiveSafeguard")} />
                      <label className="form-check-label text-capitalize" for="cctvCheck">
                        CCTV Camera
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="fireAlarmCheck" value="Fire Alarm" {...register("protectiveSafeguard")} />
                      <label className="form-check-label text-capitalize" for="fireAlarmCheck">
                        Fire Alarm
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="burglarAlarmCheck" value="Burglar Alarm" {...register("protectiveSafeguard")} />
                      <label className="form-check-label text-capitalize" for="burglarAlarmCheck">
                        Burglar Alarm
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {" "}
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="centralStationCheck" value="Central Station" {...register("protectiveSafeguard")} />
                      <label className="form-check-label text-capitalize" for="centralStationCheck">
                        Central Station
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="smokeDetectionCheck" value="Smoke Detector" {...register("protectiveSafeguard")} />
                      <label className="form-check-label text-capitalize" for="smokeDetectionCheck">
                        Smoke Detector
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="sprinklerCheck" value="Sprinkler System" {...register("protectiveSafeguard")} />
                      <label className="form-check-label text-capitalize" for="sprinklerCheck">
                        Sprinkler System
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="othersCheck" value="Others" {...register("protectiveSafeguard")} />
                      <label className="form-check-label text-capitalize" for="othersCheck">
                        Others
                      </label>
                    </div>
                  </div>
                </div>

                <p className="text-danger">{errors.protectiveSafeguard?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>ATM Machine (Inside/Outside the Building)?</div>
                </label>
                <div class="form-check">
                  <input class="form-check-input" id="insideCheck" type="checkbox" name="inlineRadioOptions" value="Inside the Building" {...register("atmMachineFlag")} />
                  <label class="form-check-label" for="insideCheck">
                    Inside the Building
                  </label>
                </div>
                <div class="form-check ">
                  <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="outsideCheck" value="Outside the Building" {...register("atmMachineFlag")} />
                  <label class="form-check-label" for="outsideCheck">
                    Outside the Building
                  </label>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="d-flex justify-content-end">
          {primaryId && (
            <button type="button" onClick={() => handleExitButton("Gas Station Insurance Application")} className="btn btn-danger ms-2 text-capitalize ">
              Exit if no changes required
            </button>
          )}
          <button type="button" className="btn btn-warning ms-2" onClick={() => stepHandler(1)}>
            Previous
          </button>
          <button className="btn btn-primary ms-2">Next</button>
        </div>
      </form>
    </>
  );
};

export default Step2;
