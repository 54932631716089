import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { emailRegex, nameRegex, cellRegex, paswordRegex } from '../../../Common/regex';
import { default as axios } from "../../../config/axios-config";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from 'react-router-dom';
import Footer from "../../ancfooter";
import MyvaultHeader from "../../Header";

const DEVURL = process.env.REACT_APP_ANC_API + "/api";

const signupValidationSchema = yup.object().shape({
    agencyName: yup.string().trim().required().matches(nameRegex, 'Please enter a valid Agency Name').max(45).label('Agency Name'),
    firstName: yup.string().trim().required().matches(nameRegex, 'Please enter a valid First Name').max(45).label('First Name'),
    lastName: yup.string().trim().required().matches(nameRegex, 'Please enter a valid Last Name').max(45).label('Last Name'),
    contactNumber: yup.string().nullable(null).matches(/^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[0-9]{3}[\s\-]?[0-9]{4}$|^$/, "Please enter a valid contact number").label("Contact Number"),
    emailAddress: yup.string().trim().required().test('emailAddress', 'Please enter a valid Email Address or Mobile Number', (value) => {
        return validateEmail(value) || validatePhone(value);
    }).max(45).label('Mobile Number or Email Address'),
    password: yup.string().trim().required().min(8).max(45).matches(paswordRegex, 'Password must contain alphanumeric characters').label('Password'),
    termsCondition: yup.boolean().oneOf([true], "Terms and conditions is a required field"),
});

const validateEmail = (email) => {
    return yup.string().matches(emailRegex).isValidSync(email);
};

const validatePhone = (phone) => {
    return yup.string().matches(cellRegex).isValidSync(phone);
};

const SignUp = () => {

    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const agencyId = queryParams.get("id");
    const verifyId = queryParams.get("verifyid");

    const [errorMessage, setErrorMessage] = useState('');
    const [captchaErrorMessage, setCaptchaErrorMessage] = useState('');
    const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset
    } = useForm({
        defaultValues: {
            agencyName: "",
            firstName: "",
            lastName: "",
            contactNumber: "",
            emailAddress: "",
            password: "",
            termsCondition: false
        },
        resolver: yupResolver(signupValidationSchema),
    });

    useEffect(() => {
        if (verifyId) {
            isPasscodeActive(verifyId);
            getAgency();
        }
    }, []);

    const cleanlocalStore = () => {
        localStorage.removeItem('agency_data');
        localStorage.removeItem('activeProducts');
        localStorage.removeItem('AgencyName');
    };
    const handleFormSubmit = (formData) => {
        insertAgency(formData);
    };

    const getAgency = async () => {
        await axios.get(`${DEVURL}/Agency/getAgencyById/${agencyId}`)
            .then(response => {
                if (response.status === 200) {
                    setValue("agencyName", response.data.agencyName);
                    setValue("emailAddress", response.data.email ? response.data.email : response.data.phone);
                    setValue("password", response.data.password);
                    setValue("termsCondition", response.data.termsCondition);
                }
            }).catch((error) => {
                console.log(error);
            });
    }

    const isPasscodeActive = async (verifyId) => {
        await axios.get(`${DEVURL}/Verify/IsActivePasscode/${verifyId}`)
            .then(response => {
                if (response.status === 200) {
                    if (response.data !== 1) {
                        navigate(`/verification?verify=${verifyId}`);
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
    }

    const insertAgency = async (data) => {
        if (!isCaptchaSuccessful) {
            setCaptchaErrorMessage("Please check the captcha");
            return;
        }
        setErrorMessage('');
        await axios
            .post(`${DEVURL}/agency/insertAgency`, data)
            .then(res => {
                if (res.status === 200) {
                    reset();
                    navigate(`/verification?verify=${res.data}&loginid=${data.emailAddress}`);
                }
            })
            .catch(error => {
                setErrorMessage(error?.response?.data);
            });
    }

    const onChangeRecaptcha = (value) => {
        setIsCaptchaSuccess(true);
        setCaptchaErrorMessage("");
    }

    return (
        <>

            <MyvaultHeader />
            <section className="hero-section section-spacing">
                <div className="container" style={{ "z-index": "9999" }}>
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="hero-form">
                                <h5>Sign up for free. No credit card required. </h5>
                                <form onSubmit={handleSubmit(handleFormSubmit)}>
                                    <div className="mb-md-3">
                                        {errorMessage && <p className="text-danger mt-3">{errorMessage}</p>}
                                        <div className="form-floating mb-3">
                                            <input type="text"
                                                className="form-control brand-input"
                                                id="floatingAgencyName"
                                                placeholder="Agency Name"
                                                {...register("agencyName")} />
                                            <label htmlFor="floatingAgencyName">Agency Name</label>
                                            <div className="text-danger mt-1">
                                                {errors.agencyName && (
                                                    <p>{errors.agencyName.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-floating mb-3">
                                                    <input type="text"
                                                        className="form-control brand-input"
                                                        id="floatingFirstName"
                                                        placeholder="First Name"
                                                        {...register("firstName")} />
                                                    <label htmlFor="floatingFirstName">First Name</label>
                                                    <div className="text-danger mt-1">
                                                        {errors.firstName && (
                                                            <p>{errors.firstName.message}</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-floating mb-3">
                                                    <input type="text"
                                                        className="form-control brand-input"
                                                        id="floatingLastName"
                                                        placeholder="Last Name"
                                                        {...register("lastName")} />
                                                    <label htmlFor="floatingLastName">Last Name</label>
                                                    <div className="text-danger mt-1">
                                                        {errors.lastName && (
                                                            <p>{errors.lastName.message}</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input type="text"
                                                className="form-control brand-input"
                                                id="floatingContactNumber"
                                                placeholder="Contact Number"
                                                {...register("contactNumber")} />
                                            <label htmlFor="floatingContactNumber">Contact Number</label>
                                            <div className="text-danger mt-1">
                                                {errors.contactNumber && (
                                                    <p>{errors.contactNumber.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input type="text"
                                                className="form-control brand-input"
                                                id="floatingEmailAddress"
                                                placeholder="name@example.com"
                                                {...register("emailAddress")}
                                            />
                                            <label htmlFor="floatingEmailAddress">Email Address</label>
                                            <div className="text-danger mt-1">
                                                {errors.emailAddress && (
                                                    <p>{errors.emailAddress.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-floating">
                                            <input type="password"
                                                className="form-control brand-input"
                                                id="floatingPassword"
                                                placeholder="Password"
                                                {...register("password")}
                                            />
                                            <label htmlFor="floatingPassword">Password</label>
                                            <div className="text-danger mt-1">
                                                {errors.password && (
                                                    <p>{errors.password.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-floating mt-3"><h6>
                                            <input
                                                type="checkbox"
                                                id="floatingeTermsCondition"
                                                {...register("termsCondition")}
                                            />
                                            &nbsp; I accept the <a href="termsCondition.html" target="_blank">Terms of Service.</a></h6>
                                            <p className="text-danger mt-1">
                                                {errors.termsCondition && <p>{errors.termsCondition.message}</p>}
                                            </p>
                                        </div>
                                        <div className="form-floating mt-3">
                                            <ReCAPTCHA sitekey="6LeS4L8mAAAAAEGzyClAMce6777ssfu5VYovTkaW" onChange={onChangeRecaptcha} />
                                            {captchaErrorMessage && <p className="text-danger mt-3">{captchaErrorMessage}</p>}
                                        </div>
                                    </div>

                                    <button type="submit" className="brand-btn">Create Account</button>
                                    <div className="text-center mt-3">
                                        <p>Already have Account ?
                                            <Link className="brand-link p-2" to="/">
                                                Sign In
                                            </Link>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="hero-content">
                                <h1>Unleash the Potential of MyPolicyVault</h1>
                                <p><a href="https://wwww.agentsandcarriers.com" className="nav-link">Learn more</a>  </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="hero-custom-shape-divider-bottom">

                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                        preserveAspectRatio="none">
                        <path
                            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                            className="shape-fill"></path>
                    </svg>

                </div>
            </section>
            <Footer />
        </>
    )
}

export default SignUp;