import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./Components/portal/Dashboard";
import Layout from "./Components/portal/Layout/Layout";
import Products from "./Components/portal/Products";
import AccountDetails from "./Components/portal/ProfileDetails";
import Activities from "./Components/portal/activities/Activities";
import AutoHome from "./Components/portal/activities/AutoHome";
import Business from "./Components/portal/activities/Business";
import GasStation from "./Components/portal/activities/GasStation";
import GeneralContractor from "./Components/portal/activities/GeneralContractor";
import Restaurant from "./Components/portal/activities/Restaurant";
import Tanks from "./Components/portal/activities/Tanks";
import Agents from "./Components/portal/agents/Agents";
import SmartLink from "./Components/SmartLink/SmartLink";
import AdminDashboard from "./Components/portal/AdminDashboard";
import SmartCard from "./Components/SmartCard/SmartCard";

const PortalRoutes = () => {
  return (
    <Layout>
      <Routes> 
        <Route path="/" element={<Dashboard />} />
        <Route path="/control-panel" element={<AdminDashboard />} />
        <Route path="/activity/:type" element={<Activities />} />
        <Route path="/auto" element={<AutoHome />} />
        <Route path="/business" element={<Business />} />
        <Route path="/gas-station" element={<GasStation />} />
        <Route path="/restaurant/agencyName" element={<Restaurant />} />
        <Route path="/tanks" element={<Tanks />} />
        <Route path="/account-details" element={<AccountDetails />} />
        <Route path="/products" element={<Products />} />
        <Route path="/agents" element={<Agents />} />
        <Route path="/generalContractor" element={<GeneralContractor />} />
        <Route path="/smartLink/:agencyName?/:agentName?" element={<SmartLink/>} />
        <Route path="/smartCard/:agencyName?/:agentName?" element={<SmartCard/>} />
      </Routes>
    </Layout>
  );
};

export default PortalRoutes;
