import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import imgfile from "../../assets/Image_Services.jpg";

const WelcomeModal = ({ showModal, closeModal }) => {
  const [lgShow, setLgShow] = useState(true);

  return (
    <>
      <Modal
        size="lg"
        show={showModal}
        onHide={() => closeModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Welcome to MyPolicyVault
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`p-0`}>
          <div className="col-20 header-of-welcome">
            <img
              src={imgfile}
              width={800}
              height={250}
              align="center"
              alt=""
            ></img>
          </div>
          <p className={`py-4 mx-4`}>
            Our mission is to simplify your processes, enhance your customer
            experiences, and boost your operational efficiency through our
            state-of-the-art digital tools. With our innovative technology,
            you'll be equipped to deliver the best possible service to your
            clients and stay ahead of the competition. All our tools are made
            for independent insurance agencies by insurance agents.
            <br />
            Watch our Video{" "}
            <a
              href="https://www.youtube.com/watch?v=mheP2xv1POs&list=PL9OmvZCstRqRWQRG2RFcGTmYyOHZPR2_a"
              target="_blank"
              rel="noreferrer"
            >
              <span class="badge text-bg-info">SEE HOW IT WORKS</span>
            </a>
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WelcomeModal;
