export const nameRegex = /^[A-Za-z ]*?$/;
export const alphaNumericRegex = /^[a-zA-Z]*\d?$/;
export const zipcodeRegex = /^[0-9]+$/;
export const cellRegex =
  /^(\([0-9]{3}\)|[0-9]{3}?)[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const floatRegex = /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)?$/;
export const paswordRegex =
  /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\-]*$/;
export const passwordStrongRegex =
  /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])/;
export const currentYear = new Date().getFullYear();
export const yearRegex = /^\d{4}$/;
