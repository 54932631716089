import { useEffect, useState } from "react";
import axios from "axios";

const useAgencyInfo = (agencyName, agentName) => {
  const [agencyId, setAgencyId] = useState(null);
  const [agentId, setAgentId] = useState(null);
  const [data, setData] = useState(null);
  const [isValidUrl, setIsValidUrl] = useState();
  const DEVURL = process.env.REACT_APP_ANC_API + "/api";

  useEffect(() => {
    if (agencyId === null) {
      const url = agentName ? `${DEVURL}/manageagency/GetUserInfoByName/${agencyName}/${agentName}` : `${DEVURL}/manageagency/GetUserInfoByName/${agencyName}`;

      axios
        .get(url)
        .then((response) => {
          setData(response.data);
          setAgencyId(response.data.id);
          if (response.data.agent_info_list !== null) {
            setAgentId(response.data?.agent_info_list.id);
          }
          setIsValidUrl(true);
        })
        .catch((error) => {
          console.error("Error fetching agency info:", error);
          setIsValidUrl(false);
        });
    }
  }, []);

  return { data, agencyId, agentId, isValidUrl };
};

export default useAgencyInfo;
