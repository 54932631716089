import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import { handleNotifications } from "../helper/Helper";
import { useSelector } from "react-redux";

const DEVURL = process.env.REACT_APP_ANC_API + "/api";

const AddComment = ({ details, getProduct }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    setValue,
  } = useForm();
  const [loader, setLoader] = useState(false);
  const sourceList = ["Email", "Text", "Call"];
  const userData = useSelector((state) => state.auth.user);
  console.log("details", details);
  const handleFormSubmit = (formData) => {
    console.log("formData", formData);
    const updatedData = {
      ...formData,
      CustomerId: details.id,
      AgencyId: userData.id,
      AgentId: userData.agentId,
    };
    axios
      .post(`${DEVURL}/ControlPanel/CreateComments`, updatedData)
      .then((res) => {
        if (res.status === 200) {
          getProduct();
          reset();
          handleNotifications("Successfully added comment!");
        } else {
          handleNotifications("Failed to add the comment!", "error");
        }
      })
      .catch((error) => {
        handleNotifications("Failed to the comment!", "error");
      });
  };

  return (
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="addComment" aria-labelledby="offcanvasRightLabel">
      <div className="offcanvas-header activity-status-table brand-offcanvas-header">
        <h5 id="offcanvasRightLabel">Add Comment</h5>
        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <div className="activity-cust-data">
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <section className="mt-3">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="brand-label">
                      Comment Source<span className="required-mark">*</span>
                    </label>
                    <select class="form-select" aria-label="Default select example" {...register("CommentSource")}>
                      {sourceList?.length ? sourceList.map((source) => <option value={source}>{source}</option>) : <option disabled>No source found</option>}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="brand-label">
                      Comment <span className="required-mark">*</span>
                    </label>
                    <textarea name="note" className="form-control" {...register("CommentText")}></textarea>
                  </div>
                </div>
              </div>
            </section>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button className="btn btn-outline-secondary me-md-2" type="button" data-bs-dismiss="offcanvas">
                Cancel
              </button>
              <button className="btn btn-primary" type="submit">
                <div className="button-body-for-loader">
                  <span>Save</span>
                  {loader && <ThreeDots color="#ffdb58" height={30} width={40} />}
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddComment;
