import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ThreeDots, Watch } from "react-loader-spinner";
import { default as axios } from "../../../config/axios-config";
import { formatTelNumber, handleNotifications } from "../../helper/Helper";
import AgentValidationSchema from "./AgentValidationSchema";
import { useSelector } from "react-redux";
import { IconEye } from "@tabler/icons-react";
import { IconEyeOff } from "@tabler/icons-react";

const Agent = ({ selectedAgent, setTableRenderar }) => {
  const agencyId = useSelector((state) => state.auth.user.id);
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,getValues,
    control,
    watch,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      status: "1",
      password: "",
      userId: "",
      phoneNo: "",
      ProductAllowed: [],
    },
    resolver: yupResolver(AgentValidationSchema(Boolean(selectedAgent))),
  });

  const [loader, setLoader] = useState(false);
  const [addEditTitle, setAddEditTitle] = useState("Add Agent");
  const [addEditButtonText, setAddEditButtonText] = useState("Save");
  const phoneNo = watch("phoneNo");
  const activeProducts = JSON.parse(localStorage.getItem("activeProducts"));
  console.log("activeProducts", activeProducts);
  console.log("getValues", getValues('ProductAllowed'));

  const fillupAgentFields = (agent) => {
    setValue("firstName", agent?.firstName);
    setValue("lastName", agent?.lastName);
    setValue("email", agent?.email);
    setValue("status", agent?.status);
    setValue("password", agent?.password);
    setValue("userId", agent?.userId);
    setValue("phoneNo", agent?.phoneNo);
    setValue("ProductAllowed", agent?.productAllowed);
  };
  useEffect(() => {
    console.log("selectedAgentInside", selectedAgent);
    if (selectedAgent != null) {
      fillupAgentFields(selectedAgent);
      setAddEditTitle("Edit Agent");
      setAddEditButtonText("Update");
    } else {
      reset();
      setAddEditTitle("Add Agent");
      setAddEditButtonText("Save");
    }
  }, [selectedAgent]);

  const save = async (data) => {
    setLoader(true);
    const updatedData = {
      ...data,
      agencyId: agencyId,
    };
    await axios
      .post(`${process.env.REACT_APP_ANC_API}/api/agent/create`, updatedData)
      .then((res) => {
        if (res.status === 200) {
          reset();
          handleNotifications("Agent infomation has been saved successfully");
          setTableRenderar(true);
          setLoader(false);
        }
      })
      .catch((error) => {
        handleNotifications("Failed to save agent infomation");
        setLoader(false);
        if (error.response.data === "Email already exists") setError("email", { type: "custom", message: error.response.data });
        if (error.response.data === "User id already exists") setError("userId", { type: "custom", message: error.response.data });
      });
  };

  const update = async (data) => {
    setValue("userId", selectedAgent?.userId);
    const updatedData = {
      ...data,
      userId: selectedAgent.userId,
    };
    setLoader(true);
    await axios
      .put(`${process.env.REACT_APP_ANC_API}/api/agent/update/${selectedAgent.id}`, updatedData)
      .then((res) => {
        if (res.status === 200) {
          reset();
          setTableRenderar(true);
          handleNotifications("Agent infomation has been updated successfully");
          setLoader(false);
        }
      })
      .catch((error) => {
        handleNotifications("Failed to update agent infomation");
        setLoader(false);
        console.log(error);
      });
  };

  const handleFormSubmit = async (data) => {
    const answer = window.confirm(`Are you sure you want to ${addEditButtonText.toLowerCase()} agent infomation?`);
    if (answer) {
      if (!selectedAgent) {
        await save(data);
      } else {
        await update(data);
      }
    }
  };

  return (
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="agent" aria-labelledby="offcanvasRightLabel">
      <div className="offcanvas-header activity-status-table brand-offcanvas-header">
        <h5 id="offcanvasRightLabel">{addEditTitle}</h5>
        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <div className="activity-cust-data">
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <section className="mt-3">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="brand-label">
                      First Name<span className="required-mark">*</span>
                    </label>
                    <input id="firstName" type="text" className="form-control" placeholder="Enter First Name" {...register("firstName")} />
                    <p className="text-danger mt-2">{errors.firstName && <p>{errors.firstName.message}</p>}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="brand-label">
                      Last Name<span className="required-mark">*</span>
                    </label>
                    <input id="lastName" type="text" className="form-control" placeholder="Enter Last Name" {...register("lastName")} />
                    <p className="text-danger mt-2">{errors.lastName && <p>{errors.lastName.message}</p>}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="brand-label">
                      Phone No<span className="required-mark">*</span>
                    </label>
                    {/* <input id="phoneNo" type="text" className="form-control" placeholder="Enter Phone No" {...register("phoneNo")} /> */}
                    <Controller
                      name="phoneNo"
                      control={control}
                      render={({ field }) => (
                        <input {...field} type="text" class="form-control" placeholder="(XXX) XXX-XXXX" value={formatTelNumber(phoneNo)} {...register("phoneNo")} />
                      )}
                    />
                    <p className="text-danger mt-2">{errors.phoneNo && <p>{errors.phoneNo.message}</p>}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="brand-label">
                      User Id<span className="required-mark">*</span>
                    </label>
                    <input id="userId" type="text" className="form-control" placeholder="Enter User Id" {...register("userId", { disabled: Boolean(selectedAgent) })} />
                    <p className="text-danger mt-2">{errors.userId && <p>{errors.userId.message}</p>}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="brand-label">
                      Email Address<span className="required-mark">*</span>
                    </label>
                    <input id="email" type="text" className="form-control" placeholder="Enter Email Address" {...register("email")} />
                    <p className="text-danger mt-2">{errors.email && <p>{errors.email.message}</p>}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3 position-relative">
                    <label className="brand-label">
                      Password<span className="required-mark">*</span>
                    </label>
                    <input
                      autocomplete="new-password"
                      id="password"
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      placeholder="Enter Password"
                      {...register("password")}
                    />
                    <div
                      className="btn toggle-password-button"
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      style={{
                        position: "absolute",
                        right: "0px",
                        top: "45%",
                        zIndex: 2,
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      {showPassword ? <IconEye /> : <IconEyeOff />}
                    </div>
                    <p className="text-danger mt-2">{errors.password && <p>{errors.password.message}</p>}</p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  {/* <div className="mb-3">
                    <label className="brand-label">
                      Status<span className="required-mark">*</span>
                    </label>
                    <select {...register("status")} className="form-select" id="usCitizenSelect">
                      <option value="">Select...</option>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                    <p className="text-danger mt-2">{errors.status && <p>{errors.status.message}</p>}</p>
                  </div> */}
                  <div className="mb-3">
                    <label class="brand-label">
                      Product Allowed<span className="required-mark">*</span>
                    </label>
                    <div className="row">
                      <div className="col-md-12 d-flex">
                        {activeProducts?.map((products, i) => (
                          <div className={`form-check ${i !== 0 ? "ms-4" : ""}`}>
                            <input className="form-check-input" type="checkbox" id="propertyCheck" value={products.productId} {...register("ProductAllowed")} />
                            <label className="form-check-label text-capitalize" for="propertyCheck">
                              {products.productName
                                .replace("My", "")
                                .replace(/([A-Z])/g, " $1")
                                .trim()}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <p className="text-danger">{errors.ProductAllowed?.message}</p>
                  </div>
                </div>
              </div>
            </section>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button className="btn btn-outline-secondary me-md-2" type="button" data-bs-dismiss="offcanvas">
                Cancel
              </button>
              <button className="btn btn-primary" type="submit">
                <div className="button-body-for-loader">
                  <span>{addEditButtonText}</span>
                  {loader && <ThreeDots color="#ffdb58" height={30} width={40} />}
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Agent;
