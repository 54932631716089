import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { currentYear, floatRegex } from "../../Common/regex";
import useExitConfirmationHandler from "../../hooks/useExitConfirmationHandler.hook";

const validationSchema = yup.object().shape({
  roofDate: yup
    .string()
    .nullable(true)
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);
      return inputDate < currentDate;
    })
    .label("Roof date"),

  electricalDate: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Electrical date"),

  plumbingDate: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Plumbing date"),
  hvacDate: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Havc date"),
  claimDate: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .when("propertyIfClaim", (propertyIfClaim, schema) => {
      if (propertyIfClaim && propertyIfClaim.includes("yes")) {
        return schema.required("Claim date is required");
      } else return schema.notRequired();
    })
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Claim date"),
  propertyYearOfBuil: yup
    .string()
    .trim()
    .required("Year built is required")
    .matches(floatRegex, "Please enter a valid no. of years")
    .test(
      "is-past-or-current-year",
      "Year must be in the past or current year",
      function (value) {
        return value <= currentYear;
      }
    )
    .label("Year built"),
  propertyNoofBuilding: yup
    .string()
    .trim()
    .required("No. of buildings is required")
    .matches(floatRegex, "Please enter a valid no. of buildings")
    .label("No. of buildings"),

  propertyBpp: yup.string().trim().required("BPP is required").label("BPP"),
  propertySignLimit: yup
    .string()
    .trim()
    .required("Sign limit is required")
    .matches(floatRegex, "Please enter a valid sign limit")
    .label("Sign limit"),
  propertySqft: yup
    .string()
    .trim()
    .required("Sq. ft. is required")
    .matches(floatRegex, "Please enter a valid sq. ft.")
    .label("Sq. ft."),
  propertySignedValue: yup
    .string()
    .trim()
    .required("Signed value is required")
    .matches(floatRegex, "Please enter a valid signed value")
    .label("Signed value"),
  propertyProtactive: yup
    .array()
    .typeError("Protective safeguard is required")
    .min(1, "Protective safeguard is required")
    .of(yup.string())
    .required("Protective safeguard is required")
    .label("Protective safeguard"),
  protectiveGaurdOtherValue: yup
    .string()
    .test("isRequired", "Protective safeguard is required", function (value) {
      const propertyProtactive = this.parent.propertyProtactive;
      return propertyProtactive && propertyProtactive?.includes("others")
        ? !!value
        : true;
    })
    .label("Others type"),
  propertyAnyTable: yup
    .string()
    .required("Table system is required")
    .label("Table system"),
  propertyAnyBuffet: yup
    .string()
    .required("Buffet system is required")
    .label("Buffet system"),
  propertyAnyBar: yup
    .string()
    .required("Bar or dance floor is required")
    .label("Bar or dance floor"),
  propertyServiceContract: yup
    .string()
    .required("Service contract is required")
    .label("Service contract"),
  propertyCatering: yup
    .string()
    .required("Catering service is required")
    .label("Catering service"),
  propertySpoilageLimit: yup
    .string()
    .trim()
    .required("Spoilage limit is required")
    .matches(floatRegex, "Please enter valid spoilage limit")
    .label("Spoilage limit"),
  propertyFoodContainerLimit: yup
    .string()
    .trim()
    .required("Food contamination limit is required")
    .matches(floatRegex, "Please enter valid food contamination limit")
    .label("Food contamination limit"),
  propertyMoneyLimit: yup
    .string()
    .trim()
    .required("Money & securities limit is required")
    .matches(floatRegex, "Please enter valid money & securities limit")
    .label("Money & securities limit"),
  propertyVehicle: yup
    .string()
    .required("Vechicle service is required")
    .label("Vechicle service"),
  propertyPriorLoss: yup.string().nullable(true).label("Prior losses"),
  // propertyLossRun: yup
  //   .string()
  //   .trim()
  //   .nullable(true)
  //   .matches(floatRegex, "Please enter a valid no. of years")
  //   .test(
  //     "is-past-or-current-year",
  //     "Year must be in the past or current year",
  //     function (value) {
  //       return value <= currentYear;
  //     }
  //   )
  //   .label("Loss run"),

  propertyIfClaim: yup.string().required("Claims is required").label("Claims"),

  propertyAnyUpdate: yup
    .string()
    .when("propertyIfClaim", (propertyIfClaim, schema) => {
      if (propertyIfClaim && propertyIfClaim.includes("yes")) {
        return schema.required("Claim type is required");
      } else return schema.notRequired();
    })
    .label("Claim type"),
});

const Step2 = ({ stepHandler }) => {
  const  handleExitButton  = useExitConfirmationHandler();
  const query = new URLSearchParams(useLocation().search);
  const mode = query.get("mode");
  const restaurantId = query.get("primaryid");
  const isView = mode ? (mode === "view" ? true : false) : "";
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    reset,
    watch,
    control,
  } = useForm({
    defaultValues: {
      propertyYearOfBuil: null,
      propertyNoofBuilding: null,
      propertyBpp: "",
      propertySignLimit: null,
      propertySqft: null,
      propertySignedValue: null,
      propertyProtactive: [],
      propertyAnyTable: "",
      propertyAnyBuffet: "",
      propertyAnyBar: "",
      propertyServiceContract: "",
      propertyCatering: "",
      propertySpoilageLimit: null,
      propertyFoodContainerLimit: null,
      propertyMoneyLimit: null,
      propertyVehicle: "",
      propertyPriorLoss: null,
      roofDate: null,
      electricalDate: null,
      plumbingDate: null,
      hvacDate: null,
      claimDate: null,
      propertyIfClaim: "",
      // propertyLossRun: null,
      protectiveGaurdOtherValue: "",
      propertyAnyUpdate: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const claimsValue = watch("propertyIfClaim");
  const protectiveGaurdValue = watch("propertyProtactive");

  const fillupStep2HotelInfoFromLocalStorage = (formData) => {
    setValue("propertyYearOfBuil", formData.propertyYearOfBuil);
    setValue("propertyProtactive", formData.propertyProtactive);
    setValue("protectiveGaurdOtherValue", formData.protectiveGaurdOtherValue);
    setValue("propertyNoofBuilding", formData.propertyNoofBuilding);
    setValue("propertyBpp", formData.propertyBpp);
    setValue("propertySignLimit", formData.propertySignLimit);
    setValue("propertySqft", formData.propertySqft);
    setValue("propertySignedValue", formData.propertySignedValue);
    setValue("propertyAnyTable", formData.propertyAnyTable);
    setValue("propertyAnyBuffet", formData.propertyAnyBuffet);
    setValue("propertyAnyBar", formData.propertyAnyBar);
    setValue("propertyServiceContract", formData.propertyServiceContract);
    setValue("propertyCatering", formData.propertyCatering);
    setValue("propertySpoilageLimit", formData.propertySpoilageLimit);
    setValue("propertyFoodContainerLimit", formData.propertyFoodContainerLimit);
    setValue("propertyMoneyLimit", formData.propertyMoneyLimit);
    setValue("propertyVehicle", formData.propertyVehicle);
    setValue("propertyPriorLoss", formData.propertyPriorLoss);
    setValue("roofDate", formData.roofDate);
    setValue("electricalDate", formData.electricalDate);
    setValue("plumbingDate", formData.plumbingDate);
    setValue("hvacDate", formData.hvacDate);
    // setValue("propertyLossRun", formData.propertyLossRun);
    setValue("propertyIfClaim", formData.propertyIfClaim);
    setValue("claimDate", formData.claimDate);
    setValue("propertyAnyUpdate", formData.propertyAnyUpdate);
  };

  useEffect(() => {
    if (claimsValue?.includes("no")) {
      setValue("claimDate", null);
      setValue("propertyAnyUpdate", "");
    }
    if (!protectiveGaurdValue?.includes("others")) {
      setValue("protectiveGaurdOtherValue", "");
    }
  }, [claimsValue, protectiveGaurdValue]);

  const handleFormSubmit = async (data) => {
    if (data.roofDate?.trim().length === 0) {
      data.roofDate = null;
    }
    if (data.electricalDate?.trim().length === 0) {
      data.electricalDate = null;
    }
    if (data.plumbingDate?.trim().length === 0) {
      data.plumbingDate = null;
    }
    if (data.hvacDate?.trim().length === 0) {
      data.hvacDate = null;
    }
    stepHandler(3);
    setStep2ToLocalStorage(data);
  };

  const setStep2ToLocalStorage = (data) => {
    localStorage.setItem("step2", JSON.stringify(data));
  };

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("step2"));
    if (formData) {
      fillupStep2HotelInfoFromLocalStorage(formData);
    }
  }, []);

  const handlePrevious = (e) => {
    stepHandler(1);
  };

  return (
    <>
      <form
        className="tab-wizard wizard-circle wizard needs-validation"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <section>
          <h4 className="wrap-title mb-3">Building Information</h4>
          <div className="row">
            <div class="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label">
                  Year Built?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="propertyYearOfBuil"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      className="form-control"
                      name="propertyYearOfBuil"
                      onValueChange={(values) => {
                        setValue("propertyYearOfBuil", values.value);
                      }}
                      placeholder="Enter if any prior losses"
                      readOnly={isView}
                      {...register("propertyYearOfBuil")}
                    />
                  )}
                />

                <p className="text-danger">
                  {errors.propertyYearOfBuil?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label">
                  No. of Buildings?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="propertyNoofBuilding"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="propertyNoofBuilding"
                      className="form-control"
                      thousandSeparator={true}
                      name="propertyNoofBuilding"
                      onValueChange={(values) => {
                        setValue("propertyNoofBuilding", values.value);
                      }}
                      placeholder="Enter Number of Buildings"
                      {...register("propertyNoofBuilding")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.propertyNoofBuilding?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label">
                  BPP Limit?
                  <span className="required-mark">*</span>
                </label>
                <Controller
                  name="propertyBpp"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="propertyBpp"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="propertyBpp"
                      onValueChange={(values) => {
                        setValue("propertyBpp", values.floatValue);
                      }}
                      placeholder="Enter BPP"
                      readOnly={isView}
                      {...register("propertyBpp")}
                    />
                  )}
                />
                <p className="text-danger">{errors.propertyBpp?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label">Sign Limit?</label>
                <span className="required-mark">*</span>
                <Controller
                  name="propertySignLimit"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="propertySignLimit"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="propertySignLimit"
                      onValueChange={(values) => {
                        setValue("propertySignLimit", values.floatValue);
                      }}
                      placeholder="Enter Sign Limit"
                      readOnly={isView}
                      {...register("propertySignLimit")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.propertySignLimit?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label">
                  Sq. Ft.?<span className="required-mark">*</span>
                </label>

                <Controller
                  name="propertySqft"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="propertySqft"
                      className="form-control"
                      thousandSeparator={true}
                      name="propertySqft"
                      onValueChange={(values) => {
                        setValue("propertySqft", values.floatValue);
                      }}
                      placeholder="Enter Sq. Ft."
                      readOnly={isView}
                      {...register("propertySqft")}
                    />
                  )}
                />
                <p className="text-danger">{errors.propertySqft?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label">
                  Signed Value?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="propertySignedValue"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      id="propertySignedValue"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="propertySignedValue"
                      onValueChange={(values) => {
                        setValue("propertySignedValue", values.floatValue);
                      }}
                      placeholder="Enter Signed Value"
                      readOnly={isView}
                      {...register("propertySignedValue")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.propertySignedValue?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label d-grid">
                  <div>
                    Spoilage Limit?<span className="required-mark">*</span>
                  </div>
                </label>
                <Controller
                  name="propertySpoilageLimit"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="propertySpoilageLimit"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="propertySpoilageLimit"
                      onValueChange={(values) => {
                        setValue("propertySpoilageLimit", values.floatValue);
                      }}
                      placeholder="Enter Spoilage Limit"
                      readOnly={isView}
                      {...register("propertySpoilageLimit")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.propertySpoilageLimit?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label d-grid">
                  <div>
                    Food Contamination Limit?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <Controller
                  name="propertyFoodContainerLimit"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="propertyFoodContainerLimit"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="propertyFoodContainerLimit"
                      onValueChange={(values) => {
                        setValue(
                          "propertyFoodContainerLimit",
                          values.floatValue
                        );
                      }}
                      placeholder="Enter Food Contamination Limit"
                      readOnly={isView}
                      {...register("propertyFoodContainerLimit")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.propertyFoodContainerLimit?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Last Roof Updated?</label>
                <input
                  id="roofDate"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Last Roof Update"
                  {...register("roofDate")}
                />
                <p className="text-danger">
                  {errors.roofDate && <p>{errors.roofDate.message}</p>}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Last Wiring Updated?</label>
                <input
                  id="electricalDate"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Last Wiring Update"
                  {...register("electricalDate")}
                />
                <p className="text-danger">
                  {errors.electricalDate && (
                    <p>{errors.electricalDate.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Last Plumbing Updated?</label>
                <input
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Last Plumbing Update"
                  id="plumbingDate"
                  {...register("plumbingDate")}
                />
                <p className="text-danger">
                  {errors.plumbingDate && <p>{errors.plumbingDate.message}</p>}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Last Heating Updated?</label>
                <input
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Last Heating Update"
                  id="hvacDate"
                  name="hvacDate"
                  {...register("hvacDate")}
                />
                <p className="text-danger">
                  {errors.hvacDate && <p>{errors.hvacDate.message}</p>}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label d-grid">
                  <div>
                    Money & Securities Limit?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <Controller
                  name="propertyMoneyLimit"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="propertyMoneyLimit"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="propertyMoneyLimit"
                      onValueChange={(values) => {
                        setValue("propertyMoneyLimit", values.floatValue);
                      }}
                      placeholder="Enter Money & Securities Limit"
                      readOnly={isView}
                      {...register("propertyMoneyLimit")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.propertyMoneyLimit?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label d-grid">Prior Losses</label>
                <Controller
                  name="propertyPriorLoss"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="propertyPriorLoss"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="propertyPriorLoss"
                      onValueChange={(values) => {
                        setValue("propertyPriorLoss", values.floatValue);
                      }}
                      placeholder="Enter if any prior losses"
                      readOnly={isView}
                      {...register("propertyPriorLoss")}
                    />
                  )}
                />
              </div>
              <p className="text-danger">{errors.propertyPriorLoss?.message}</p>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label d-grid">
                  <div>
                    Bar or Dance Floor?<span className="required-mark">*</span>
                  </div>
                </label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="yesBar"
                    value="1"
                    disabled={isView}
                    {...register("propertyAnyBar")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="yesBar"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="noBar"
                    value="0"
                    disabled={isView}
                    {...register("propertyAnyBar")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="noBar"
                  >
                    No
                  </label>
                </div>
                <p className="text-danger">{errors.propertyAnyBar?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label d-grid">
                  <div>
                    Table System?<span className="required-mark">*</span>
                  </div>
                </label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="tableYes"
                    value="1"
                    disabled={isView}
                    {...register("propertyAnyTable")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="tableYes"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="tableNo"
                    value="0"
                    disabled={isView}
                    {...register("propertyAnyTable")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="tableNo"
                  >
                    No
                  </label>
                </div>
                <p className="text-danger">
                  {errors.propertyAnyTable?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label d-grid">
                  <div>
                    Buffet System?<span className="required-mark">*</span>
                  </div>
                </label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="propertyAnyBuffetYes"
                    value="1"
                    disabled={isView}
                    {...register("propertyAnyBuffet")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="propertyAnyBuffetYes"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="propertyAnyBuffetNo"
                    value="0"
                    disabled={isView}
                    {...register("propertyAnyBuffet")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="propertyAnyBuffetNo"
                  >
                    No
                  </label>
                </div>
                <p className="text-danger">
                  {errors.propertyAnyBuffet?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label d-grid">
                  <div>
                    Catering Service?<span className="required-mark">*</span>
                  </div>
                </label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="cateringYes"
                    value="1"
                    disabled={isView}
                    {...register("propertyCatering")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="cateringYes"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="cateringNo"
                    value="0"
                    disabled={isView}
                    {...register("propertyCatering")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="cateringNo"
                  >
                    No
                  </label>
                </div>
                <p className="text-danger">
                  {errors.propertyCatering?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label className="brand-label">
                  <div>
                    For Catering Serivce, Employee Use Vechicle?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="ownVehicle"
                      value="1"
                      disabled={isView}
                      {...register("propertyVehicle")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="ownVehicle"
                    >
                      Own
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="companyVehicle"
                      value="0"
                      disabled={isView}
                      {...register("propertyVehicle")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="companyVehicle"
                    >
                      Company
                    </label>
                  </div>
                </div>
                <p className="text-danger">{errors.propertyVehicle?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label className="brand-label d-grid">
                  <div>
                    Service Contract for Cleaning of Kitchen Equipment &
                    Refrigeration<span className="required-mark">*</span>
                  </div>
                </label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="yesContract"
                    value="1"
                    disabled={isView}
                    {...register("propertyServiceContract")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="yesContract"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="noContract"
                    value="0"
                    disabled={isView}
                    {...register("propertyServiceContract")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="noContract"
                  >
                    No
                  </label>
                </div>
                <p className="text-danger">
                  {errors.propertyServiceContract?.message}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                    {" "}
                    Any Claims in past 5 years?
                    <span className="required-mark">*</span>
                  </div>
                </label>

                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="claimYes"
                    value="yes"
                    onSelect={(e) => {
                      console.log("yesbutton", e);
                    }}
                    {...register("propertyIfClaim")}
                  />
                  <label class="form-check-label" for="claimYes">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="claimNo"
                    value="no"
                    {...register("propertyIfClaim")}
                  />
                  <label class="form-check-label" for="claimNo">
                    No
                  </label>
                </div>
                {/* {watch("propertyIfClaim")?.includes("yes") && (
                  <p className="text-danger">Loss runs are required</p>
                )} */}
                <p className="text-danger">
                  {errors.propertyIfClaim && (
                    <p>{errors.propertyIfClaim.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>Claim Type?</div>
                </label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="propertyCheck"
                    value="Property"
                    disabled={!claimsValue.includes("yes")}
                    {...register("propertyAnyUpdate")}
                  />
                  <label class="form-check-label" for="propertyCheck">
                    Property
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="GLICheck"
                    value="GL"
                    disabled={!claimsValue.includes("yes")}
                    {...register("propertyAnyUpdate")}
                  />
                  <label class="form-check-label" for="GLICheck">
                    GL
                  </label>
                </div>

                <p className="text-danger">
                  {errors.propertyAnyUpdate && (
                    <p>{errors.propertyAnyUpdate.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">Claim Date?</label>
                <input
                  id="claimDate"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Claim Date"
                  disabled={!claimsValue?.includes("yes")}
                  {...register("claimDate")}
                />
                <p className="text-danger">
                  {errors.claimDate && <p>{errors.claimDate.message}</p>}
                </p>
              </div>
            </div>
            {/* <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label d-grid">Loss Runs (Years)</label>
                <Controller
                  name="propertyLossRun"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      className="form-control"
                      name="propertyLossRun"
                      onValueChange={(values) => {
                        setValue("propertyLossRun", values.floatValue);
                      }}
                      placeholder="Enter if any prior losses"
                      readOnly={isView}
                      {...register("propertyLossRun")}
                    />
                  )}
                />
              </div>
              <p className="text-danger">{errors.propertyLossRun?.message}</p>
            </div> */}

            <div className="col-md-12 col-lg-8">
              <div className="mb-2">
                <label className="brand-label">Protective Safeguard?</label>
                <span className="required-mark">*</span>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="cctvCheck"
                        value="CCTV Camera"
                        disabled={isView}
                        {...register("propertyProtactive")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="cctvCheck"
                      >
                        CCTV Camera
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="fireAlarmCheck"
                        value="Fire Alarm"
                        disabled={isView}
                        {...register("propertyProtactive")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="fireAlarmCheck"
                      >
                        Fire Alarm
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="burglarAlarmCheck"
                        value="Burglar Alarm"
                        disabled={isView}
                        {...register("propertyProtactive")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="burglarAlarmCheck"
                      >
                        Burglar Alarm
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="centralStationCheck"
                        value="Central Station"
                        disabled={isView}
                        {...register("propertyProtactive")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="centralStationCheck"
                      >
                        Central Station
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="smokeDetectionCheck"
                        value="Smoke Detector"
                        disabled={isView}
                        {...register("propertyProtactive")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="smokeDetectionCheck"
                      >
                        Smoke Detector
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="sprinklerCheck"
                        value="Sprinkler System "
                        disabled={isView}
                        {...register("propertyProtactive")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="sprinklerCheck"
                      >
                        Sprinkler System
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="othersCheck"
                        value="others"
                        disabled={isView}
                        {...register("propertyProtactive")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="othersCheck"
                      >
                        Others
                      </label>
                    </div>
                    <div>
                      {watch("propertyProtactive")?.includes("others") && (
                        <>
                          <input
                            className="form-control"
                            type="text"
                            id="protectiveGaurdOtherValue"
                            name="protectiveGaurdOtherValue"
                            placeholder="Enter Protective Safeguard"
                            {...register("protectiveGaurdOtherValue")}
                          />

                          <p className="text-danger">
                            {errors?.protectiveGaurdOtherValue?.message}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <p className="text-danger">
                  {errors.propertyProtactive?.message}
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="gap-4">
          <div className="gap-4 text-end">
          {restaurantId && (
                <button
                  type="button"
                  onClick={()=>handleExitButton('Restaurant Insurance Application')}
                  className="btn btn-danger ms-2 text-capitalize"
                >
                  Exit if no changes required
                </button>
              )}
            <button
              className="btn btn-warning ms-2"
              type="button"
              onClick={handlePrevious}
            >
              Previous
            </button>
            <button className="btn btn-primary ms-2">Next</button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Step2;
