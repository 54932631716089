import dayjs from "dayjs";
import React from "react";
const DependentForm = (dependentInfo) => {
  return (
    <>
      <section>
        <h4 className="wrap-title mb-3">Dependent Details</h4>
        <div className="brand-table">
          <div className="table-responsive table-container">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Persons Name</th>
                  <th scope="col">Date of Birth</th>
                  <th scope="col">Gender</th>
                  <th scope="col">Immigration</th>
                  <th scope="col">Relation</th>
                  <th scope="col">Tobacco</th>
                </tr>
              </thead>
              <tbody>
                {dependentInfo.dependentInfo.map((dep) => {
                  const list = (
                    <>
                      <tr>
                        <th scope="row"></th>
                        <td>
                          <b>{dep.dependentName}</b>
                        </td>
                        <td>
                          <b>
                            {dayjs(dep.dependentBirthday).format("MM/DD/YYYY")}
                          </b>
                        </td>
                        <td>
                          <b>{dep.dependentGender}</b>
                        </td>
                        <td>
                          <div className="mb-2">
                            <b>{dep.dependentCitizenshipSelect}</b>
                          </div>
                        </td>
                        <td>
                          <b>{dep.documentTypeSelect}</b>
                        </td>
                        <td>
                          <b>{dep.tobaccoSelect}</b>
                        </td>
                      </tr>
                    </>
                  );
                  return list;
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};

export default DependentForm;
