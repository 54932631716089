import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import React, { useState } from "react";

const placesLibrary = ["places"];

function PlaceComponent({
  getLocationAddress,
  value = "",
  type = "",
  name,
  control,
  readOnly = false,
  onChange
}) {
  const [searchResult, setSearchResult] = useState("");

  const API_KEY = process.env.REACT_APP_GOOGLE_KEY;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: placesLibrary,
  });
  function onLoad(autocomplete) {
    console.log('autocomplete',autocomplete)
    setSearchResult(autocomplete);
  }

  const onPlaceChanged = () => {
    if (searchResult != null) {
      //variable to store the result
      const place = searchResult?.getPlace();
      let addr = place.formatted_address ?? "";
      addr = addr.split(",")[0];
      document.getElementById("location-search-input").blur();
      setTimeout(() => {
        if (type !== "profile-update") {
          document.getElementById("location-search-input").value =
            place.name ?? addr;
        }
      }, 20);
      console.log('place',place)
      getLocationAddress(place);
    }
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
      <>
        {readOnly && <input
            type="text"
            id="location-search-input"
            placeholder="Address"
            className="form-control"
            readOnly={readOnly}
            autoComplete="off"
            value={value}
        />}
        {!readOnly &&
        <Autocomplete freesolo onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
        <input
            type="text"
            name={name}
            control={control}
            id="location-search-input"
            placeholder="Address"
            className="form-control"
            readOnly={readOnly}
            autoComplete="off"
            defaultValue={value}
            onChange={onChange}
        />
      </Autocomplete> }
      </>

  );
}

export default PlaceComponent;
