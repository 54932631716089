import React from "react";
import Master from "./Master";

const VaultEditHotelForm = ({ details }) => {
  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="salon-Edit"
        style={{ width: "80%", zIndex: 5 }}
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header activity-status-table brand-offcanvas-header">
          <h5 id="offcanvasRightLabel">Hotel Commercial Application Update</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <Master details={details} />
        </div>
      </div>
    </>
  );
};

export default VaultEditHotelForm;
