import React, { useState, useEffect } from "react";
import "./../../../portal-assets/styles.css";
import "./../../../portal-assets/normalize.css";
import LeftBar from "./../LeftBar";
import { ToastContainer } from "react-toastify";
import Footer from "../../ancfooter";

const Layout = ({ children }) => {
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <LeftBar active={children.type.displayName} />
          {React.cloneElement(children, {})}
        </div>
        <div
          style={{
            paddingLeft: "350px",
          }}
        >
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Layout;
