import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { formattedDate } from "../../Common/Utils";
import { emailRegex, nameRegex } from "../../Common/regex";
import { default as axios } from "../../config/axios-config";
import PlaceComponent from "../healthcare/GooglePlace";
import useExitConfirmationHandler from "../../hooks/useExitConfirmationHandler.hook";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/Hotel";
const DEVURL_VERIFY = process.env.REACT_APP_ANC_API + "/api/Verify";

const validationSchema = yup.object().shape({
  firstName: yup.string().trim().required("First name is required").matches(nameRegex, "Please enter a valid name").label("First name"),
  lastName: yup.string().trim().required("Last name is required").matches(nameRegex, "Please enter a valid name").label("Last name"),
  businessName: yup.string().trim().required("Business name is required").label("Business name"),
  dbaname: yup.string().nullable(true).matches(nameRegex, "Please enter a valid dba").label("DBA"),

  locationAddress: yup.string().trim().required("Location address is required").label("Location address"),
  mailingAddress: yup.string().trim().required("Mailing address is required").label("Mailing address"),
  mobileNum: yup
    .string()
    .trim()
    .required("Mobile number is required")
    .matches(/^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/, "Please enter valid mobile number")
    .label("Mobile number"),
  workNum: yup
    .string()
    .nullable(null)
    .matches(/^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[0-9]{3}[\s\-]?[0-9]{4}$|^$/, "Please enter valid work number")
    .label("Work number"),

  email: yup.string().trim().required("Email is required").matches(emailRegex, "Enter a valid email").label("Email address"),
  coverage: yup.array().typeError("Coverage is required").min(1, "Coverage is required").of(yup.string()).required("Coverage is required").label("Coverage"),
  effectiveDate: yup
    .string()
    .trim()
    .required("Effective date is required")
    .test("is-future-date", "Date must be today or future date", (value) => {
      if (!value) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate >= currentDate;
    })
    .label("Effective date"),
});

const Step1 = ({ stepHandler, loader, agencyId, agentId }) => {
  const navigate = useNavigate();
  const handleExitButton = useExitConfirmationHandler();
  const query = new URLSearchParams(useLocation().search);
  const verifyId = query.get("verifyid");
  const id = query.get("primaryid");
  const mode = query.get("mode");
  const isView = mode ? (mode === "view" ? true : false) : "";
  const [sameAsAddress, setSameAsAddress] = useState(false);
  const [garageAddress, setGarageAddress] = useState("");
  const queryParams = new URLSearchParams(window.location.search);
  const restaurantId = query.get("primaryid");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    control,
    watch,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      businessName: "",
      dbaname: "",
      locationAddress: "",
      locationCity: "",
      locationState: "",
      locationZip: "",
      mailingAddress: "",
      mailingCity: "",
      mailingState: "",
      mailingZip: "",
      workNum: "",
      mobileNum: "",
      email: "",
      coverage: [],
      effectiveDate: null,
      agencyId: agencyId,
      agentId: agentId,
      customerId: "",
      restaurantId: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const [isLoading, setLoading] = useState(false);
  const [addesss, setAddress] = useState("");
  const mobileNum = watch("mobileNum");
  const workNum = watch("workNum");
  const locationCity = watch("locationCity");
  const locationState = watch("locationState");
  const locationZip = watch("locationZip");

  const getLocation = async (data) => {
    let address_components = data.address_components;
    let formatted_address = data.formatted_address;
    setValue("locationAddress", formatted_address);
    setAddress(formatted_address);
    formatted_address = formatted_address.split(",");
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        zip_code = data.long_name + zip_code;
      }
      // if (data.types.includes("postal_code_suffix")) {
      //     zip_code =  zip_code +'-'+ data.long_name;
      // }
      if (data.types.includes("locality")) {
        setValue("locationCity", data.long_name);
      }
      if (data.types.includes("administrative_area_level_1")) {
        setValue("locationState", data.long_name);
      }
    });
    setValue("locationZip", zip_code);
  };

  const getGarageAddress = async (data) => {
    console.log("garageAddress data", data);
    let address_components = data?.address_components;
    let formatted_address = data?.formatted_address;
    setValue("mailingAddress", formatted_address);
    formatted_address = formatted_address.split(",");
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        zip_code = data.long_name + zip_code;
      }
      // if (data.types.includes("postal_code_suffix")) {
      //     zip_code =  zip_code +'-'+ data.long_name;
      // }
      if (data.types.includes("locality")) {
        setValue("mailingCity", data.long_name);
      }
      if (data.types.includes("administrative_area_level_1")) {
        setValue("mailingState", data.long_name);
      }
    });
    setValue("mailingZip", zip_code);
    return false;
  };

  const fillupStep1HotelInfo = (restaurant) => {
    if (restaurant) {
      setValue("agencyId", agencyId);
      setValue("customerId", restaurant?.customerInfo?.customerId);
      setValue("firstName", restaurant.customerInfo.firstName);
      setValue("lastName", restaurant.customerInfo.lastName);
      setValue("businessName", restaurant.customerInfo.businessName);
      setValue("dbaname", restaurant.customerInfo.dbaname);
      setValue("locationAddress", restaurant.customerInfo.locationAddress);
      setValue("locationCity", restaurant.customerInfo.locationCity);
      setValue("locationState", restaurant.customerInfo.locationState);
      setValue("locationZip", restaurant.customerInfo.locationZip);
      setValue("mailingAddress", restaurant.customerInfo.mailingAddress);
      setValue("mailingCity", restaurant.customerInfo.mailingCity);
      setValue("mailingState", restaurant.customerInfo.mailingState);
      setValue("mailingZip", restaurant.customerInfo.mailingZip);
      setValue("workNum", restaurant.customerInfo.workNum);
      setValue("mobileNum", restaurant.customerInfo.mobileNum);
      setValue("email", restaurant.customerInfo.email);
      setValue("coverage", restaurant.hotelInfo?.coverage);

      setValue("effectiveDate", formattedDate(restaurant?.hotelInfo?.effectiveDate));
      setAddress(restaurant.customerInfo.locationAddress);
      setGarageAddress(restaurant.customerInfo.mailingAddress);
      if (restaurant.customerInfo.mailingAddress === restaurant.customerInfo.locationAddress) {
        setSameAsAddress(true);
      }
    }
  };

  const getStep1HotelInfo = (restaurant) => {
    const hotelInfoOb = {};
    hotelInfoOb.customerId = restaurant.customerInfo.customerId;
    hotelInfoOb.firstName = restaurant.customerInfo.firstName;
    hotelInfoOb.lastName = restaurant.customerInfo.lastName;
    hotelInfoOb.businessName = restaurant.customerInfo.businessName;
    hotelInfoOb.dbaname = restaurant.customerInfo.dbaname;
    hotelInfoOb.locationAddress = restaurant.customerInfo.locationAddress;
    hotelInfoOb.locationCity = restaurant.customerInfo.locationCity;
    hotelInfoOb.locationState = restaurant.customerInfo.locationState;
    hotelInfoOb.locationZip = restaurant.customerInfo.locationZip;
    hotelInfoOb.mailingAddress = restaurant.customerInfo.mailingAddress;
    hotelInfoOb.mailingCity = restaurant.customerInfo.mailingCity;
    hotelInfoOb.mailingState = restaurant.customerInfo.mailingState;
    hotelInfoOb.mailingZip = restaurant.customerInfo.mailingZip;
    hotelInfoOb.workNum = restaurant.customerInfo.workNum;
    hotelInfoOb.mobileNum = restaurant.customerInfo.mobileNum;
    hotelInfoOb.email = restaurant.customerInfo.email;
    hotelInfoOb.coverage = restaurant.hotelInfo.coverage;
    hotelInfoOb.effectiveDate = restaurant.hotelInfo.effectiveDate;

    return hotelInfoOb;
  };

  const getStep2HotelInfo = (restaurant) => {
    const hotelInfoOb = {};
    setValue("hotelId", restaurant.hotelInfo.hotelId);
    hotelInfoOb.hotelId = restaurant.hotelInfo.hotelId;
    hotelInfoOb.yearBuilt = restaurant.hotelInfo.yearBuilt;
    hotelInfoOb.noOfBuilding = restaurant.hotelInfo.noOfBuilding;
    hotelInfoOb.inventoryLimit = restaurant.hotelInfo.inventoryLimit;
    hotelInfoOb.noOfStoriesEachBuilding = restaurant.hotelInfo.noOfStoriesEachBuilding;
    hotelInfoOb.eachBuilding = restaurant.hotelInfo.eachBuilding;
    hotelInfoOb.signValue = restaurant.hotelInfo.signValue;
    hotelInfoOb.isRegisteredFranchise = restaurant.hotelInfo.isRegisteredFranchise;
    hotelInfoOb.isBuildingsStairs = restaurant.hotelInfo.isBuildingsStairs;
    hotelInfoOb.isElevators = restaurant.hotelInfo.isElevators;
    hotelInfoOb.isUpdateConstruction = restaurant.hotelInfo.isUpdateConstruction;
    hotelInfoOb.isBanquet = restaurant.hotelInfo.isBanquet;
    hotelInfoOb.banquetSqFt = restaurant.hotelInfo.banquetSqFt;
    hotelInfoOb.banquetCapacity = restaurant.hotelInfo.banquetCapacity;
    hotelInfoOb.isGym = restaurant.hotelInfo.isGym;
    hotelInfoOb.isGymMaintenanceAvailable = restaurant.hotelInfo.isGymMaintenanceAvailable;
    hotelInfoOb.gymSqft = restaurant.hotelInfo.gymSqft;
    hotelInfoOb.gymHour = restaurant.hotelInfo.gymHour;
    hotelInfoOb.isSwimmingPool = restaurant.hotelInfo.isSwimmingPool;
    hotelInfoOb.isSwimmingPoolLocation = restaurant.hotelInfo.isSwimmingPoolLocation;
    hotelInfoOb.ageGroup = restaurant.hotelInfo.ageGroup;
    hotelInfoOb.totalArea = restaurant.hotelInfo.totalArea;

    hotelInfoOb.isSwimmingWellFenced = restaurant.hotelInfo.isSwimmingWellFenced;
    hotelInfoOb.isSwimmingLifeGuard = restaurant.hotelInfo.isSwimmingLifeGuard;
    hotelInfoOb.isSwimmingDoorShutOff = restaurant.hotelInfo.isSwimmingDoorShutOff;
    hotelInfoOb.isSwimmingDivingBoard = restaurant.hotelInfo.isSwimmingDivingBoard;
    hotelInfoOb.isSwimmingHotTub = restaurant.hotelInfo.isSwimmingHotTub;
    hotelInfoOb.isSwimmingHotTubLocation = restaurant.hotelInfo.isSwimmingHotTubLocation;
    hotelInfoOb.swimmingPoolDepth = restaurant.hotelInfo.swimmingPoolDepth;
    hotelInfoOb.swimmingNoOfPools = restaurant.hotelInfo.swimmingNoOfPools;
    hotelInfoOb.swimmingNoOfHotTubs = restaurant.hotelInfo.swimmingNoOfHotTubs;
    hotelInfoOb.isBarDanceFloor = restaurant.hotelInfo.isBarDanceFloor;
    hotelInfoOb.isHappyHours = restaurant.hotelInfo.isHappyHours;
    hotelInfoOb.barDanceHours = restaurant.hotelInfo.barDanceHours;
    hotelInfoOb.isRestaurant = restaurant.hotelInfo.isRestaurant;
    hotelInfoOb.seatingCapacity = restaurant.hotelInfo.seatingCapacity;
    hotelInfoOb.restaurantHours = restaurant.hotelInfo.restaurantHours;
    hotelInfoOb.noOfEmergencyExits = restaurant.hotelInfo.noOfEmergencyExits;
    hotelInfoOb.noOfManagersPerShift = restaurant.hotelInfo.noOfManagersPerShift;
    hotelInfoOb.crimeCoverageLimit = restaurant.hotelInfo.crimeCoverageLimit;
    hotelInfoOb.managersCheckinCheckout = restaurant.hotelInfo.managersCheckinCheckout;
    hotelInfoOb.isShuttleService = restaurant.hotelInfo.isShuttleService;
    hotelInfoOb.isRoomRentOutBasis = restaurant.hotelInfo.isRoomRentOutBasis;

    hotelInfoOb.lastRoofUpdated = restaurant.hotelInfo.lastRoofUpdated === null ? null : formattedDate(restaurant.hotelInfo.lastRoofUpdated);
    hotelInfoOb.lastWiringUpdated = restaurant.hotelInfo.lastWiringUpdated === null ? null : formattedDate(restaurant.hotelInfo.lastWiringUpdated);
    hotelInfoOb.lastPlumbingUpdated = restaurant.hotelInfo.lastPlumbingUpdated === null ? null : formattedDate(restaurant.hotelInfo.lastPlumbingUpdated);

    hotelInfoOb.lastHeatingUpdated = restaurant.hotelInfo.lastHeatingUpdated === null ? null : formattedDate(restaurant.hotelInfo.lastHeatingUpdated);

    hotelInfoOb.isRoomWithKitchen = restaurant.hotelInfo.isRoomWithKitchen;
    hotelInfoOb.isPlayGround = restaurant.hotelInfo.isPlayGround;
    hotelInfoOb.isEntertainmentRoom = restaurant.hotelInfo.isEntertainmentRoom;
    hotelInfoOb.isCyberLiabilityCoverage = restaurant.hotelInfo.isCyberLiabilityCoverage;
    hotelInfoOb.isSubsidizedRate = restaurant.hotelInfo.isSubsidizedRate;
    hotelInfoOb.howManySubRooms = restaurant.hotelInfo.howManySubRooms;
    hotelInfoOb.averageOccupancyRate = restaurant.hotelInfo.averageOccupancyRate;
    hotelInfoOb.constructionType = restaurant.hotelInfo.constructionType;
    hotelInfoOb.protectiveSafeguard = restaurant.hotelInfo.protectiveSafeguard;

    hotelInfoOb.claimDate = formattedDate(restaurant.hotelInfo.claimDate);
    hotelInfoOb.restaurantHours = restaurant.hotelInfo.restaurantHours;
    hotelInfoOb.propertyIfClaim = restaurant.hotelInfo.propertyIfClaim;

    return hotelInfoOb;
  };

  const getStep3HotelInfo = (restaurant) => {
    const hotelInfoOb = {};
    hotelInfoOb.agentId = restaurant.customerInfo.agentId;
    hotelInfoOb.roomRatesPerDay = restaurant.hotelInfo.roomRatesPerDay;
    hotelInfoOb.liquorSales = restaurant.hotelInfo.liquorSales;
    hotelInfoOb.restaurantSales = restaurant.hotelInfo.restaurantSales;
    hotelInfoOb.annualGrossSales = restaurant.hotelInfo.annualGrossSales;
    hotelInfoOb.totalPayroll = restaurant.hotelInfo.totalPayroll;
    hotelInfoOb.feinNumer = restaurant.hotelInfo.feinNumer;
    hotelInfoOb.noOfEmployees = restaurant.hotelInfo.noOfEmployees;
    hotelInfoOb.bankDetails = restaurant.hotelInfo.bankDetails;
    hotelInfoOb.isAnyClaims = restaurant.hotelInfo.isAnyClaims;
    hotelInfoOb.priorLosses = restaurant.hotelInfo.priorLosses;
    hotelInfoOb.workersCompLimit = restaurant.hotelInfo.workersCompLimit;
    hotelInfoOb.isReturnPolicyEmployeeAccident = restaurant.hotelInfo.isReturnPolicyEmployeeAccident;

    hotelInfoOb.isOwnerIncludedExcludedPayroll = restaurant.hotelInfo.isOwnerIncludedExcludedPayroll;
    hotelInfoOb.isPreviousWorkersCompClaim = restaurant.hotelInfo.isPreviousWorkersCompClaim;

    hotelInfoOb.noOfNonClericalStaffs = restaurant.hotelInfo.noOfNonClericalStaffs;
    hotelInfoOb.noOfClericalStaffs = restaurant.hotelInfo.noOfClericalStaffs;

    hotelInfoOb.noOfFullTimer = restaurant.hotelInfo.noOfFullTimer;

    hotelInfoOb.noOfPartTimer = restaurant.hotelInfo.noOfPartTimer;

    hotelInfoOb.umbrellaLimit = restaurant.hotelInfo.umbrellaLimit;
    hotelInfoOb.bankPropertyValue = restaurant.hotelInfo.bankPropertyValue;

    hotelInfoOb.isClaimType = restaurant.hotelInfo.isClaimType;

    hotelInfoOb.claimDate = restaurant.hotelInfo.claimDate;
    hotelInfoOb.lossRunYears = restaurant.hotelInfo.lossRunYears;

    return hotelInfoOb;
  };

  const fillupStep1HotelInfoFromLocalStorage = async (formData) => {
    if (formData) {
      setValue("firstName", formData.firstName);
      setValue("lastName", formData.lastName);
      setValue("businessName", formData.businessName);
      setValue("dbaname", formData.dbaname);
      setValue("locationAddress", formData.locationAddress);
      setValue("locationCity", formData.locationCity);
      setValue("locationState", formData.locationState);
      setValue("locationZip", formData.locationZip);
      setValue("mailingAddress", formData.mailingAddress);
      setValue("mailingCity", formData.mailingCity);
      setValue("mailingState", formData.mailingState);
      setValue("mailingZip", formData.mailingZip);
      setValue("workNum", formData.workNum);
      setValue("mobileNum", formData.mobileNum);
      setValue("email", formData.email);
      setValue("coverage", formData?.coverage);
      setValue("effectiveDate", formattedDate(formData.effectiveDate));

      setAddress(formData.locationAddress);
      setGarageAddress(formData.mailingAddress);
      if (formData.mailingAddress === formData.locationAddress) {
        setSameAsAddress(true);
      }
    }
  };

  const setHotelInfoInLocalStorage = (data) => {
    localStorage.setItem("step1", JSON.stringify(getStep1HotelInfo(data)));
    localStorage.setItem("step2", JSON.stringify(getStep2HotelInfo(data)));
    localStorage.setItem("step3", JSON.stringify(getStep3HotelInfo(data)));
    localStorage.setItem("customerId", data?.customerInfo?.customerId);
  };

  const formatTelNumber = (input) => {
    const cleaned = ("" + input).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    } else {
      return cleaned;
    }
  };

  const isPasscodeActive = (verifyId) => {
    setLoading(true);
    axios
      .get(`${DEVURL_VERIFY}/IsActivePasscode/${verifyId}`)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          if (response.data !== 1) {
            navigate(`/verification?verify=${verifyId}`);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.status === 404) {
          navigate(`/verification?verify=${verifyId}`);
        }
      });
  };

  const loadInitialGasStationInfo = () => {
    const formData = JSON.parse(localStorage.getItem("step1"));
    if (!formData) {
      setLoading(true);
      axios
        .get(`${DEVURL}/GetHotelInfoById/${id}`)
        .then((response) => {
          setLoading(false);
          fillupStep1HotelInfo(response.data);
          setHotelInfoInLocalStorage(response.data);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else if (formData) {
      fillupStep1HotelInfoFromLocalStorage(formData);
    }
  };

  useEffect(() => {
    if (verifyId) {
      isPasscodeActive(verifyId);
    }
    loadInitialGasStationInfo();
  }, []);

  const handleFormSubmit = (data) => {
    localStorage.setItem("step1", JSON.stringify(data));
    stepHandler(2);
  };

  if (isLoading) {
    return loader();
  }

  return (
    <>
      <form className="tab-wizard wizard-circle wizard needs-validation" onSubmit={handleSubmit(handleFormSubmit)}>
        <section>
          <h4 className="wrap-title mb-3">General Information</h4>
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">First Name</label>
                <span className="required-mark">*</span>
                <input type="text" className="form-control" placeholder="Enter First Name" readOnly={isView} {...register("firstName")} />
                <p className="text-danger">{errors.firstName && <p>{errors.firstName.message}</p>}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Last Name</label>
                <span className="required-mark">*</span>
                <input type="text" className="form-control" placeholder="Enter Last Name" readOnly={isView} {...register("lastName")} />
                <p className="text-danger">{errors.lastName && <p>{errors.lastName.message}</p>}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Business Name</label>
                <span className="required-mark">*</span>
                <input type="text" className="form-control" placeholder="Enter Business Name" readOnly={isView} {...register("businessName")} />
                <p className="text-danger">{errors.businessName && <p>{errors.businessName.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">DBA</label>
                <input type="text" className="form-control" placeholder="Enter DBA" readOnly={isView} {...register("dbaname")} />
              </div>
              <p className="text-danger">{errors.dbaname && <p>{errors.dbaname.message}</p>}</p>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label">
                  Work Number
                  {/* <span className="required-mark">*</span> */}
                </label>
                <Controller
                  name="mobileNum"
                  control={control}
                  render={({ field }) => (
                    <input {...field} type="text" class="form-control" placeholder="(XXX) XXX-XXXX" value={formatTelNumber(workNum)} {...register("workNum")} />
                  )}
                />
                <p className="text-danger">{errors.workNum?.message}</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label">
                  Mobile Number<span className="required-mark">*</span>
                </label>
                <Controller
                  name="mobileNum"
                  control={control}
                  render={({ field }) => (
                    <input {...field} type="text" class="form-control" placeholder="(XXX) XXX-XXXX" value={formatTelNumber(mobileNum)} {...register("mobileNum")} />
                  )}
                />

                <p className="text-danger">{errors.mobileNum?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Email Address</label>
                <span className="required-mark">*</span>
                <input type="email" className="form-control" placeholder="Enter Email Address" readOnly={isView} {...register("email")} />
                <p className="text-danger">{errors.email && <p>{errors.email.message}</p>}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Effective Date</label>
                <span className="required-mark">*</span>
                <input type="date" max="2099-12-31" className="form-control" placeholder="Enter Effective Date" readOnly={isView} {...register("effectiveDate")} />
                <p className="text-danger">{errors.effectiveDate && <p>{errors.effectiveDate.message}</p>}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="mb-3">
                <label className="brand-label">
                  Location Address<span className="required-mark">*</span>
                </label>
                <PlaceComponent
                  getLocationAddress={getLocation}
                  value={addesss}
                  onChange={(e) => {
                    setAddress(e.target.value);
                    setValue("locationAddress", e.target.value);
                    setValue("locationCity", null);
                    setValue("locationState", null);
                    setValue("locationZip", null);
                  }}
                  type="profile-update"
                  name="locationAddress"
                  control={control}
                ></PlaceComponent>
                <p className="text-danger">{errors.locationAddress && <p>{errors.locationAddress?.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mb-3">
                <label className="brand-label" style={{ display: "flex" }}>
                  Mailing Address<span className="required-mark">*</span>&nbsp;
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={sameAsAddress}
                      onChange={(e) => {
                        setSameAsAddress(e.target.checked);
                        setValue("mailingAddress", addesss);
                        setValue("mailingCity", locationCity);
                        setValue("mailingState", locationState);
                        setValue("mailingZip", locationZip);
                        setGarageAddress(addesss);
                      }}
                      id="same-as"
                    />
                    <label className="form-check-label text-capitalize" htmlFor="flexCheckDefault">
                      Same As Location Address
                    </label>
                  </div>
                </label>

                <PlaceComponent
                  getLocationAddress={getGarageAddress}
                  value={garageAddress}
                  onChange={(e) => {
                    setGarageAddress(e.target.value);
                    setValue("mailingAddress", e.target.value);
                    setValue("mailingZip", null);
                    setValue("mailingState", null);
                    setValue("mailingCity", null);
                  }}
                  type="profile-update"
                  readOnly={sameAsAddress}
                  name="mailingAddress"
                  control={control}
                >
                  {" "}
                </PlaceComponent>
                <p className="text-danger">{errors.mailingAddress && <p>{errors.mailingAddress.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mb-3">
                <label class="brand-label">
                  Coverage<span className="required-mark">*</span>
                </label>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="propertyCheck" value="Property" {...register("coverage")} />
                      <label className="form-check-label text-capitalize" for="propertyCheck">
                        Property
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="generalLiabilityCheck" value="General Liability" {...register("coverage")} />
                      <label className="form-check-label text-capitalize" for="generalLiabilityCheck">
                        General Liability
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="equipmentbreakdownCheck" value="Equipment breakdown" {...register("coverage")} />
                      <label className="form-check-label text-capitalize" for="equipmentbreakdownCheck">
                        Equipment breakdown
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="workerCompensationCheck" value="Worker’s compensation" {...register("coverage")} />
                      <label className="form-check-label text-capitalize" for="workerCompensationCheck">
                        Worker’s compensation
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" id="othersCheck" value="Others" {...register("coverage")} />
                      <label className="form-check-label text-capitalize" for="othersCheck">
                        Others
                      </label>
                    </div>
                  </div>
                </div>
                <p className="text-danger">{errors.coverage?.message}</p>
              </div>
            </div>
          </div>
        </section>
        <div className="text-end">
          {restaurantId && (
            <button type="button" onClick={() => handleExitButton("Hotel Commercial Application")} className="btn btn-danger ms-2 text-capitalize">
              Exit if no changes required
            </button>
          )}
          <button className="btn btn-primary ms-2">Next</button>
        </div>
      </form>
    </>
  );
};

export default Step1;
