import React, { useEffect, useState } from "react";
import "./../../portal-assets/styles.css";
import { ThreeDots } from "react-loader-spinner";
import Spinner from "react-bootstrap/Spinner";
import { default as axios } from "../../config/axios-config";
import ImageUpload from "./ImageUpload";
import PlaceComponent from "../healthcare/GooglePlace";
import YourProduct from "./YourProduct";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { formatTelNumber, getAllDataTogether } from "../helper/Helper";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/agency";

const AccountDetails = () => {
  const dispatch = useDispatch();
  let allData = useSelector((state) => state.someData);
  const userData = useSelector((state) => state.auth.user);
  const [updateALlData, setUpdateALlData] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const agencyId = query.get("id");
  if ((allData?.data.length <= 0 || updateALlData == true) && userData) {
    getAllDataTogether(agencyId, userData.agentId, dispatch);
  }
  const [profileDetails, setProDetails] = useState(null);
  const [showPass, setShowPass] = useState(0);
  const [uploadedImage, setUploadedImages] = useState(null);
  const [base64LogoString, setBase64LogoString] = useState(null);
  const [isEdite, setIsEdite] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [updatedData, setUpdatedData] = useState(null);
  const [showPhoneErr, setShowPhoneErr] = useState(false);

  const handleImageUpload = (image) => {
    profileDetails.logo = URL.createObjectURL(image);
    profileDetails.base64logo = profileDetails.logo;
    setProDetails(profileDetails);
    setUploadedImages(image);
    setBase64Logo(image);
  };

  const setBase64Logo = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64LogoString(reader.result.replace("data:", "").replace(/^.+,/, ""));
    };
    reader.readAsDataURL(file);
  };

  const getProfileData = (data) => {
    if (allData?.data?.agencyInfo) {
      data = allData?.data?.agencyInfo;
      // let base64logo = data.base64Logo ? `data:image/jpeg;base64, ${data.base64Logo}` : null;
      data.base64logo = data.base64Logo;
      setProDetails(data);
      setBase64LogoString(data.base64Logo);
      return;
    }
    let id = localStorage.getItem("AgencyId");
    axios
      .get(`${DEVURL}/getAgencyById/${id}`)
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          // data.base64logo = data.base64Log ?? `data:image/jpeg;base64, ${data.base64Logo}`;
          data.base64logo = `${data.base64Logo}`;
          setBase64LogoString(data.base64Logo);
          setProDetails(data);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status === 404) {
        } else {
        }
      });
  };

  useEffect(() => {
    getProfileData();
  }, []);
  const showHidePass = () => {
    showPass ? setShowPass(0) : setShowPass(1);
  };
  const changeEditeStatus = (type) => {
    setShowPhoneErr(false);
    if (type == "edite") {
      setIsEdite(true);
      let data = {
        id: profileDetails.id,
        agencyName: profileDetails.agencyName,
        website: profileDetails.website,
        aboutUs: profileDetails.aboutUs,
        address: profileDetails.address,
        city: profileDetails.city,
        zip: profileDetails.zip,
        state: profileDetails.state,
        phone: profileDetails.phone,
      };
      setUpdatedData(data);
    } else {
      getProfileData()
      setIsEdite(false);
      setUpdatedData({});
    }
  };
  const saveData = () => {
    if (profileDetails.phone.length > 0 && profileDetails.phone.length !== 10) {
      setShowPhoneErr(true);
      return;
    } else {
      setShowPhoneErr(false);
      let data = {
        id: profileDetails.id,
        agencyName: profileDetails.agencyName,
        website: profileDetails.website,
        aboutUs: profileDetails.aboutUs,
        address: profileDetails.address,
        city: profileDetails.city,
        zip: profileDetails.zip,
        state: profileDetails.state,
        phone: formatTelNumber(profileDetails.phone),
        base64Logo: `data:image/jpeg;base64, ${base64LogoString.replace("data:image/jpeg;base64,", "")}`,
      };
      setIsLoader(true);
      axios
        .put(`${DEVURL}/updateAgency`, data)
        .then((response) => {
          if (response.status === 200) {
            setIsEdite(false);
            // window.location.reload();
            setUpdateALlData(true);
            setTimeout(() => {
              setUpdateALlData(false);
            }, 200);
            localStorage.setItem("agency_data", JSON.stringify(profileDetails));
          }
          setIsLoader(false);
        })
        .catch((error) => {
          if (error?.response?.data?.status === 404) {
          } else {
          }
          setIsLoader(false);
        });
    }
  };
  const updateValue = (e, type) => {
    let newValue;
    if (type === "phone") {
      const filteredValue = e.target.value.replace(/\D/g, "");
      newValue = filteredValue;
    } else {
      newValue = e.target.value;
    }
    console.log("newValue", newValue);
    // Create a new copy of profileDetails and update the specific property
    const updatedProfileDetails = { ...profileDetails, [type]: newValue };
    // Update the state with the updated profileDetails
    setProDetails(updatedProfileDetails);
  };

  const getLocation = async (data) => {
    // console.log(data)
    let address_components = data.address_components;
    // let formatted_address = data.formatted_address;
    // formatted_address = formatted_address.split(',');
    // let updatedProfileDetails = { ...profileDetails, ['address']: data.name };
    profileDetails.address = data.name;
    setProDetails(profileDetails);
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        zip_code = data.long_name + zip_code;
        profileDetails.zipCode = zip_code;
        profileDetails.zip = zip_code;
        setProDetails(profileDetails);
      }
      // if (data.types.includes("postal_code_suffix")) {
      //     zip_code =  zip_code +'-'+ data.long_name;
      // }
      if (data.types.includes("locality")) {
        profileDetails.city = data.long_name;
        setProDetails(profileDetails);
      }
      if (data.types.includes("administrative_area_level_1")) {
        profileDetails.state = data.short_name;
        setProDetails(profileDetails);
      }
    });
  };

  const passwordShow = () => {
    if (showPass) {
      return <span>{profileDetails?.password}</span>;
    } else {
      return <span>********</span>;
    }
  };

  const loader = (type = "3dot") => {
    if (type === "3dot") {
      return (
        <ThreeDots
          height="40"
          width="80"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperStyle={{ justifyContent: "center", paddingBottom: "10px" }}
          wrapperClass=""
          visible={true}
        />
      );
    } else {
      return (
        <Spinner animation="border" role="status" style={{ "margin-right": "20px" }}>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );
    }
  };

  const show_details = () => {
    if (profileDetails) {
      return (
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="account" role="tabpanel" aria-labelledby="account-tab">
            <div className="pt-5 pb-2">
              <div className="row mb-3">
                <div className="col-md-4">
                  <div className="acc-sec-title">
                    <h4>Your Company</h4>
                  </div>
                </div>
                <div className="col-md-8">
                  <ul className="mb-1 d-flex" style={{ "justify-content": "right" }}>
                    {isLoader && loader("spinner")}
                    {!isEdite && (
                      <button className="btn btn-primary w-30 me-1" role="button" onClick={() => changeEditeStatus("edite")}>
                        Edit
                      </button>
                    )}
                    {isEdite && (
                      <>
                        <button className="btn btn-success w-30 me-1" role="button" onClick={saveData}>
                          Save
                        </button>
                        <button className="btn btn-warning w-30 me-1" role="button" onClick={() => changeEditeStatus("cancel")}>
                          Cancel
                        </button>
                      </>
                    )}
                  </ul>
                  <ul className="list-group mb-3">
                    <li className="list-group-item d-flex align-items-center">
                      <h6 className="mb-0 w-30 me-3">Name</h6>

                      {isEdite ? (
                        <input type="text" className="form-control" name="name" onChange={(event) => updateValue(event, "agencyName")} value={profileDetails?.agencyName} />
                      ) : (
                        <p className="mb-0">{profileDetails?.agencyName}</p>
                      )}
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <h6 className="mb-0 w-30 me-3">Address</h6>

                      {isEdite ? (
                        <PlaceComponent
                          getLocationAddress={getLocation}
                          type={"profile-update"}
                          value={
                            profileDetails?.address != "" ? profileDetails?.address + "," + profileDetails?.city + "," + profileDetails?.state + "-" + profileDetails?.zip : ""
                          }
                        >
                          {" "}
                        </PlaceComponent>
                      ) : (
                        <p className="mb-0">
                          {profileDetails?.city ? profileDetails?.address + "," + profileDetails?.city + "," + profileDetails?.state + "-" + profileDetails?.zip : ""}
                        </p>
                      )}
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <h6 className="mb-0 w-30 me-3">Website</h6>

                      {isEdite ? (
                        <input type="text" className="form-control" name="name" value={profileDetails?.website} onChange={(event) => updateValue(event, "website")} />
                      ) : (
                        <p className="mb-0">
                          <a href={profileDetails?.website} target="_blank" rel="noreferrer">
                            {profileDetails?.website}
                          </a>
                        </p>
                      )}
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <h6 className="mb-0 w-30 me-3">Phone</h6>

                      {isEdite ? (
                        // <input type="text" className="form-control" name="name" onChange={(event) => updateValue(event, "phone")} value={profileDetails?.phone} />
                        <div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="(XXX) XXX-XXXX"
                            value={formatTelNumber(profileDetails?.phone)}
                            // value={profileDetails?.phone}
                            onChange={(event) => updateValue(event, "phone")}
                          />
                          {showPhoneErr && (
                            <span className="required-mark" style={{ fontSize: 14 }}>
                              Invalid Phone Number
                            </span>
                          )}
                        </div>
                      ) : (
                        <p className="mb-0">{profileDetails?.phone}</p>
                      )}
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <h6 className="mb-0 w-30 me-3">About us</h6>

                      {isEdite ? (
                        <input type="text" className="form-control" name="name" onChange={(event) => updateValue(event, "aboutUs")} value={profileDetails?.aboutUs} />
                      ) : (
                        <p className="mb-0">{profileDetails?.aboutUs}</p>
                      )}
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <h6 className="mb-0 w-30 me-3">Logo</h6>
                      <div className="logo-view">
                        {profileDetails.base64logo ? (
                          <img className="preview-logo w-50 mb-3" src={`data:image/jpeg;base64, ${profileDetails?.base64logo.replace("data:image/jpeg;base64,", "")}`} alt="" />
                        ) : (
                          <span>Upload Logo</span>
                        )}
                        {isEdite && <ImageUpload isEdit={isEdite} onImageUpload={handleImageUpload} />}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-4">
                  <div className="acc-sec-title">
                    <h4>Your Account</h4>
                  </div>
                </div>
                <div className="col-md-8">
                  <ul className="list-group mb-3">
                    <li className="list-group-item d-flex align-items-center">
                      <h6 className="mb-0 w-30 me-3">Email</h6>
                      <p className="mb-0">{profileDetails?.email}</p>
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <h6 className="mb-0 w-30 me-3">Password</h6>
                      <p className="mb-0">
                        {passwordShow()}
                        <a href="javascript:void(0)" className="px-2" onClick={showHidePass}>
                          <i className="bi-eye"></i>
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/*<div className="tab-pane fade" id="billing" role="tabpanel"
                        aria-labelledby="billing-tab">
                        <div> Hello</div>
                    </div>
                    <div className="tab-pane fade" id="your-products" role="tabpanel"
                        aria-labelledby="your-products-tab">
                        <YourProduct />
                                            </div> */}
        </div>
      );
    } else {
      return loader();
    }
  };
  return (
    <>
      <div className="col py-3">
        <div className="content-panel">
          <div className="panel-title">
            <h4>Account Details</h4>
          </div>

          <div className="account-details-tabs mt-5 mb-3"></div>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link brand-tab active"
                id="account-tab"
                data-bs-toggle="tab"
                data-bs-target="#account"
                type="button"
                role="tab"
                aria-controls="account"
                aria-selected="true"
              >
                Account
              </button>
            </li>
            {/*<li className="nav-item" role="presentation">
                            <button className="nav-link brand-tab" id="billing-tab" data-bs-toggle="tab"
                                data-bs-target="#billing" type="button" role="tab"
                                aria-controls="billing"
                                aria-selected="false">Billing
                            </button>
                        </li
                        <li className="nav-item" role="presentation">
                            <button className="nav-link brand-tab" id="your-products-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#your-products" type="button" role="tab"
                                aria-controls="your-products"
                                aria-selected="false">Your Products
                            </button>
                        </li>>*/}
          </ul>
          {show_details()}
        </div>
      </div>

      <div className="offcanvas offcanvas-end cust-width" tabIndex="-1" id="accountDetail" aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header brand-offcanvas-header">
          <h5 id="offcanvasRightLabel">Personal Info</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <div className="account-details-data">
            <div className="row">
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <input type="text" className="form-control" id="firstName" placeholder="First Name" />
                  <label htmlFor="firstName">
                    First Name <span className="required-mark">*</span>
                  </label>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <input type="text" className="form-control" id="lastName" placeholder="Last Name" />
                  <label htmlFor="lastName">
                    Last Name <span className="required-mark">*</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="form-floating mb-3">
              <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
              <label htmlFor="floatingInput">Email address</label>
            </div>

            <div className="form-floating mb-3">
              <input type="tel" className="form-control" id="floatingInput" placeholder="+1 73657 83677" />
              <label htmlFor="floatingInput">Contact Number</label>
            </div>

            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button className="btn btn-outline-secondary me-md-2" type="button">
                Cancel
              </button>
              <button className="btn btn-primary" type="button">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AccountDetails;
