import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
// import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import ReCAPTCHA from "react-google-recaptcha";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import * as yup from "yup";
import axiosInstance from "../../config/axios-config";
import useExitConfirmationHandler from "../../hooks/useExitConfirmationHandler.hook";
import axios from "axios";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/GeneralContractor";
const DEVURL_MANAGEAGENCY = process.env.REACT_APP_ANC_API + "/api/manageagency";

const getValidationSchema = (agentId) => {
  let baseSchema = {
    selectedWork: yup.array().required("Regular work is required").min(1, "Please select at least 1 item").label("Regular work"),
    doEmpNeedToAddCoverage: yup.string().required("Employees coverage is required").label("Employees coverage"),
    subContractorCoverage: yup.string().required("Sub-contractor coverage is required").label("Sub-contractor coverage"),
    isWorkInHospital: yup.string().required("Hospital work is required").label("Hospital work"),
    isMasonryWrk: yup.string().required("Masonary work is required").label("Masonary work"),

    isCustomHomeWrk: yup.string().required("Custom home work is required").label("Custom homes work"),
    isCarpentry: yup.string().required("Carpentry is required").label("Carpentry"),
    isBoilerInstall: yup.string().required("Boiler installation is required").label("Boiler installation"),
    isConcreteWrk: yup.string().required("Concrete construction is required").label("Concrete construction"),
    isAirConditionHeating: yup.string().required("Air conditioning/heating is required").label("Air conditioning/heating"),
    isEnvirnomentalCleanUp: yup.string().required("Environmental cleanup is required").label("Environmental cleanup"),
    anyClaim: yup.string().required("Claim is required").label("Claim"),
    //   priorLoss: yup
    //     .string()
    //     .trim()
    //     .when("anyClaim", (anyClaim, schema) => {
    //       if (anyClaim && anyClaim.includes("yes")) {
    //         return schema.required("Prior loss is required");
    //       } else return schema.notRequired();
    //     })
    //     .label("Prior loss"),
    claimType: yup
      .string()
      .trim()
      .when("anyClaim", (anyClaim, schema) => {
        if (anyClaim && anyClaim.includes("1")) {
          return schema.required("Claim type is required");
        } else return schema.notRequired();
      })
      .label("Claim"),
    claimDate: yup
      .string()
      .nullable(true)
      .typeError("Please enter valid date")
      .when("anyClaim", (anyClaim, schema) => {
        if (anyClaim && anyClaim.includes("1")) {
          return schema.required("Claim Date is required");
        } else return schema.notRequired();
      })
      .test("is-past-date", "Date must be a past date", (value) => {
        if (!value || isNaN(Date.parse(value))) {
          return true;
        }
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const inputDate = new Date(value);
        inputDate.setHours(0, 0, 0, 0);

        return inputDate < currentDate;
      })
      .label("Claim date"),
    termsCondition: yup.boolean().test("termsCondition", "Terms and condition is required", (val) => {
      return val;
    }),
    feinNum: yup
      .string()
      .trim()
      .nullable(true)
      .matches(/^\d{2}-\d{7,8}$/, "Please enter a valid fein")
      .label("FEIN"),
  };
  if (agentId == null) {
    baseSchema = {
      ...baseSchema,
      agentId: yup.string().required("Selecting an agent is required.").notOneOf(["", null, undefined], "You must select a valid agent."),
    };
  }
  return yup.object().shape(baseSchema);
};

const Step2 = ({ stepHandler, loader, agentId }) => {
  const { agencyName, agentName } = useParams();
  const validationSchema = getValidationSchema(agentId);
  const [agentList, setAgentList] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    getValues,
    reset,
    control,
  } = useForm({
    defaultValues: {
      annualGrossSale: null,
      selectedWork: [],
      isWorkInHospital: "",
      totalPayRoll: null,
      feinNum: null,
      isMasonryWrk: "",
      isCustomHomeWrk: "",
      isCarpentry: "",
      isBoilerInstall: "",
      isConcreteWrk: "",
      isAirConditionHeating: "",
      isEnvirnomentalCleanUp: "",
      _1099employee: null,
      payrollOfEmp: null,
      howmanyw2: null,
      costOfSubContractor: null,
      persentOfSubContractorWrk: null,
      persentOfEmprWrk: null,
      doEmpNeedToAddCoverage: "",
      subContractorCoverage: "",
      anyClaim: "",
      claimType: "",
      claimDate: null,
    },
    resolver: yupResolver(validationSchema),
  });
  const handleExitButton = useExitConfirmationHandler();
  const [isLoading, setLoading] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("htappid");
  const agencyId = query.get("id");
  // const agentId = query.get("agentId");
  const generalContractId = query.get("primaryid");
  const navigate = useNavigate();
  const [disableAdd, setDisableAdd] = useState(false);
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);
  const claimsValue = watch("anyClaim");
  const [selectedWorkPersentage, setselectedWork] = useState({});
  const onChangeRecaptcha = async (value) => {
    if (value === null) {
      setIsCaptchaSuccess(false);
    } else {
      setIsCaptchaSuccess(true);
    }
  };

  const selectedWork = watch("selectedWork");

  const setValueFormselectedWorkPersentage = (value, type) => {
    setselectedWork({
      ...selectedWorkPersentage,
      [type]: value.target.value,
    });
  };

  const handleFeinChange = (e) => {
    console.log(e.keyCode);
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, "");
    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2) + "-" + inputValue.slice(2);
    }
    const name = e.target.name;
    setValue(name, inputValue);
  };

  useEffect(() => {
    setValue("agentId", getValues("agentId"));
  }, [getValues("agentId")]);

  const getAgentName = () => {
    if (agentList?.length) {
      const agent = agentList?.find((agent) => agent.id === getValues("agentId"));
      console.log("agent", agent, getValues("agentId"));
      return `${agent?.firstName} ${agent?.lastName}`;
    }
  };

  useEffect(() => {
    loader();
    const formData = JSON.parse(localStorage.getItem("step2"));
    if (formData) {
      setValue("agentId", agentId == null ? formData?.agentId : agencyId);
      setValue("annualGrossSale", formData.annualGrossSale);
      setValue("selectedWork", formData.selectedWork);
      setValue("isWorkInHospital", formData.isWorkInHospital);
      setValue("isMasonryWrk", formData.isMasonryWrk);
      setValue("isCustomHomeWrk", formData.isCustomHomeWrk);
      setValue("isCarpentry", formData.isCarpentry);
      setValue("isBoilerInstall", formData.isBoilerInstall);
      setValue("isConcreteWrk", formData.isConcreteWrk);
      setValue("isAirConditionHeating", formData.isAirConditionHeating);
      setValue("isEnvirnomentalCleanUp", formData.isEnvirnomentalCleanUp);
      setValue("_1099employee", formData._1099employee);
      setValue("totalPayRoll", formData.totalPayRoll);
      setValue("feinNum", formData.feinNum);
      setValue("payrollOfEmp", formData.payrollOfEmp);
      setValue("howmanyw2", formData.howmanyw2);
      setValue("costOfSubContractor", formData.costOfSubContractor);
      setValue("persentOfSubContractorWrk", formData.persentOfSubContractorWrk);
      setValue("persentOfEmprWrk", formData.persentOfEmprWrk);
      setValue("doEmpNeedToAddCoverage", formData.doEmpNeedToAddCoverage);
      setValue("subContractorCoverage", formData.subContractorCoverage);
      setValue("priorLoss", formData.priorLoss);
      setValue("anyClaim", formData.anyClaim);
      setValue("claimDate", formData.claimDate);
      setValue("claimType", formData.claimType);

      if (formData.selectedWork && formData.selectedWork.length > 0) {
        let selectedWorks = [];
        let selectedCommaSeparatedString = "";
        formData.selectedWork.forEach((work) => {
          const [label, percentage] = work.split(":");
          selectedWorks.push(label);
          selectedCommaSeparatedString += `${label}: ${percentage},`;
        });
        let selectedPercentageValues = selectedCommaSeparatedString.slice(0, -1);
        setValue("selectedWork", selectedWorks);
        var keyValuePairs = selectedPercentageValues.split(",");
        var resultObject = {};
        for (var i = 0; i < keyValuePairs.length; i++) {
          var pair = keyValuePairs[i].split(":");
          var key = pair[0].trim();
          var value = parseInt(pair[1].trim());
          resultObject[key] = value;
        }
        setselectedWork(resultObject);
      }
    }
  }, []);

  const getAgentList = async () => {
    await axios
      .get(`${DEVURL_MANAGEAGENCY}/GetAgentInfoByAgency/${agencyName}`)
      .then((response) => {
        if (response.status === 200) {
          setAgentList(response.data.agent_info_list);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (agencyName) getAgentList();
  }, [agencyName]);

  useEffect(() => {
    if (claimsValue?.includes("0")) {
      setValue("claimDate", null);
      setValue("claimType", "");
      ////   setValue("propertyPriorLoss", "");
    }
  }, [claimsValue]);

  const clearLocalStorage = () => {
    localStorage.removeItem("step1");
    localStorage.removeItem("step2");
    localStorage.removeItem("step3");
    localStorage.removeItem("customerId");
  };

  const creategenContractInfoObj = (genContractInfo) => {
    console.log(genContractInfo);
    let salonToCreate = {
      customerInfo: {
        agencyId: genContractInfo.agencyId,
        agentId: genContractInfo.agentId,
        customerId: genContractInfo.customerId,
        firstName: genContractInfo.firstName,
        lastName: genContractInfo.lastName,
        locationAddress: genContractInfo.locationAddress,
        locationCity: genContractInfo.locationCity,
        locationZip: genContractInfo.locationZip,
        locationState: genContractInfo.locationState,
        mailingAddress: genContractInfo.mailingAddress,
        mailingCity: genContractInfo.mailingCity,
        mailingZip: genContractInfo.mailingZip,
        mailingState: genContractInfo.mailingState,
        businessName: genContractInfo.businessName,
        dbaname: genContractInfo.dbaname,
        hoursOfOperation: genContractInfo.hoursOfOperation,
        typeOfBusiness: [],
        mobileNum: genContractInfo.mobileNum,
        workNum: genContractInfo.workNum,
        email: genContractInfo.email,
        faxNum: "",
        termsCondition: genContractInfo.termsCondition?.toString(),
      },
      generalContractorInfo: {
        generalContractId: genContractInfo.generalContractId,
        coverage: [],
        effectiveDate: genContractInfo.effectiveDate,
        anyClaim: genContractInfo.anyClaim,
        claimDate: genContractInfo.claimDate,
        claimType: genContractInfo.claimType,
        priorLoss: genContractInfo.priorLoss,
        lossRunYear: genContractInfo.lossRunYear,
        annualGrossSale: genContractInfo.annualGrossSale,
        costOfSubContractor: genContractInfo.costOfSubContractor,
        persentOfSubContractorWrk: genContractInfo.persentOfSubContractorWrk,
        persentOfEmprWrk: genContractInfo.persentOfEmprWrk,
        payrollOfEmp: genContractInfo.payrollOfEmp,
        _1099employee: genContractInfo._1099employee,
        howmanyw2: genContractInfo.howmanyw2,
        doEmpNeedToAddCoverage: genContractInfo.doEmpNeedToAddCoverage,
        isWorkInHospital: genContractInfo.isWorkInHospital,
        isMasonryWrk: genContractInfo.isMasonryWrk,
        isCustomHomeWrk: genContractInfo.isCustomHomeWrk,
        isCarpentry: genContractInfo.isCarpentry,
        isBoilerInstall: genContractInfo.isBoilerInstall,
        isConcreteWrk: genContractInfo.isConcreteWrk,
        isAirConditionHeating: genContractInfo?.isAirConditionHeating,
        isEnvirnomentalCleanUp: genContractInfo.isEnvirnomentalCleanUp,
        selectedWork: genContractInfo.convertedSelectedWorkArray,
        totalPayRoll: genContractInfo.totalPayRoll,
        feinNum: genContractInfo.feinNum,
        numOfEmp: 0,
        isTermsCondition: 0,
        hoursOfOperation: genContractInfo.hoursOfOperation,
        typeOfBusiness: genContractInfo.typeOfBusiness,
        typeOfWork: "To Do",
        subContractorCoverage: genContractInfo.subContractorCoverage,
      },
    };
    return salonToCreate;
  };

  const handleFormSubmit = async (data) => {
    console.log("data", data);

    const customerId = localStorage.getItem("customerId");

    const filteredPercentageWork = Object.fromEntries(Object.entries(selectedWorkPersentage).filter(([key]) => selectedWork.includes(key)));
    const convertedSelectedWorkArray = Object.entries(filteredPercentageWork).map(([key, value]) => `${[key]}:${value}`);
    const updatedData = {
      ...data,
      selectedWork: convertedSelectedWorkArray,
    };
    localStorage.setItem("step2", JSON.stringify(updatedData));
    const step1 = localStorage.getItem("step1");
    const step2 = localStorage.getItem("step2");
    const genContractInfo = {
      ...JSON.parse(step1),
      ...JSON.parse(step2),
      convertedSelectedWorkArray: convertedSelectedWorkArray,
    };

    const genContractInfoObj = await creategenContractInfoObj(genContractInfo);
    console.log("ge", genContractInfoObj);
    Swal.fire({
      title: "Are you sure you want to submit the form?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        if (!generalContractId) {
          setLoading(true);
          axiosInstance
            .post(`${DEVURL}/InsertGeneralContractorApp`, genContractInfoObj)
            .then((res) => {
              if (res.status === 200) {
                setLoading(false);
                clearLocalStorage();
                reset();
                stepHandler(3);
              }
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        } else {
          setLoading(true);
          axiosInstance
            .put(`${DEVURL}/UpdateGeneralContractorApp/${customerId}/${generalContractId}`, genContractInfoObj)
            .then((res) => {
              if (res.status === 200) {
                setLoading(false);
                clearLocalStorage();
                reset();
                stepHandler(3);
              }
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      } else {
        setLoading(false);
        return;
      }
    });
  };

  if (isLoading) {
    return loader();
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)} className="tab-wizard wizard-circle wizard needs-validation">
        <section>
          <h4 class="wrap-title mb-3">Contractor Information</h4>

          <div className="row">
            <div class="col-md-6 col-lg-6">
              <div class="mb-3">
                <label class="text-capitalize brand-label">Annual Gross Sales</label>
                <Controller
                  name="annualGrossSale"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="annualGrossSale"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="annualGrossSale"
                      placeholder="Enter Gross Sales"
                      onValueChange={(values) => {
                        setValue("annualGrossSale", values.floatValue);
                      }}
                      {...register("annualGrossSale")}
                    />
                  )}
                />
              </div>
            </div>

            <div class="col-md-6 col-lg-6">
              <div class="mb-3">
                <label class="text-capitalize brand-label d-grid">Cost For Sub-contractor Out Of Gross Sales?</label>
                <Controller
                  name="costOfSubContractor"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="costOfSubContractor"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="costOfSubContractor"
                      placeholder="Enter The Cost Of Sub-contrator"
                      onValueChange={(values) => {
                        setValue("costOfSubContractor", values.floatValue);
                      }}
                      {...register("costOfSubContractor")}
                    />
                  )}
                />
              </div>
            </div>

            <div class="col-md-6 col-lg-6">
              <div class="mb-3">
                <label class="text-capitalize brand-label ">percentage for sub-contractor work?</label>
                <Controller
                  name="persentOfSubContractorWrk"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="persentOfSubContractorWrk"
                      className="form-control"
                      thousandSeparator={true}
                      suffix="%"
                      name="persentOfSubContractorWrk"
                      placeholder="Enter Percentage For Sub-contractor Work"
                      onValueChange={(values) => {
                        setValue("persentOfSubContractorWrk", values.formattedValue);
                      }}
                      {...register("persentOfSubContractorWrk")}
                    />
                  )}
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="mb-3">
                <label class="text-capitalize brand-label">percentage of work done by the own employees?</label>
                <Controller
                  name="persentOfEmprWrk"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="persentOfEmprWrk"
                      className="form-control"
                      thousandSeparator={true}
                      suffix="%"
                      name="persentOfEmprWrk"
                      onValueChange={(values) => {
                        console.log(values);
                        setValue("persentOfEmprWrk", values.formattedValue);
                      }}
                      placeholder="Enter Percentage Of Work Done"
                      {...register("persentOfEmprWrk")}
                    />
                  )}
                />
              </div>
            </div>

            <div class="col-md-6 col-lg-6">
              <div class="mb-3">
                <label class="text-capitalize brand-label">payroll for the employees?</label>
                <Controller
                  name="payrollOfEmp"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="payrollOfEmp"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="payrollOfEmp"
                      onValueChange={(values) => {
                        setValue("payrollOfEmp", values.floatValue);
                      }}
                      placeholder="Enter Payroll For The Employees"
                      {...register("payrollOfEmp")}
                    />
                  )}
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="mb-3">
                <label class="text-capitalize brand-label">How many 1099 employees?</label>
                <Controller
                  name="_1099employee"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="_1099employee"
                      className="form-control"
                      thousandSeparator={true}
                      name="_1099employee"
                      onValueChange={(values) => {
                        setValue("_1099employee", values.value);
                      }}
                      placeholder="Enter No. of 1099 Employees"
                      {...register("_1099employee")}
                    />
                  )}
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="mb-3">
                <label class="text-capitalize brand-label">How many W-2 employee?</label>
                <Controller
                  name="howmanyw2"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="howmanyw2"
                      className="form-control"
                      thousandSeparator={true}
                      name="howmanyw2"
                      onValueChange={(values) => {
                        setValue("howmanyw2", values.value);
                      }}
                      placeholder="Enter No. of W-2 Employees"
                      {...register("howmanyw2")}
                    />
                  )}
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="mb-3">
                <label class="text-capitalize brand-label">
                  Do employees need to be added in the coverage?
                  <span className="required-mark">*</span>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="doEmpNeedToAddCoverage"
                      id="doEmpNeedToAddCoverageCheckYes"
                      value="1"
                      {...register("doEmpNeedToAddCoverage")}
                    />
                    <label className="text-capitalize form-check-label" htmlFor="doEmpNeedToAddCoverageCheckYes">
                      Yes
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="doEmpNeedToAddCoverage"
                      id="doEmpNeedToAddCoverageCheckNo"
                      value="0"
                      {...register("doEmpNeedToAddCoverage")}
                    />

                    <label className="text-capitalize form-check-label" htmlFor="doEmpNeedToAddCoverageCheckNo">
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">{errors.doEmpNeedToAddCoverage?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label class="text-capitalize brand-label">
                  Need coverage for sub-contractor?
                  <span className="required-mark">*</span>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="subContractorCoverage"
                      id="yessubContractorCoverageCheck"
                      value="1"
                      {...register("subContractorCoverage")}
                    />
                    <label className="text-capitalize form-check-label" htmlFor="yessubContractorCoverageCheck">
                      Yes
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="subContractorCoverage"
                      id="nosubContractorCoverageCheck"
                      value="0"
                      {...register("subContractorCoverage")}
                    />

                    <label className="text-capitalize form-check-label" htmlFor="nosubContractorCoverageCheck">
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">{errors.subContractorCoverage?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="text-capitalize brand-label d-grid">
                  <div>
                    Any custom homes work?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="yesHomesCheck" value="1" {...register("isCustomHomeWrk")} />
                  <label class="text-capitalize form-check-label" for="yesHomesCheck">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="noHomesCheck" value="0" {...register("isCustomHomeWrk")} />
                  <label class="text-capitalize form-check-label" for="noHomesCheck">
                    No
                  </label>
                </div>
                <p className="text-danger">{errors.isCustomHomeWrk?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="text-capitalize brand-label d-grid">
                  <div>
                    Any work for hospitals?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="yesHospitalsCheck" value="1" {...register("isWorkInHospital")} />
                  <label class="text-capitalize form-check-label" for="yesHospitalsCheck">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="noHospitalsCheck" value="0" {...register("isWorkInHospital")} />
                  <label class="text-capitalize form-check-label" for="noHospitalsCheck">
                    No
                  </label>
                </div>
                <p className="text-danger">{errors.isWorkInHospital?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="text-capitalize brand-label d-grid">
                  <div>
                    Any masonry works?<span className="required-mark">*</span>
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="yesMasonaryCheck" value="1" {...register("isMasonryWrk")} />
                  <label class="text-capitalize form-check-label" for="yesMasonaryCheck">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="noMasonaryCheck" value="0" {...register("isMasonryWrk")} />
                  <label class="text-capitalize form-check-label" for="noMasonaryCheck">
                    No
                  </label>
                </div>
                <p className="text-danger">{errors.isMasonryWrk?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="text-capitalize brand-label d-grid">
                  <div>
                    Carpentry?<span className="required-mark">*</span>
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="yesCarpentryCheck" value="1" {...register("isCarpentry")} />
                  <label class="text-capitalize form-check-label" for="yesCarpentryCheck">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="noCarpentryCheck" value="0" {...register("isCarpentry")} />
                  <label class="text-capitalize form-check-label" for="noCarpentryCheck">
                    No
                  </label>
                </div>
                <p className="text-danger">{errors.isCarpentry?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="text-capitalize brand-label d-grid">
                  <div>
                    Boiler installations?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="yesBoilerCheck" value="1" {...register("isBoilerInstall")} />
                  <label class="text-capitalize form-check-label" for="yesBoilerCheck">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="noBoilerCheck" value="0" {...register("isBoilerInstall")} />
                  <label class="text-capitalize form-check-label" for="noBoilerCheck">
                    No
                  </label>
                </div>
                <p className="text-danger">{errors.isBoilerInstall?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="text-capitalize brand-label d-grid">
                  <div>
                    Concrete construction?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="yesConcreteCheck" value="1" {...register("isConcreteWrk")} />
                  <label class="text-capitalize form-check-label" for="yesConcreteCheck">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="noConcreteCheck" value="0" {...register("isConcreteWrk")} />
                  <label class="text-capitalize form-check-label" for="noConcreteCheck">
                    No
                  </label>
                </div>

                <p className="text-danger">{errors.isConcreteWrk?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="text-capitalize brand-label">
                  Air conditioning/Heating?
                  <span className="required-mark">*</span>
                </label>
                <div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="yesHeatingCheck" value="1" {...register("isAirConditionHeating")} />
                    <label class="text-capitalize form-check-label" for="yesHeatingCheck">
                      Yes
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="noHeatingCheck" value="0" {...register("isAirConditionHeating")} />
                    <label class="text-capitalize form-check-label" for="noHeatingCheck">
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">{errors.isAirConditionHeating?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="text-capitalize brand-label d-grid">
                  <div>
                    Environmental cleanup?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="yesEnvironmentalCheck" value="1" {...register("isEnvirnomentalCleanUp")} />
                  <label class="text-capitalize form-check-label" for="yesEnvironmentalCheck">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="noEnvironmentalCheck" value="0" {...register("isEnvirnomentalCleanUp")} />
                  <label class="text-capitalize form-check-label" for="noEnvironmentalCheck">
                    No
                  </label>
                </div>
                <p className="text-danger">{errors.isEnvirnomentalCleanUp?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="text-capitalize brand-label d-grid">
                  <div>
                    {" "}
                    Any Claim in past 5 years?
                    <span className="required-mark">*</span>
                  </div>
                </label>

                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="claimYes"
                    value="1"
                    onSelect={(e) => {
                      console.log("yesbutton", e);
                    }}
                    {...register("anyClaim")}
                  />
                  <label class="text-capitalize form-check-label" for="claimYes">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="claimNo" value="0" {...register("anyClaim")} />
                  <label class="text-capitalize form-check-label" for="claimNo">
                    No
                  </label>
                </div>
                <p className="text-danger">{errors.anyClaim && <p>{errors.anyClaim.message}</p>}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="text-capitalize brand-label d-grid">
                  <div>Claim Type?</div>
                </label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="propertyCheck"
                    value="Property"
                    disabled={!claimsValue?.includes("1")}
                    {...register("claimType")}
                  />
                  <label class="text-capitalize form-check-label" for="propertyCheck">
                    Property
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="GLICheck"
                    value="GL"
                    disabled={!claimsValue?.includes("1")}
                    {...register("claimType")}
                  />
                  <label class="text-capitalize form-check-label" for="GLICheck">
                    GL
                  </label>
                </div>

                <p className="text-danger">{errors.claimType && <p>{errors.claimType.message}</p>}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="text-capitalize brand-label">Claim Date?</label>
                <input
                  id="claimDate"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Claim Date"
                  disabled={!claimsValue?.includes("1")}
                  {...register("claimDate")}
                />
                <p className="text-danger">{errors.claimDate && <p>{errors.claimDate.message}</p>}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="text-capitalize brand-label d-grid">Prior Losses</label>
                <Controller
                  name="priorLoss"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="priorLoss"
                      className="form-control"
                      thousandSeparator={true}
                      name="priorLoss"
                      prefix="$"
                      onValueChange={(values) => {
                        setValue("priorLoss", values.value);
                      }}
                      placeholder="Enter if any prior losses"
                      {...register("priorLoss")}
                    />
                  )}
                />

                <p className="text-danger">{errors.priorLoss?.message}</p>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12">
            <div class="mb-3">
              <label class="text-capitalize brand-label">
                <div>
                  Select the work you do in a regular basis:(Select at least one)
                  <span className="required-mark">*</span>
                </div>
              </label>
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="flooringCheck" value="Flooring" {...register("selectedWork")} />

                    <div className="row">
                      <div className="col-8 col-lg-7">
                        <label className="text-capitalize form-check-label" htmlFor="flooringCheck">
                          Flooring {selectedWork.includes("Flooring") && (selectedWorkPersentage.Flooring ?? "0") + "%:"}
                        </label>
                      </div>
                      <div className="col-4 col-lg-5">
                        {selectedWork.includes("Flooring") && (
                          <Box sx={{ width: "60%" }} style={{ height: "10px" }}>
                            <Slider
                              defaultValue={selectedWorkPersentage.Flooring ?? 0}
                              onChange={(value) => setValueFormselectedWorkPersentage(value, "Flooring")}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                            />
                          </Box>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="foundationCheck" value="Foundation" {...register("selectedWork")} />
                    <div className="row">
                      <div className="col-8 col-lg-7">
                        <label className="text-capitalize form-check-label" htmlFor="foundationCheck">
                          Foundation {selectedWork.includes("Foundation") && (selectedWorkPersentage.Foundation ?? "0") + "%:"}
                        </label>
                      </div>
                      <div className="col-4 col-lg-5">
                        {selectedWork.includes("Foundation") && (
                          <Box sx={{ width: "60%" }} style={{ height: "10px" }}>
                            <Slider
                              defaultValue={selectedWorkPersentage.Foundation ?? 0}
                              onChange={(value) => setValueFormselectedWorkPersentage(value, "Foundation")}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                            />
                          </Box>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="roofingCheck" value="Roofing" {...register("selectedWork")} />
                    <div className="row">
                      <div className="col-8 col-lg-7">
                        <label className="text-capitalize form-check-label" htmlFor="roofingCheck">
                          Roofing {selectedWork.includes("Roofing") && (selectedWorkPersentage.Roofing ?? "0") + "%:"}
                        </label>
                      </div>
                      <div className="col-4 col-lg-5">
                        {selectedWork.includes("Roofing") && (
                          <Box sx={{ width: "60%" }} style={{ height: "10px" }}>
                            <Slider
                              defaultValue={selectedWorkPersentage.Roofing ?? 0}
                              onChange={(value) => setValueFormselectedWorkPersentage(value, "Roofing")}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                            />
                          </Box>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="appliancesCheck" value="Installation_Of_Appliances" {...register("selectedWork")} />
                    <div className="row">
                      <div className="col-8 col-lg-7">
                        <label className="text-capitalize form-check-label" htmlFor="appliancesCheck">
                          Installation of Appliances {selectedWork.includes("Installation_Of_Appliances") && (selectedWorkPersentage.Installation_Of_Appliances ?? "0") + "%:"}
                        </label>
                      </div>
                      <div className="col-4 col-lg-5">
                        {selectedWork.includes("Installation_Of_Appliances") && (
                          <Box sx={{ width: "60%" }} style={{ height: "10px" }}>
                            <Slider
                              defaultValue={selectedWorkPersentage.Installation_Of_Appliances ?? 0}
                              onChange={(value) => setValueFormselectedWorkPersentage(value, "Installation_Of_Appliances")}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                            />
                          </Box>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="dryWallCheck" value="Dry_Wall" {...register("selectedWork")} />

                    <div className="row">
                      <div className="col-8 col-lg-7">
                        <label className="text-capitalize form-check-label" htmlFor="dryWallCheck">
                          Dry wall {selectedWork.includes("Dry_Wall") && (selectedWorkPersentage.Dry_Wall ?? "0") + "%:"}
                        </label>
                      </div>
                      <div className="col-4 col-lg-5">
                        {selectedWork.includes("Dry_Wall") && (
                          <Box sx={{ width: "60%" }} style={{ height: "10px" }}>
                            <Slider
                              defaultValue={selectedWorkPersentage.Dry_Wall ?? 0}
                              onChange={(value) => setValueFormselectedWorkPersentage(value, "Dry_Wall")}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                            />
                          </Box>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="paintingCheck" value="Painting" {...register("selectedWork")} />

                    <div className="row">
                      <div className="col-8 col-lg-7">
                        <label className="text-capitalize form-check-label" htmlFor="paintingCheck">
                          Painting {selectedWork.includes("Painting") && (selectedWorkPersentage.Painting ?? "0") + "%:"}
                        </label>
                      </div>
                      <div className="col-4 col-lg-5">
                        {selectedWork.includes("Painting") && (
                          <Box sx={{ width: "60%" }} style={{ height: "10px" }}>
                            <Slider
                              defaultValue={selectedWorkPersentage.Painting ?? 0}
                              onChange={(value) => setValueFormselectedWorkPersentage(value, "Painting")}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                            />
                          </Box>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="reStructuringCheck" value="Re_Structuring" {...register("selectedWork")} />
                    <div className="row">
                      <div className="col-8 col-lg-7">
                        <label className="text-capitalize form-check-label" htmlFor="paintingCheck">
                          Re-Structuring {selectedWork.includes("Re_Structuring") && (selectedWorkPersentage.Re_Structuring ?? "0") + "%:"}
                        </label>
                      </div>
                      <div className="col-4 col-lg-5">
                        {selectedWork.includes("Re_Structuring") && (
                          <Box sx={{ width: "60%" }} style={{ height: "10px" }}>
                            <Slider
                              defaultValue={selectedWorkPersentage.Re_Structuring ?? 0}
                              onChange={(value) => setValueFormselectedWorkPersentage(value, "Re_Structuring")}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                            />
                          </Box>
                        )}
                      </div>
                    </div>
                  </div>{" "}
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="excavationCheck" value="Excavation" {...register("selectedWork")} />
                    <div className="row">
                      <div className="col-8 col-lg-7">
                        <label className="text-capitalize form-check-label" htmlFor="excavationCheck">
                          Excavation {selectedWork.includes("Excavation") && (selectedWorkPersentage.Excavation ?? "0") + "%:"}
                        </label>
                      </div>
                      <div className="col-4 col-lg-5">
                        {selectedWork.includes("Excavation") && (
                          <Box sx={{ width: "60%" }} style={{ height: "10px" }}>
                            <Slider
                              defaultValue={selectedWorkPersentage.Excavation ?? 0}
                              onChange={(value) => setValueFormselectedWorkPersentage(value, "Excavation")}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                            />
                          </Box>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="undergroundTankCheck" value="Any_Underground_Tank_Works" {...register("selectedWork")} />

                    <div className="row">
                      <div className="col-8 col-lg-7">
                        <label className="text-capitalize form-check-label" htmlFor="undergroundTankCheck">
                          Any Underground Tank Works {selectedWork.includes("Any_Underground_Tank_Works") && (selectedWorkPersentage.Any_Underground_Tank_Works ?? "0") + "%:"}
                        </label>
                      </div>
                      <div className="col-4 col-lg-5">
                        {selectedWork.includes("Any_Underground_Tank_Works") && (
                          <Box sx={{ width: "60%" }} style={{ height: "10px" }}>
                            <Slider
                              defaultValue={selectedWorkPersentage.Any_Underground_Tank_Works ?? 0}
                              onChange={(value) => setValueFormselectedWorkPersentage(value, "Any_Underground_Tank_Works")}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                            />
                          </Box>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="anyworkforhighwaysCheck" value="Any_work_for_highways" {...register("selectedWork")} />

                    <div className="row">
                      <div className="col-8 col-lg-7">
                        <label className="text-capitalize form-check-label" htmlFor="anyworkforhighwaysCheck">
                          Any work for highways {selectedWork.includes("Any_work_for_highways") && (selectedWorkPersentage.Any_work_for_highways ?? "0") + "%:"}
                        </label>
                      </div>
                      <div className="col-4 col-lg-5">
                        {selectedWork.includes("Any_work_for_highways") && (
                          <Box sx={{ width: "60%" }} style={{ height: "10px" }}>
                            <Slider
                              defaultValue={selectedWorkPersentage.Any_work_for_highways ?? 0}
                              onChange={(value) => setValueFormselectedWorkPersentage(value, "Any_work_for_highways")}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                            />
                          </Box>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-danger">{errors.selectedWork?.message}</p>
            </div>
          </div>

          <h4 class="wrap-title mb-2">Other</h4>
          <div className="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label class="text-capitalize brand-label d-grid">Total Payroll?</label>
                <Controller
                  name="totalPayRoll"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="totalPayRoll"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="totalPayRoll"
                      placeholder="Enter Payroll"
                      onValueChange={(values) => {
                        console.log("values", values);
                        setValue("totalPayRoll", values.floatValue);
                      }}
                      {...register("totalPayRoll")}
                    />
                  )}
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-3">
                <label class="text-capitalize brand-label d-grid">FEIN Number</label>
                <input className="form-control" type="text" name="feinNum" onKeyUp={handleFeinChange} placeholder="Enter FEIN Number" {...register("feinNum")} />
                <p className="text-danger">{errors.feinNum && <p>{errors.feinNum.message}</p>}</p>
              </div>
            </div>
            {agentId == null ? (
              <div className="row">
                <div className="col-md-6 col-lg-3">
                  {generalContractId ? (
                    <>
                      <label className="brand-label">Selected Agent: &nbsp;</label>
                      <span>{getAgentName()}</span>
                    </>
                  ) : (
                    <div className="mb-3">
                      <label className="brand-label">
                        {generalContractId ? "Selected Agent" : "Select your contact person"}
                        <span className="required-mark">*</span>
                      </label>
                      <select class="form-select" aria-label="Default select example" {...register("agentId")} disabled={generalContractId != null}>
                        <option value={""} selected>
                          Select Agent
                        </option>
                        {agentList?.length ? (
                          agentList.map((agent) => (
                            <option value={agent.id}>
                              {agent?.firstName} {agent?.lastName}
                            </option>
                          ))
                        ) : (
                          <option disabled>No agent found</option>
                        )}
                      </select>
                      <p className="text-danger mt-2">{errors.agentId && <p>{errors.agentId.message}</p>}</p>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
            <div className="text-center mb-3">
              {generalContractId && (
                <button type="button" onClick={() => handleExitButton("General Contractor Insurance Application")} className="btn btn-danger ms-2 text-capitalize">
                  Exit if no changes required
                </button>
              )}
            </div>
            <div className="col-md-12 col-lg-12 mt-1">
              <div className="mb-3">
                <div className="d-flex align-items-baseline gap-10">
                  <input className="me-2" type="checkbox" id="termsCondition" {...register("termsCondition")} />

                  <label className="brand-label ">
                    I understand loss runs are required to receive a quote and I hereby allow the agent to retrieve information using the above-mentioned business information and
                    provide me with quote(s) for my perusal. I also understand that quotes are for information and marketing purpose only.
                  </label>
                </div>

                <p className="text-danger mt-2">{errors.termsCondition && <p>{errors.termsCondition.message}</p>}</p>
                <div className="col-md-6">
                  <div className="mb-3">
                    <ReCAPTCHA sitekey="6LeS4L8mAAAAAEGzyClAMce6777ssfu5VYovTkaW" onChange={onChangeRecaptcha} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="d-flex justify-content-end">
          <button className="btn btn-warning" type="button" onClick={() => stepHandler(1)}>
            Previous
          </button>
          <button type="submit" className="btn btn-primary ms-2" disabled={disableAdd || !isCaptchaSuccessful}>
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default Step2;
