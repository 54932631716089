import axios from "axios";
import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/Products";

const ProductDetails = ({ product, profileDetails, getUpdateProduct }) => {
  const [configEmail, setConfigEmail] = useState("");
  const [ReminderFlag, setReminderFlag] = useState("");
  const [FeedbackFlag, setFeedbackFlag] = useState(false);
  const buttonStyles = (type) => {
    if (type) {
      return { cursor: "none" };
    } else {
      return { cursor: "pointer" };
    }
  };

  const handleNotifications = (msg = "Success", type = "success") => {
    if (type === "error") {
      toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        progress: undefined,
      });
    } else if (type === "success") {
      toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleActivated = (product) => {
    let data = {
      AgencyId: profileDetails?.id,
      AgencyName: profileDetails?.agencyName,
      FirstName: profileDetails?.firstName ?? "",
      LastName: profileDetails?.lastName ?? "",
      Email: profileDetails?.email,
      ProductName: product.productName,
      ProductId: product.productId,
      ProductActivation: "true",
      AlertEmail: configEmail,
      ReminderFlag: ReminderFlag,
      FeedbackFlag: FeedbackFlag.toLocaleString(),
    };

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "mx-4 btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Confirm",
        text: "Are you sure you want to Activate the product?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`${DEVURL}/Activate`, data)
            .then((response) => {
              // console.log("================================================");
              // console.log(data);
              window.location.reload();
              if (response.status === 200) {
                product.activated = true;
                getUpdateProduct();
                Swal.fire({
                  title:
                    "You are all set! Thank you for choosing <br/>" +
                    product.productName +
                    " product",
                  width: 600,
                  padding: "3em",
                  color: "#716add",
                  background: "#fff url(/images/trees.png)",
                  backdrop: `rgba(0,0,123,0.4)  url("/images/nyan-cat.gif") left top    no-repeat
  `,
                });
              }
            })
            .catch((error) => {
              if (error?.response?.data?.status === 404) {
              } else {
              }
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      });
  };

  const updateValue = (e, type = null) => {
    const value = e.target.value;
    if (type === "select") {
      setReminderFlag(value);
    } else if (type === "check") {
      setFeedbackFlag(!FeedbackFlag);
    } else {
      setConfigEmail(value);
    }
  };

  return (
    <>
      <div
        className="offcanvas offcanvas-end cust-width"
        tabIndex="-1"
        id="customerDetail"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header activity-status-table brand-offcanvas-header">
          <h5 id="offcanvasRightLabel">Product Information</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="activity-cust-data">
            <h3 className="mb-3">{product?.productName}</h3>
          </div>
          <div className="activity-cust-data">
            <h6 className="mb-3">{product?.productDesc}</h6>
          </div>
          <div className="activity-cust-data">
            <Card className="brand-card">
              <Card.Header className="brand-card-title">
                <h4>Specify Action</h4>
              </Card.Header>
              <Card.Body>
                In the email field, this is usually a dedicated email address
                used solely for receiving insurance application.
                <br />
                <b>Setting up Email Alerts</b>
                <div className="mb-2">
                  <input
                    type="email"
                    className="form-control"
                    readOnly={product?.activated}
                    onChange={(event) => updateValue(event)}
                    value={configEmail}
                    aria-label="Email"
                    aria-describedby="basic-addon2"
                  />
                </div>
                <div className="mb-2">
                  Auto reminders help keep customers engaged with your business.
                  Whether it's for appointments, pending application, these
                  reminders ensure that customers stay connected and informed.
                </div>
                <div className="mb-2">
                  <b> Reminding alert</b>
                </div>
                <div className="mb-2 col-6">
                  <select
                    className="form-select"
                    value={ReminderFlag}
                    disabled={product?.activated}
                    onChange={(event) => updateValue(event, "select")}
                  >
                    <option value={0}>Number Of Reminders</option>
                    <option value={2}>2 times a week</option>
                    <option value={3}>3 times a week</option>
                  </select>
                </div>
                <div className="mb-2">
                  Customer feedback provides valuable insights into what is
                  working well and what needs improvement.
                  <div className="mb-2">
                    <b>Customer Feedback</b>
                  </div>
                  <div className="mb-2">
                    Do you want us to request for feedback from your customer
                    &nbsp;
                    <input
                      type="checkbox"
                      className="form-check-input"
                      disabled={product?.activated}
                      onChange={(event) => updateValue(event, "check")}
                      name="Feedback"
                    ></input>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          <button
            style={buttonStyles(product?.activated)}
            disabled={product?.activated}
            className={`btn p-2 ${
              product?.activated == 1
                ? "btn-success"
                : product?.activated == 2
                ? "btn-warning"
                : "btn-primary"
            }`}
            onClick={() => handleActivated(product)}
          >
            {" "}
            {product?.activated == 1
              ? "Activated"
              : product?.activated == 2
              ? "Expired"
              : "Try Now Free"}
          </button>
          &nbsp;
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
