import React, { useEffect, useState } from "react";
import { default as axios } from "../../../config/axios-config";

const DEVURL_HEALTH = process.env.REACT_APP_ANC_API + "/api/health/";

const DisplaySelectedPlan = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const planId = queryParams.get("planId");
  const custname = queryParams.get("name");
  const agencyId = queryParams.get("id");
  const [selectedPlan, setSelectedPlan] = useState([]);

  const getPlanByPlanId = async () => {
    await axios
      .get(`${DEVURL_HEALTH}getPlanByPlanId/${planId}`)
      .then((response) => {
        if (response.status === 200) {
          setSelectedPlan(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (planId) {
      getPlanByPlanId();
    }
  }, []);

  return (
    <>
      {planId && (
        <>
          <div className="alert alert-light" role="alert">
            <h4>
              Hey, {custname}, You're one step closer to securing the protection
              and peace of mind you deserve!
            </h4>
            <div className="text-end">
              <a
                target="_blank"
                href={"/quote?id=" + agencyId}
                type="button"
                className="btn btn-secondary end-0"
                rel="noreferrer"
              >
                Start over
              </a>
            </div>
          </div>
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button bg-info"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-controls="collapseOne"
                >
                  <i className="bi bi-arrow-down-circle-fill">
                    <h4>{selectedPlan?.plan?.name} (Selected)</h4>
                  </i>
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="row">
                    <div className="col-md-6 col-lg-7">
                      <h6 className="main-label">
                        {selectedPlan?.plan?.issuer?.name}
                      </h6>
                      <h6 className="main-label">Premium</h6>
                      <h5 className="main-plan-title">
                        {" "}
                        ${selectedPlan?.plan?.premium_w_credit}
                      </h5>
                      <div className="main-plan-badges">
                        <span className="plan-badge">
                          {" "}
                          <a
                            href={selectedPlan?.plan?.benefits_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {selectedPlan?.plan?.issuer?.name}
                          </a>
                        </span>
                        &nbsp;
                        <span className="plan-badge">
                          {" "}
                          {selectedPlan?.plan?.metal_level}
                        </span>
                        &nbsp;
                        <span className="plan-badge">
                          {" "}
                          {selectedPlan?.plan?.type}
                        </span>
                        &nbsp;
                        <span className="plan-badge"> {planId}</span>
                        <br />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-5">
                      <h6 className="main-label">Deductable</h6>
                      <h5 className="main-plan-title">
                        ${selectedPlan?.plan?.deductibles[0]?.amount}
                      </h5>
                      <div className="main-plan-badges"></div>
                    </div>
                  </div>
                  <div className="plan-border-bottom"></div>
                  <div className="plan-border-bottom">
                    <div className="row">
                      <h6 className="main-label">Copayments / Coinsurance</h6>
                      <div className="row">
                        {selectedPlan?.plan?.benefits.map((benefit, index) => (
                          <div className="col-md-6 col-lg-3" key={index}>
                            <p className="main-plan-badges">
                              <b>{benefit?.name}</b>
                              <br />
                              {benefit?.cost_sharings[0]?.display_string}
                            </p>
                            <p className="main-plan-badges"></p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DisplaySelectedPlan;
