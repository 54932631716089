import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";

const placesLibrary = ["places"];

function PlaceComponentFloating({ getLocationAddress, value = "", type = "", name, control, readOnly = false }) {
  const [searchResult, setSearchResult] = useState("");
  const [labelFloat, setLabelFloat] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (value) {
      setLabelFloat(true);
    }
  }, [value]);

  const handleFocus = () => {
    setLabelFloat(true);
  };

  const handleBlur = () => {
    if (!inputRef.current?.value) {
      setLabelFloat(false);
    }
  };

  const API_KEY = process.env.REACT_APP_GOOGLE_KEY;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: placesLibrary,
  });
  function onLoad(autocomplete) {
    console.log("autocomplete", autocomplete);
    setSearchResult(autocomplete);
  }

  const onPlaceChanged = () => {
    if (searchResult != null) {
      //variable to store the result
      const place = searchResult?.getPlace();
      let addr = place.formatted_address ?? "";
      addr = addr.split(",")[0];
      document.getElementById("location-search-input").blur();
      setTimeout(() => {
        if (type !== "profile-update") {
          document.getElementById("location-search-input").value = place.name ?? addr;
        }
      }, 20);
      console.log("place", place);
      getLocationAddress(place);
    }
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {readOnly && <input type="text" id="location-search-input" placeholder="Address" className="form-control" readOnly={readOnly} autoComplete="off" value={value} />}
      {!readOnly && (
        <div className={`form-floating ${labelFloat ? "is-floating" : ""}`} style={{ position: "relative" }}>
          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
            <input
              ref={inputRef}
              onFocus={handleFocus}
              onBlur={handleBlur}
              type="text"
              name={name}
              id="location-search-input"
              className="form-control"
              readOnly={readOnly}
              defaultValue={value}
              autoComplete="off"
              placeholder=""
              style={{ height: "auto", padding: "1.25rem 0.75rem 0.75rem 0.75rem" }}
            />
          </Autocomplete>
          <label htmlFor="location-search-input" className={labelFloat ? "floating-label" : ""}>
            Address
          </label>
          <style jsx>{`
            .is-floating label,
            .floating-label {
              transform: scale(0.85) translateY(-0.7rem) translateX(-0.5rem);
              opacity: 0.65;
            }
          `}</style>
        </div>
      )}
    </>
  );
}

export default PlaceComponentFloating;
