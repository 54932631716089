import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { default as axios } from "../../config/axios-config";
import PlaceComponent from "../healthcare/GooglePlace";
import { getValue } from "@testing-library/user-event/dist/utils";
import useExitConfirmationHandler from "../../hooks/useExitConfirmationHandler.hook";
import { formatTelNumber } from "../../Common/Utils";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/Auto/";
const DEVURL_VERIFY = process.env.REACT_APP_ANC_API + "/api/Verify";

localStorage.removeItem("step2");
localStorage.removeItem("step1");

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .matches(/^[A-Za-z ]*$/, "Please enter a valid first name")
    .max(45)
    .required("First name is required")
    .label("First name"),
  lastName: yup
    .string()
    .trim()
    .matches(/^[A-Za-z ]*$/, "Please enter a valid last name")
    .max(45)
    .required("Last name is required")
    .label("Last name"),

  cell: yup
    .string()
    .trim()
    .required("Phone number is required")
    .matches(/^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/, "Please enter valid phone number")
    .label("Phone number"),
  workPhone: yup
    .string()
    .trim()
    .matches(/^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[0-9]{3}[\s\-]?[0-9]{4}$|^$/, "Please enter valid work phone number")
    .label("Work phone number"),
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Enter a valid email address"
    )
    .label("Email address"),
  apt: yup.string().trim().required("Street address is required").label("Address"),
  garrageStreetAddress: yup.string().trim().required("Garrage street address is required").label("Mailing address"),
  garragingEffectiveDate: yup
    .date()
    .typeError("Effective date is required")
    .test("is-future-date", "Date must be today or in the future", (value) => {
      if (!value) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate >= currentDate;
    }),
  dob: yup
    .date()
    .typeError("Date of birth required")
    .max(new Date(Date.now() - 86400000), "Date of birth can not be future or current date")
    .required("Date of birth required")
    .test("is-age-15-or-older", "User age must be 15 or older", (value) => {
      if (!value) {
        return true;
      }
      const birthYear = value.getFullYear();
      const currentYear = new Date().getFullYear();
      return currentYear - birthYear >= 15;
    })
    .label("Date of Birth"),
});
const Step1 = ({ stepHandler, agencyId, agentId, loader }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
    control,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      garragingEffectiveDate: "",
      workPhone: "",
      apt: "",
      garrageStreetAddress: "",
      garageCity: "",
      garageZipcode: "",
      garageState: "",
      cell: "",
      email: "",
      dob: "",
    },
    resolver: yupResolver(validationSchema),
  });
  const handleExitButton = useExitConfirmationHandler();
  const [isLoading, setLoading] = useState(false);
  const [addesss, setAddress] = useState("");
  const [garageAddress, setGarageAddress] = useState("");
  const [sameAsAddress, setSameAsAddress] = useState(false);
  const navigate = useNavigate();
  const [errors_, setErrors_] = useState({});
  const queryParams = new URLSearchParams(window.location.search);
  // const agencyId = queryParams.get("id");
  const primaryId = queryParams.get("primaryid");
  const verifyId = queryParams.get("verifyid");
  const cell = watch("cell");
  const workPhone = watch("workPhone");
  const mobile = queryParams.get("mobile");
  const emailAddress = queryParams.get("emailAddress");
  const zip = queryParams.get("zip");
  const income = queryParams.get("income");
  const dob = queryParams.get("dob");
  const gender = queryParams.get("gender");
  const name = queryParams.get("name");

  const formattedDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const setStep1ToLocalStorage = (data) => {
    const updatedData = {
      ...data,
      agencyId,
      agentId,
    };
    localStorage.setItem("step1", JSON.stringify(updatedData));
  };

  const setStep2ToLocalStorage = (data) => {
    localStorage.setItem("step2", JSON.stringify(data));
  };

  const fillupStep1FromLocalStorage = (formData) => {
    setValue("firstName", formData.firstName);
    setValue("lastName", formData.lastName);
    setValue("gender", formData.gender);
    setValue("apt", formData.apt);
    setValue("garrageStreetAddress", formData.garrageStreetAddress);
    setValue("phone", formData.phone);
    setValue("workPhone", formData.workPhone);
    setValue("cell", formData.cell);
    setValue("email", formData.email);
    setValue("dob", formattedDate(formData.dob));
    setValue("garragingEffectiveDate", formattedDate(formData.garragingEffectiveDate));
    setValue("garageCity", formData.garageCity);
    setValue("garageZipcode", formData.garageZipcode);
    setValue("garageState", formData.garageState);
    setValue("agencyId", agencyId);
    setAddress(formData.apt);
    setGarageAddress(formData.garrageStreetAddress);
    if (formData.garrageStreetAddress === formData.apt) {
      setSameAsAddress(true);
    }
  };

  const isPasscodeActive = (verifyId) => {
    setLoading(true);
    axios
      .get(`${DEVURL_VERIFY}/IsActivePasscode/${verifyId}`)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          setLoading(false);
          if (response.data !== 1) {
            navigate(`/verification?verify=${verifyId}`);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.status === 404) {
          navigate(`/verification?verify=${verifyId}`);
        }
      });
  };

  const setInitialData = () => {
    const step1Data = JSON.parse(localStorage.getItem("step1"));
    if (step1Data) {
      fillupStep1FromLocalStorage(step1Data);
    }
  };

  const setAutoInfo = (autoinfo) => {
    // console.log(autoinfo);
    let step1 = {
      agencyId: agencyId,
     
      dob: autoinfo?.dob,
      garragingEffectiveDate: autoinfo?.garragingEffectiveDate,
      garrageStreetAddress: autoinfo?.garrageStreetAddress,
      email: autoinfo?.email,
      workPhone: autoinfo?.workPhone,
      cell: autoinfo?.cell,
      apt: autoinfo?.apt,
      lastName: autoinfo?.lastName,
      firstName: autoinfo?.firstName,
      city: autoinfo?.city,
      state: autoinfo?.state,
      zipCode: autoinfo?.zip,
      garageCity: autoinfo?.garageCity,
      garageState: autoinfo?.garageState,
      garageZipcode: autoinfo?.garageZipcode,
    };
    setStep1ToLocalStorage(step1);
    let step2 = {
      agentId: agentId == null ? autoinfo?.agentId : agentId,
      terms: false,
      carrierDuration: autoinfo?.carrierDuration,
      insurancePremium: autoinfo?.insurancePremium,
      renewalDate: autoinfo?.renewalDate,
      carrierName: autoinfo?.carrierName,
      drivers: autoinfo?.drivers,
      vehicles: autoinfo?.vehicles,
    };
    setStep2ToLocalStorage(step2);
    setInitialData();
  };
  const getAutoFormInfoById = (id) => {
    const step1Data = JSON.parse(localStorage.getItem("step1"));
    if (step1Data) {
      return false;
    }
    setLoading(true);
    axios
      .get(`${DEVURL}GetAutoByCustomerId/${id}`)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setAutoInfo(res.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (verifyId) {
      isPasscodeActive(verifyId);
    }
    if (primaryId) {
      getAutoFormInfoById(primaryId);
    }
    setInitialData();
  }, []);

  const handleFormSubmit = async (data) => {
    // let is_validate = 1;
    // if (data?.apt?.length <= 0) {
    //   setErrors_({ ...errors_, apt: "Address is required field" });
    //   is_validate = 0;
    // }
    // if (data?.garrageStreetAddress?.length <= 0) {
    //   setErrors_({
    //     ...errors_,
    //     garageAddress: "Garage Address is required field",
    //   });
    //   is_validate = 0;
    // }
    // if (is_validate === 0) {
    //   return false;
    // }
    // console.log(data);
    setStep1ToLocalStorage(data);
    stepHandler(2);
  };

  const getLocation = async (data) => {
    let address_components = data?.address_components;
    let formatted_address = data?.formatted_address;
    setValue("apt", formatted_address);

    setAddress(formatted_address);
    formatted_address = formatted_address.split(",");
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        zip_code = data.long_name + zip_code;
      }
      // if (data.types.includes("postal_code_suffix")) {
      //     zip_code =  zip_code +'-'+ data.long_name;
      // }
      if (data.types.includes("locality")) {
        setValue("city", data.long_name);
      }
      if (data.types.includes("administrative_area_level_1")) {
        setValue("state", data.long_name);
      }
    });
    setValue("zipCode", zip_code);
  };

  const getGarageAddress = async (data) => {
    console.log("data", data);
    let address_components = data?.address_components;
    let formatted_address = data?.formatted_address;
    setValue("garrageStreetAddress", formatted_address);
    setAddress(formatted_address);
    formatted_address = formatted_address.split(",");
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        zip_code = data.long_name + zip_code;
      }
      if (data.types.includes("locality")) {
        setValue("garageCity", data.long_name);
      }
      if (data.types.includes("administrative_area_level_1")) {
        setValue("garageState", data.long_name);
      }
    });
    setValue("garageZipcode", zip_code);
  };

  if (isLoading) {
    return loader();
  }
  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)} className="tab-wizard wizard-circle wizard needs-validation">
        <section>
          <h4 className="wrap-title mb-3">Personal Information</h4>

          <div className="row">
            <div className="col-md-4">
              <div className="mb-3">
                <label className="brand-label">
                  First Name<span className="required-mark">*</span>
                </label>
                <input id="firstName" type="text" className="form-control" placeholder="Enter First Name" {...register("firstName")} />
                <p className="text-danger mt-2">{errors.firstName && <p>{errors.firstName.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-3">
                <label className="brand-label">
                  Last Name<span className="required-mark">*</span>
                </label>
                <input id="lastName" type="text" className="form-control" placeholder="Enter Last Name" {...register("lastName")} />
                <p className="text-danger mt-2">{errors.lastName && <p>{errors.lastName.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-4 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  Email Address<span className="required-mark">*</span>
                </label>
                <input id="email" type="email" className="form-control" placeholder="Enter Email Address" {...register("email")} />
                <p className="text-danger mt-2">{errors.email && <p>{errors.email.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-4 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  Mobile Phone Number<span className="required-mark">*</span>
                </label>
                <input id="cellPhone" type="text" className="form-control" placeholder="(XXX) XXX-XXXX" value={formatTelNumber(cell)} {...register("cell")} />
                <p className="text-danger mt-2">{errors.cell && <p>{errors.cell.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-4 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">Work Phone Number</label>
                <input id="workPhone" type="text" className="form-control" placeholder="(XXX) XXX-XXXX" value={formatTelNumber(workPhone)} {...register("workPhone")} />
                <p className="text-danger mt-2">{errors.workPhone && <p>{errors.workPhone.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-4 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  Date of Birth<span className="required-mark">*</span>
                </label>
                <input id="birthday" type="date" max="2099-12-31" className="form-control" placeholder="Enter Enrollment Date" {...register("dob")} />
                <p className="text-danger mt-2">{errors.dob && <p>{errors.dob.message}</p>}</p>
              </div>
            </div>
            {/* Garrage Address  */}
            <div className="col-md-4 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  Street Address<span className="required-mark">*</span>
                </label>
                <PlaceComponent
                  getLocationAddress={getLocation}
                  control={control}
                  name="apt"
                  value={addesss}
                  onChange={(e) => {
                    setAddress(e.target.value);
                    setValue("apt", e.target.value);
                    setValue("city", null);
                    setValue("state", null);
                    setValue("zipCode", null);
                  }}
                  type="profile-update"
                ></PlaceComponent>
                <p className="text-danger mt-2">
                  <p>{errors?.apt?.message}</p>
                </p>
              </div>
            </div>

            <div className="col-md-5 col-lg-5">
              <div className="mb-3">
                <label className="brand-label" style={{ display: "flex" }}>
                  Garage Street Address <span className="required-mark me-2">*</span>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={sameAsAddress}
                      onChange={(e) => {
                        setSameAsAddress(e.target.checked);
                        setValue("garrageStreetAddress", addesss);
                        setGarageAddress(addesss);
                        setValue("garageZipcode", getValues("zipCode"));
                        setValue("garageState", getValues("state"));
                        setValue("garageCity", getValues("city"));
                      }}
                      id="same-as"
                    />
                    <label className="form-check-label text-capitalize" htmlFor="flexCheckDefault">
                      Same as Address
                    </label>
                  </div>
                </label>
                <PlaceComponent
                  getLocationAddress={getGarageAddress}
                  control={control}
                  name="garrageStreetAddress"
                  value={garageAddress}
                  onChange={(e) => {
                    setGarageAddress(e.target.value);
                    setValue("garrageStreetAddress", e.target.value);
                    setValue("garageCity", null);
                    setValue("garageState", null);
                    setValue("garageZipcode", null);
                  }}
                  type="profile-update"
                  readOnly={sameAsAddress}
                ></PlaceComponent>
                <p className="text-danger mt-2">
                  <p>{errors?.garrageStreetAddress?.message}</p>
                </p>
              </div>
            </div>

            <div className="col-md-3 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">
                  Effective Date<span className="required-mark">*</span>
                </label>
                <input
                  id="garraging_effective_date"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Garraging Effective Date"
                  {...register("garragingEffectiveDate")}
                />
                <p className="text-danger mt-2">{errors.garragingEffectiveDate && <p>{errors.garragingEffectiveDate.message}</p>}</p>
              </div>
            </div>
          </div>
          <div className="text-end">
            {primaryId && (
              <button type="button" onClick={() => handleExitButton("Auto Insurance Application")} className="btn btn-danger ms-2 text-capitalize">
                Exit if no changes required
              </button>
            )}
            <button className="btn btn-primary ms-2">Next</button>
          </div>
        </section>
      </form>
    </>
  );
};

export default Step1;
