import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import * as yup from "yup";
import { floatRegex } from "../../Common/regex";
import axiosInstance from "../../config/axios-config";
import useExitConfirmationHandler from "../../hooks/useExitConfirmationHandler.hook";
import axios from "axios";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/GeneralStore";
const DEVURL_MANAGEAGENCY = process.env.REACT_APP_ANC_API + "/api/manageagency";

const getValidationSchema = (agentId) => {
  let baseSchema = {
    sqftspace: yup.string().trim().required("Sq. ft. is required").label("Sq. ft."),
    salesAmt: yup.string().trim().required("Sales is required").label("Sales"),
    isTenant: yup.string().trim().required("Tenant is required").label("Tenant"),
    tenantInfo: yup
      .string()
      .when("isTenant", (isTenant, schema) => {
        if (isTenant && isTenant?.includes("1")) {
          return schema.required("Tenant info is required");
        } else return schema.notRequired();
      })
      .label("Tenant info"),
    moneyLimit: yup
      .string()
      .trim()
      // .required("Money & securities limit is required")
      .nullable(true)
      .matches(floatRegex, "Please enter valid money & securities limit")
      .label("Money & securities limit"),
    signValue: yup.string().trim().required("Sign value is required").label("Sign value"),

    protectiveGaurd: yup.array().min(1, "Protective safeguard is required").of(yup.string()).required("Protective safeguard is required").label("Protective safeguard"),
    storeType: yup.array().min(1, "Store type is required").of(yup.string()).required("Store type is required").label("Store type"),

    bppValue: yup.string().trim().required("Bpp value is required").label("Bpp value"),
    additionalInsured: yup
      .string()
      // .required("Additional insured is required")
      .label("Additional insured"),
    anyClaim: yup.string().required("Claim is required").label("Claim"),
    // claimDate: yup
    //   .string()
    //   .nullable(true)
    //   .typeError("Please enter valid date")
    //   .when("anyClaim", (anyClaim, schema) => {
    //     if (anyClaim && anyClaim.includes("1")) {
    //       return schema.required("Claim date is required");
    //     } else return schema.notRequired();
    //   })
    //   .test("is-past-date", "Date must be a past date", (value) => {
    //     if (!value || isNaN(Date.parse(value))) {
    //       return true;
    //     }
    //     const currentDate = new Date();
    //     currentDate.setHours(0, 0, 0, 0);

    //     const inputDate = new Date(value);
    //     inputDate.setHours(0, 0, 0, 0);

    //     return inputDate < currentDate;
    //   })
    //   .label("Claim date"),
    claimType: yup
      .string()
      .when("anyClaim", (anyClaim, schema) => {
        if (anyClaim && anyClaim?.includes("1")) {
          return schema.required("Claim type is required");
        } else return schema.notRequired();
      })
      .label("Claim type"),

    protectiveGaurdOtherValue: yup
      .string()
      .test("isRequired", "Protective safeguard is required", function (value) {
        const protectiveGaurd = this.parent.protectiveGaurd;
        return protectiveGaurd && protectiveGaurd.includes("others") ? !!value : true;
      })
      .label("Others type"),
    storeTypeOtherValue: yup
      .string()
      .test("isRequired", "Store type is required", function (value) {
        const storeType = this.parent.storeType;
        return storeType && storeType.includes("others") ? !!value : true;
      })
      .label("Others type"),
    termsCondition: yup.boolean().test("termsCondition", "Terms & Conditions is required", (val) => {
      return val;
    }),
  };
  if (agentId == null) {
    baseSchema = {
      ...baseSchema,
      agentId: yup.string().required("Selecting an agent is required.").notOneOf(["", null, undefined], "You must select a valid agent."),
    };
  }
  return yup.object().shape(baseSchema);
};

const Step2 = ({ stepHandler, loader, agentId }) => {
  const handleExitButton = useExitConfirmationHandler();
  const { agencyName, agentName } = useParams();
  const validationSchema = getValidationSchema(agentId);
  const [agentList, setAgentList] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    control,
  } = useForm({
    defaultValues: {
      salesAmt: null,
      sqftspace: null,
      termsCondition: false,
      tenantInfo: "",
      bppValue: null,
      moneyLimit: "",
      protectiveGaurd: [],
      buildingValue: null,
      additionalInsured: "",
      storeType: [],
      // claimDate: null,
      anyClaim: "",
      claimType: "",
      signValue: null,
      franchiseInfo: "",
      storeTypeOtherValue: "",
      isTenant: "",
      protectiveGaurdOtherValue: "",
    },
    resolver: yupResolver(validationSchema),
  });
  const claimsValue = watch("anyClaim");
  const isTenantValue = watch("isTenant");
  const storeTypeValue = watch("storeType");
  const protectiveGaurdValue = watch("protectiveGaurd");
  const queryParams = new URLSearchParams(window.location.search);
  const agencyId = queryParams.get("id");
  // const [show, setShow] = useState(false);
  // const target = useRef(null);

  // useEffect(() => {
  //   setValue("agentId", getValues("agentId"));

  // }, [getValues("agentId")]);
  //
  const getAgentName = () => {
    if (agentList?.length) {
      const agent = agentList?.find((agent) => agent.id === getValues("agentId"));
      console.log("agent", agent, getValues("agentId"));
      return `${agent?.firstName} ${agent?.lastName}`;
    }
  };
  const fillupStep2HotelInfoFromLocalStorage = (formData) => {
    setValue("agentId", agentId == null ? formData?.agentId : agentId);
    setValue("storeType", formData.storeType);
    setValue("storeTypeOtherValue", formData.storeTypeOtherValue);
    setValue("protectiveGaurd", formData.protectiveGaurd);
    setValue("protectiveGaurdOtherValue", formData.protectiveGaurdOtherValue);
    setValue("salesAmt", formData.salesAmt);
    setValue("sqftspace", formData.sqftspace);
    setValue("isTenant", formData.isTenant);
    setValue("tenantInfo", formData.tenantInfo);
    setValue("bppValue", formData.bppValue);
    setValue("moneyLimit", formData.moneyLimit);
    setValue("buildingValue", formData.buildingValue);
    setValue("additionalInsured", formData.additionalInsured);
    setValue("anyClaim", formData.anyClaim);
    // setValue("claimDate", formData.claimDate);
    setValue("claimType", formData.claimType);
    setValue("signValue", formData.signValue);
    setValue("termsCondition", formData?.termsCondition);
    setValue("franchiseInfo", formData.franchiseInfo);
  };

  useEffect(() => {
    if (claimsValue?.includes("0")) {
      // setValue("claimDate", null);
      setValue("claimType", "");
    }
    if (!storeTypeValue?.includes("others")) {
      setValue("storeTypeOtherValue", "");
    }
    if (!protectiveGaurdValue?.includes("others")) {
      setValue("protectiveGaurdOtherValue", "");
    }
    if (isTenantValue?.includes("0")) {
      setValue("tenantInfo", "");
    }
  }, [claimsValue, isTenantValue, storeTypeValue, protectiveGaurdValue]);

  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);
  const [disableAdd, setDisableAdd] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const salonId = query.get("primaryid");
  const navigate = useNavigate();

  const clearLocalStorage = () => {
    localStorage.removeItem("step1");
    localStorage.removeItem("step2");
    localStorage.removeItem("customerId");
  };

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("step2"));
    if (formData) {
      fillupStep2HotelInfoFromLocalStorage(formData);
    }
  }, []);

  const onChangeRecaptcha = async (value) => {
    if (value === null) {
      setIsCaptchaSuccess(false);
    } else {
      setIsCaptchaSuccess(true);
    }
  };

  const creategeneralStoreInfoObj = (generalStoreInfo) => {
    console.log(generalStoreInfo);
    let salonToCreate = {
      customerInfo: {
        agencyId: generalStoreInfo.agencyId,
        agentId: generalStoreInfo.agentId,
        customerId: generalStoreInfo.customerId,
        firstName: generalStoreInfo.firstName,
        lastName: generalStoreInfo.lastName,
        locationAddress: generalStoreInfo.locationAddress,
        locationCity: generalStoreInfo.locationCity,
        locationZip: generalStoreInfo.locationZip,
        locationState: generalStoreInfo.locationState,
        mailingAddress: generalStoreInfo.mailingAddress,
        mailingCity: generalStoreInfo.mailingCity,
        mailingZip: generalStoreInfo.mailingZip,
        mailingState: generalStoreInfo.mailingState,
        businessName: generalStoreInfo.businessName,
        dbaname: generalStoreInfo.dbaname,
        hoursOfOperation: generalStoreInfo.hoursOfOperation,
        mobileNum: generalStoreInfo.mobileNum,
        workNum: generalStoreInfo.workNum,
        email: generalStoreInfo.email,
        typeOfBusiness: [],
        faxNum: "",
        termsCondition: generalStoreInfo.termsCondition?.toString(),
      },
      generalStoreInfo: {
        storeId: generalStoreInfo.storeId,
        customerId: generalStoreInfo.customerId,
        salesAmt: generalStoreInfo.salesAmt,
        coverage: generalStoreInfo.coverage,
        coverageOtherValue: generalStoreInfo.coverageOtherValue,
        sqftspace: generalStoreInfo.sqftspace,
        isTenant: generalStoreInfo.isTenant,
        tenantInfo: generalStoreInfo.tenantInfo,
        bppValue: generalStoreInfo.bppValue,
        moneyLimit: generalStoreInfo.moneyLimit,
        protectiveGaurd: generalStoreInfo.protectiveGaurd,
        buildingValue: generalStoreInfo.buildingValue,
        signValue: generalStoreInfo.signValue,
        storeType: generalStoreInfo.storeType,
        additionalInsured: generalStoreInfo.additionalInsured,
        anyClaim: generalStoreInfo.anyClaim,
        claimType: generalStoreInfo.claimType,
        franchiseInfo: generalStoreInfo.franchiseInfo,
        effectiveDate: generalStoreInfo.effectiveDate,
        hoursOfOperation: generalStoreInfo.hoursOfOperation,
        protectiveGaurdOtherValue: generalStoreInfo.protectiveGaurdOtherValue,
        storeTypeOtherValue: generalStoreInfo.storeTypeOtherValue,
        typeOfBusiness: [],
        termsCondition: generalStoreInfo.termsCondition?.toString(),
      },
    };
    return salonToCreate;
  };
  const handleFormSubmit = async (data) => {
    localStorage.setItem("step2", JSON.stringify(data));
    const customerId = localStorage.getItem("customerId");
    const step1 = localStorage.getItem("step1");
    const step2 = localStorage.getItem("step2");
    const generalStoreInfo = {
      ...JSON.parse(step1),
      ...JSON.parse(step2),
    };

    const generalStoreInfoObj = creategeneralStoreInfoObj(generalStoreInfo);

    Swal.fire({
      title: "Are you sure you want to submit the form?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        if (!salonId) {
          setLoading(true);
          axiosInstance
            .post(`${DEVURL}/InsertGeneralStoreApp`, generalStoreInfoObj)
            .then((res) => {
              if (res.status === 200) {
                setLoading(false);
                clearLocalStorage();
                reset();
                stepHandler(3);
              }
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        } else {
          setLoading(true);
          axiosInstance
            .put(`${DEVURL}/UpdateGeneralStoreApp/${customerId}/${salonId}`, generalStoreInfoObj)
            .then((res) => {
              if (res.status === 200) {
                setLoading(false);
                clearLocalStorage();
                reset();
                stepHandler(3);
              }
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      } else {
        setLoading(false);
        return;
      }
    });
  };

  const getAgentList = async () => {
    await axios
      .get(`${DEVURL_MANAGEAGENCY}/GetAgentInfoByAgency/${agencyName}`)
      .then((response) => {
        if (response.status === 200) {
          setAgentList(response.data.agent_info_list);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (agencyName) getAgentList();
  }, [agencyName]);

  if (isLoading) {
    return loader();
  }

  return (
    <>
      <form className="tab-wizard wizard-circle wizard needs-validation" onSubmit={handleSubmit(handleFormSubmit)}>
        <section>
          <h4 className="wrap-title mb-3">Property Information:</h4>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Sq. Ft.?<span className="required-mark">*</span>
                </label>

                <Controller
                  name="sqftspace"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="sqftspace"
                      className="form-control"
                      thousandSeparator={true}
                      name="sqftspace"
                      onValueChange={(values) => {
                        setValue("sqftspace", values.floatValue);
                      }}
                      placeholder="Enter Sq. Ft."
                      {...register("sqftspace")}
                    />
                  )}
                />
                <p className="text-danger">{errors.sqftspace?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">
                  Gross Sales/ Rent Roll?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="salesAmt"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="salesAmt"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="salesAmt"
                      placeholder="Enter Sales"
                      onValueChange={(values) => {
                        setValue("salesAmt", values.floatValue);
                      }}
                      {...register("salesAmt")}
                    />
                  )}
                />
                <p className="text-danger">{errors.salesAmt && <p>{errors.salesAmt.message}</p>}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">
                  BPP/Content Value?
                  <span className="required-mark">*</span>
                </label>
                <Controller
                  name="bppValue"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="bppValue"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="bppValue"
                      onValueChange={(values) => {
                        setValue("bppValue", values.floatValue);
                      }}
                      placeholder="Enter Bpp Value"
                      {...register("bppValue")}
                    />
                  )}
                />

                <p className="text-danger">{errors.bppValue && <p>{errors.bppValue.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label d-grid">
                  <div>
                    Money & Securities Limit?
                    {/* <span className="required-mark">*</span> */}
                  </div>
                </label>
                <Controller
                  name="moneyLimit"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="moneyLimit"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="moneyLimit"
                      onValueChange={(values) => {
                        setValue("moneyLimit", values.floatValue);
                      }}
                      placeholder="Enter Money & Securities Limit"
                      {...register("moneyLimit")}
                    />
                  )}
                />
                <p className="text-danger">{errors.moneyLimit?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">
                  If Owner, Building Value?
                  {/* <span className="required-mark">*</span> */}
                </label>
                <Controller
                  name="buildingValue"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="buildingValue"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="buildingValue"
                      onValueChange={(values) => {
                        setValue("buildingValue", values.floatValue);
                      }}
                      placeholder="Enter Building Value"
                      {...register("buildingValue")}
                    />
                  )}
                />

                <p className="text-danger">{errors.buildingValue && <p>{errors.buildingValue.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">Franchisor Name?</label>
                <input className="form-control" type="text" placeholder="Enter Franchisor Name" {...register("franchiseInfo")} />
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label">
                  Sign Value?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="signValue"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="signValue"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="signValue"
                      onValueChange={(values) => {
                        setValue("signValue", values.floatValue);
                      }}
                      placeholder="Enter Sign Value"
                      {...register("signValue")}
                    />
                  )}
                />
                <p className="text-danger">{errors.signValue && <p>{errors.signValue.message}</p>}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">
                  <div>
                    Any Additional Insured?
                    {/* <span className="required-mark">*</span> */}
                  </div>
                </label>
                <input className="form-control" type="text" placeholder="Enter Additional Insured" {...register("additionalInsured")} />

                <p className="text-danger">{errors.additionalInsured && <p>{errors.additionalInsured.message}</p>}</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                    Tenant?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="tenantYes" value="1" {...register("isTenant")} />
                  <label class="form-check-label" for="tenantYes">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="tenantNo" value="0" {...register("isTenant")} />
                  <label class="form-check-label" for="tenantNo">
                    No
                  </label>
                </div>
                <p className="text-danger">{errors.isTenant && <p>{errors.isTenant.message}</p>}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">Tenant Info?</label>
                <input className="form-control" type="text" disabled={!isTenantValue?.includes("1")} placeholder="Enter Tenant Info" {...register("tenantInfo")} />
                <p className="text-danger">{errors.tenantInfo?.message}</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                    Any Claim in past 5 years?
                    <span className="required-mark">*</span>
                  </div>
                </label>

                <div
                  class="form-check form-check-inline"
                  // ref={target}
                  // onClick={() => setShow(!show)}
                >
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="claimYes" value="1" {...register("anyClaim")} />
                  <label class="form-check-label" for="claimYes">
                    Yes
                  </label>
                </div>
                <div
                  class="form-check form-check-inline"
                  // onClick={() => setShow(false)}
                >
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="claimNo" value="0" {...register("anyClaim")} />
                  <label class="form-check-label" for="claimNo">
                    No
                  </label>
                </div>
                {watch("anyClaim")?.includes("1") && <span class="badge text-bg-info">Loss run is mandatory when there is a claim</span>}
                <p className="text-danger">{errors.anyClaim && <p>{errors.anyClaim.message}</p>}</p>
                {/* <div>
                  <Overlay target={target.current} show={show} placement="top">
                    {(props) => (
                      <Tooltip id="overlay-example" {...props}>
                        Loss runs are required
                      </Tooltip>
                    )}
                  </Overlay>
                </div> */}
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>Claim Type?</div>
                </label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="propertyCheck"
                    value="Property"
                    disabled={!claimsValue?.includes("1")}
                    {...register("claimType")}
                  />
                  <label class="form-check-label" for="propertyCheck">
                    Property
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="GLICheck"
                    value="GL"
                    disabled={!claimsValue?.includes("1")}
                    {...register("claimType")}
                  />
                  <label class="form-check-label" for="GLICheck">
                    GL
                  </label>
                </div>
                <p className="text-danger">{errors.claimType && <p>{errors.claimType.message}</p>}</p>
              </div>
            </div>
            {/* <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label text-capitalize">
                  Claim Date?
                </label>
                <input
                  id="claimDate"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Claim Date"
                  disabled={!claimsValue?.includes("1")}
                  {...register("claimDate")}
                />
                <p className="text-danger">
                  {errors.claimDate && <p>{errors.claimDate.message}</p>}
                </p>
              </div>
            </div> */}

            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label className="brand-label">Protective Safeguard?</label>
                <span className="required-mark">*</span>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="cctvCheck" value="CCTV Camera" {...register("protectiveGaurd")} />
                      <label className="form-check-label text-capitalize" for="cctvCheck">
                        CCTV Camera
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="fireAlarmCheck" value="Fire Alarm" {...register("protectiveGaurd")} />
                      <label className="form-check-label text-capitalize" for="fireAlarmCheck">
                        Fire Alarm
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="burglarAlarmCheck" value="Burglar Alarm" {...register("protectiveGaurd")} />
                      <label className="form-check-label text-capitalize" for="burglarAlarmCheck">
                        Burglar Alarm
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="protectiveGaurdCheck" value="others" {...register("protectiveGaurd")} />
                      <label className="form-check-label text-capitalize" for="protectiveGaurdCheck">
                        Others
                      </label>
                    </div>
                    <div>
                      {watch("protectiveGaurd")?.includes("others") && (
                        <>
                          <input
                            className="form-control"
                            type="text"
                            id="protectiveGaurdOtherValue"
                            name="protectiveGaurdOtherValue"
                            placeholder="Enter Protective Safeguard"
                            {...register("protectiveGaurdOtherValue")}
                          />

                          <p className="text-danger">{errors?.protectiveGaurdOtherValue?.message}</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <p className="text-danger">{errors.protectiveGaurd?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label className="brand-label">Store Type?</label>
                <span className="required-mark">*</span>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="repairStoreCheck" value="Repair Store" {...register("storeType")} />
                      <label className="form-check-label text-capitalize" for="repairStoreCheck">
                        Repair Store
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="installationCheck" value="Installation" {...register("storeType")} />
                      <label className="form-check-label text-capitalize" for="installationCheck">
                        Installation
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="accessoriesCheck" value="Accessories" {...register("storeType")} />
                      <label className="form-check-label text-capitalize" for="accessoriesCheck">
                        Accessories
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="cellphoneCheck" value="Cell Phone Store" {...register("storeType")} />
                      <label className="form-check-label text-capitalize" for="cellphoneCheck">
                        Cell Phone Store
                      </label>
                    </div>

                    <div className="form-check ">
                      <label className="form-check-label text-capitalize" htmlFor="othersStoreTypeCheck">
                        Others
                      </label>
                      <input className="form-check-input" type="checkbox" id="othersStoreTypeCheck" value="others" {...register("storeType")} />
                    </div>

                    <div>
                      {watch("storeType")?.includes("others") && (
                        <>
                          <input
                            className="form-control"
                            type="text"
                            id="storeTypeOtherValue"
                            name="storeTypeOtherValue"
                            placeholder="Enter Store Type"
                            {...register("storeTypeOtherValue")}
                          />

                          <p className="text-danger">{errors?.storeTypeOtherValue?.message}</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <p className="text-danger">{errors.storeType?.message}</p>
              </div>
            </div>
            {agentId == null ? (
              <div className="row">
                <div className="col-md-6 col-lg-3">
                  {salonId ? (
                    <label className="brand-label">
                      Selected Agent: &nbsp;
                      <span>{getAgentName()}</span>
                    </label>
                  ) : (
                    <div className="mb-3">
                      <label className="brand-label">
                        Select your contact person
                        <span className="required-mark">*</span>
                      </label>
                      <select class="form-select" aria-label="Default select example" {...register("agentId")}>
                        <option value={""} selected>
                          Select Agent
                        </option>
                        {agentList?.length ? (
                          agentList.map((agent) => (
                            <option value={agent.id}>
                              {agent?.firstName} {agent?.lastName}
                            </option>
                          ))
                        ) : (
                          <option disabled>No agent found</option>
                        )}
                      </select>
                      <p className="text-danger mt-2">{errors.agentId && <p>{errors.agentId.message}</p>}</p>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
            <div className="text-center mb-3">
              {salonId && (
                <button type="button" onClick={() => handleExitButton("General Business Application")} className="btn btn-danger ms-2 text-capitalize">
                  Exit if no changes required
                </button>
              )}
            </div>
            <div className="col-md-12 col-lg-12 mt-1">
              <div className="mb-3">
                <div className="d-flex align-items-baseline gap-10">
                  <input className="me-2" type="checkbox" id="termsCondition" {...register("termsCondition")} />

                  <label className=" brand-label ">
                    I understand loss runs are required to receive a quote and I hereby allow the agent to retrieve information using the above-mentioned business information and
                    provide me with quote(s) for my perusal. I also understand that quotes are for information and marketing purpose only.
                  </label>
                </div>

                <p className="text-danger mt-2">{errors.termsCondition && <p>{errors.termsCondition.message}</p>}</p>
                <div className="col-md-6">
                  <div className="mb-3">
                    <ReCAPTCHA sitekey="6LeS4L8mAAAAAEGzyClAMce6777ssfu5VYovTkaW" onChange={onChangeRecaptcha} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button className="btn btn-warning" type="button" onClick={() => stepHandler(1)}>
              Previous
            </button>
            <button type="submit" className="btn btn-primary ms-2" disabled={disableAdd || !isCaptchaSuccessful}>
              Submit
            </button>
          </div>
        </section>
      </form>
    </>
  );
};

export default Step2;
