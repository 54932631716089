import React from 'react';
import  Bodysection  from './Bodysection';
import Header from './Header';
import Footer  from "./ancfooter";
const Home = ()=>{

return (
    <React.Fragment>
    <Header/>
    <Bodysection/>
    <Footer/>
    </React.Fragment>

)
}

export default Home
