import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import * as yup from "yup";
import { default as axios } from "../../../config/axios-config";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/Restaurant";

const validationSchema = yup.object().shape({
  glFein: yup
    .string()
    .trim()
    .nullable(true)
    .matches(/^\d{2}-\d{6}$/, "Please enter a valid fein")
    .label("FEIN"),
  glCookingType: yup
    .array()
    .min(1, "Type of cooking is required")
    .of(yup.string())
    .required("Type of cooking is required")
    .label("Type of cooking"),
  glSeatingCapacity: yup
    .string()
    .required("Seating capacity is required")
    .label("Seating capacity"),
  glLiquour: yup
    .string()
    .required("Serving liquor is required")
    .label("Serving liquor"),
  glHappyHours: yup
    .string()
    .required("Happy hours is required")
    .label("Happy hours"),
  glHoodAndDuck: yup
    .string()
    .required("Hood & duct system in kitchen is required")
    .label("Hood & duct system in kitchen"),
  glOpenFireCooking: yup
    .array()
    .min(1, "Please select at least 1 item")
    .of(yup.string())
    .required("Open fire cooking is required")
    .label("Open fire cooking"),

  glLiquorLimit: yup.string().when("glLiquour", (glLiquour, schema) => {
    if (glLiquour && glLiquour.includes("1")) {
      return schema.required("Liquor liabilities limit is required");
    } else return schema.notRequired();
  }),

  glAnnualFoodSale: yup
    .string()
    .trim()
    .required("Annual food sales is required")
    .label("Annual food sales"),
  glAnnualLiquorSales: yup
    .string()
    .trim()
    .when("glLiquour", (glLiquour, schema) => {
      if (glLiquour && glLiquour.includes("1")) {
        return schema.required("Annual liquor sales is required");
      } else return schema.notRequired();
    })
    .label("Annual liquor sales"),
  glCateringServce: yup
    .string()
    .trim()
    .required("Annual catering service sales is required")
    .label("Annual catering service sales"),
  glIsBanquest: yup
    .string()
    .trim()
    .required("Banquet service is required")
    .label("Banquet service"),
  terms: yup
    .boolean()
    .test("terms", "Terms & conditions is required", (val) => {
      return val;
    }),
});

const Step3 = ({ stepHandler, loader, details }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    watch,
  } = useForm({
    defaultValues: {
      glCookingType: [],
      glSeatingCapacity: null,
      glLiquour: "",
      glHappyHours: "",
      glHoodAndDuck: "",
      glOpenFireCooking: [],
      glLiquorLimit: null,
      glAnnualFoodSale: null,
      glAnnualLiquorSales: null,
      glCateringServce: "",
      glIsBanquest: "",
      glTotalPayRoll: null,
      glFein: null,
      glNoEmp: null,
      glBankDetails: "",
      glPropValueByBank: "",
      terms: false,
    },
    resolver: yupResolver(validationSchema),
  });

  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const restaurantId = query.get("primaryid");
  const agencyId = query.get("id");
  const mode = query.get("mode");
  const isView = mode ? (mode === "view" ? true : false) : "";
  const terms = watch("terms");

  const fillupStep3HotelInfoFromLocalStorage = (formData) => {
    setValue("glCookingType", formData.glCookingType);
    setValue("glSeatingCapacity", formData.glSeatingCapacity);
    setValue("glLiquour", formData.glLiquour);
    setValue("glHappyHours", formData.glHappyHours);
    setValue("glHoodAndDuck", formData.glHoodAndDuck);
    setValue("glOpenFireCooking", formData.glOpenFireCooking);
    setValue("glLiquorLimit", formData.glLiquorLimit);
    setValue("glAnnualFoodSale", formData.glAnnualFoodSale);
    setValue("glAnnualLiquorSales", formData.glAnnualLiquorSales);
    setValue("glCateringServce", formData.glCateringServce);
    setValue("glIsBanquest", formData.glIsBanquest);
    setValue("glTotalPayRoll", formData.glTotalPayRoll);
    setValue("glFein", formData.glFein);
    setValue("glNoEmp", formData.glNoEmp);
    setValue("glBankDetails", formData.glBankDetails);
    setValue("glPropValueByBank", formData.glPropValueByBank);
    setValue("terms", formData?.terms);
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("step1");
    localStorage.removeItem("step2");
    localStorage.removeItem("step3");
  };

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("step3"));
    if (formData) {
      fillupStep3HotelInfoFromLocalStorage(formData);
    }
  }, []);

  const onChangeRecaptcha = async (value) => {
    if (value === null) {
      setIsCaptchaSuccess(false);
    } else {
      setIsCaptchaSuccess(true);
    }
  };

  const createRestaurantInfoObj = (restaurantInfo) => {
    console.log(restaurantInfo);
    let restaurantToCreate = {
      customerInfo: {
        agencyId: restaurantInfo.agencyId,
        agentId: restaurantInfo.agentId,
        customerId: restaurantInfo.customerId,
        firstName: restaurantInfo.firstName,
        lastName: restaurantInfo.lastName,
        locationAddress: restaurantInfo.locationAddress,
        locationCity: restaurantInfo.locationCity,
        locationZip: restaurantInfo.locationZip,
        locationState: restaurantInfo.locationState,
        mailingAddress: restaurantInfo.mailingAddress,
        mailingCity: restaurantInfo.mailingCity,
        mailingZip: restaurantInfo.mailingZip,
        mailingState: restaurantInfo.mailingState,
        businessName: restaurantInfo.businessName,
        dbaname: restaurantInfo.dba,
        hoursOfOperation: restaurantInfo.hoursOfOperation,
        typeOfBusiness: restaurantInfo?.businessType,
        mobileNum: restaurantInfo.mobileNum,
        workNum: restaurantInfo.workNum,
        email: restaurantInfo.email,
        faxNum: "",
        termsCondition: restaurantInfo.terms?.toString(),
      },
      restaurantInfo: {
        restaurantId: restaurantInfo.restaurantId,
        coverage: restaurantInfo.coverage,
        effectiveDate: restaurantInfo.effectiveDate,
        experienceRestaurant: restaurantInfo.experienceRestaurant,
        glAnnualFoodSale: restaurantInfo.glAnnualFoodSale,
        glAnnualLiquorSales: restaurantInfo.glAnnualLiquorSales,
        glBankDetails: restaurantInfo.glBankDetails,
        glCateringServce: restaurantInfo.glCateringServce,
        glCookingType: restaurantInfo.glCookingType,
        glFein: restaurantInfo.glFein,
        glHappyHours: restaurantInfo.glHappyHours,
        glHoodAndDuck: restaurantInfo.glHoodAndDuck,
        glIsBanquest: restaurantInfo.glIsBanquest,
        glLiquorLimit: restaurantInfo.glLiquorLimit,
        glLiquour: restaurantInfo.glLiquour,
        glNoEmp: restaurantInfo?.glNoEmp?.toString(),
        glOpenFireCooking: restaurantInfo.glOpenFireCooking,
        glPropValueByBank: restaurantInfo.glPropValueByBank,
        glSeatingCapacity: restaurantInfo.glSeatingCapacity,
        glTotalPayRoll: restaurantInfo?.glTotalPayRoll?.toString(),
        howManyYearOnSameLocation: restaurantInfo.howManyYearOnSameLocation,
        propertyAnyBar: restaurantInfo.propertyAnyBar,
        propertyAnyBuffet: restaurantInfo.propertyAnyBuffet,
        propertyAnyTable: restaurantInfo.propertyAnyTable,
        propertyAnyUpdate: restaurantInfo.propertyAnyUpdate,
        propertyBpp: restaurantInfo.propertyBpp,
        propertyCatering: restaurantInfo.propertyCatering,
        propertyFoodContainerLimit: restaurantInfo.propertyFoodContainerLimit,
        propertyIfClaim: restaurantInfo.propertyIfClaim,
        propertyLossRun: restaurantInfo.propertyLossRun,
        propertyMoneyLimit: restaurantInfo.propertyMoneyLimit,
        propertyNoofBuilding: restaurantInfo.propertyNoofBuilding,
        propertyPriorLoss: restaurantInfo.propertyPriorLoss,
        propertyProtactive: restaurantInfo.propertyProtactive,
        propertyServiceContract: restaurantInfo.propertyServiceContract,
        propertySignLimit: restaurantInfo.propertySignLimit,
        propertySignedValue: restaurantInfo.propertySignedValue,
        propertySpoilageLimit: restaurantInfo.propertySpoilageLimit,
        propertySqft: restaurantInfo.propertySqft,
        propertyVehicle: restaurantInfo.propertyVehicle,
        propertyYearOfBuil: restaurantInfo.propertyYearOfBuil,
        roofDate: restaurantInfo.roofDate,
        electricalDate: restaurantInfo.electricalDate,
        plumbingDate: restaurantInfo.plumbingDate,
        hvacDate: restaurantInfo.hvacDate,
        claimDate: restaurantInfo.claimDate,
        hoursOfOperation: restaurantInfo.hoursOfOperation,
        businessType: restaurantInfo?.businessType,
      },
    };
    return restaurantToCreate;
  };

  const handleFormSubmit = async (data) => {
    localStorage.setItem("step3", JSON.stringify(data));
    const step1 = localStorage.getItem("step1");
    const step2 = localStorage.getItem("step2");
    const step3 = localStorage.getItem("step3");
    const restaurantInfo = {
      ...JSON.parse(step1),
      ...JSON.parse(step2),
      ...JSON.parse(step3),
    };

    const restaurantInfoObj = createRestaurantInfoObj(restaurantInfo);

    Swal.fire({
      title: "Are you sure you want to submit the form?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios
          .put(
            `${DEVURL}/UpdateRestaurantApp/${restaurantInfoObj.customerInfo.customerId}/${details.restaurantInfo?.restaurantId}`,
            restaurantInfoObj
          )
          .then((res) => {
            if (res.status === 200) {
              setLoading(false);
              clearLocalStorage();
              reset();
              stepHandler(4);
              setTimeout(() => {
                stepHandler(1);
              }, 3000);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      } else {
        setLoading(false);
        return;
      }
    });
  };
  const handleFeinChange = async (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, "");
    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2) + "-" + inputValue.slice(2);
    }
    const name = e.target.name;
    setValue(name, inputValue);
  };

  if (isLoading) {
    return loader();
  }

  return (
    <>
      <form
        className="tab-wizard wizard-circle wizard needs-validation"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <section>
          <h4 className="wrap-title mb-3">General Liability Information</h4>

          <div className="row align-items-end">
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label d-grid">
                  <div>
                    What type of Cooking?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div className="row">
                  <div className="col-6">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="asianCheck"
                        value="Asian"
                        disabled={isView}
                        {...register("glCookingType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="asianCheck"
                      >
                        Asian
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="chineseCheck"
                        value="Chinese"
                        disabled={isView}
                        {...register("glCookingType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="chineseCheck"
                      >
                        Chinese
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="americanCheck"
                        value="American"
                        disabled={isView}
                        {...register("glCookingType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="americanCheck"
                      >
                        American
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="indianCheck"
                        value="Indian"
                        disabled={isView}
                        {...register("glCookingType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="indianCheck"
                      >
                        Indian
                      </label>
                    </div>
                  </div>
                </div>

                <p className="text-danger">{errors.glCookingType?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">
                  Seating Capacity?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="glSeatingCapacity"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="glSeatingCapacity"
                      className="form-control"
                      thousandSeparator={true}
                      name="glSeatingCapacity"
                      onValueChange={(values) => {
                        setValue("glSeatingCapacity", values.floatValue);
                      }}
                      placeholder="Enter Seating Capacity"
                      readOnly={isView}
                      {...register("glSeatingCapacity")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.glSeatingCapacity?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label d-grid">
                  <div>
                    Serving Liquor?<span className="required-mark">*</span>
                  </div>
                </label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="yesLiquorCheck"
                    value="1"
                    disabled={isView}
                    {...register("glLiquour")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="yesLiquorCheck"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="noLiquorCheck"
                    value="0"
                    disabled={isView}
                    {...register("glLiquour")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="noLiquorCheck"
                  >
                    No
                  </label>
                </div>
                <p className="text-danger">{errors.glLiquour?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label d-grid">
                  <div>
                    Happy Hours?<span className="required-mark">*</span>
                  </div>
                </label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="yesHappyHourCheck"
                    value="1"
                    disabled={isView}
                    {...register("glHappyHours")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="yesHappyHourCheck"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="noHappyHourCheck"
                    value="0"
                    disabled={isView}
                    {...register("glHappyHours")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="noHappyHourCheck"
                  >
                    No
                  </label>
                </div>
                <p className="text-danger">{errors.glHappyHours?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label d-grid">
                  <div>
                    Hood & Duct System in Kitchen?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="yesDuctCheck"
                    value="1"
                    disabled={isView}
                    {...register("glHoodAndDuck")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="yesDuctCheck"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="noDuctCheck"
                    value="0"
                    disabled={isView}
                    {...register("glHoodAndDuck")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="noDuctCheck"
                  >
                    No
                  </label>
                </div>
                <p className="text-danger">{errors.glHoodAndDuck?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label d-grid">
                  <div>
                    Any Open Fire Cooking?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="grillingCheck"
                    value="Grilling"
                    disabled={isView}
                    {...register("glOpenFireCooking")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="grillingCheck"
                  >
                    Grilling
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="deepFryerCheck"
                    value="Deep Fryer"
                    disabled={isView}
                    {...register("glOpenFireCooking")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="deepFryerCheck"
                  >
                    Deep Fryer
                  </label>
                </div>
                <p className="text-danger">
                  {errors.glOpenFireCooking?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label d-grid">
                  <div>Liquor Liabilities Limit?</div>
                </label>
                <Controller
                  name="glLiquorLimit"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="glLiquorLimit"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="glLiquorLimit"
                      onValueChange={(values) => {
                        setValue("glLiquorLimit", values.floatValue);
                      }}
                      placeholder="Enter the limit"
                      readOnly={isView}
                      {...register("glLiquorLimit")}
                    />
                  )}
                />
                <p className="text-danger">{errors.glLiquorLimit?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label d-grid">
                  Annual Liquor Sales?
                </label>
                <Controller
                  name="glAnnualLiquorSales"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="glAnnualLiquorSales"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="glAnnualLiquorSales"
                      onValueChange={(values) => {
                        setValue("glAnnualLiquorSales", values.floatValue);
                      }}
                      placeholder="Enter annual liquor sales"
                      readOnly={isView}
                      {...register("glAnnualLiquorSales")}
                    />
                  )}
                />

                <p className="text-danger">
                  {errors.glAnnualLiquorSales?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label d-grid">
                  <div>
                    Annual Food Sales? <span className="required-mark">*</span>
                  </div>
                </label>
                <Controller
                  name="glAnnualFoodSale"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="glAnnualFoodSale"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="glAnnualFoodSale"
                      onValueChange={(values) => {
                        setValue("glAnnualFoodSale", values.floatValue);
                      }}
                      placeholder="Enter annual food sales"
                      readOnly={isView}
                      {...register("glAnnualFoodSale")}
                    />
                  )}
                />

                <p className="text-danger">
                  {errors.glAnnualFoodSale?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label d-grid">
                  <div>
                    Annual Catering Service Sales?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <Controller
                  name="glCateringServce"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="glCateringServce"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="glCateringServce"
                      onValueChange={(values) => {
                        setValue("glCateringServce", values.floatValue);
                      }}
                      placeholder="Enter annual catering service sales"
                      readOnly={isView}
                      {...register("glCateringServce")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.glCateringServce?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label d-grid">
                  <div>
                    Is Banquet Service Available?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="yesBanquetServiceCheck"
                    value="1"
                    disabled={isView}
                    {...register("glIsBanquest")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="yesBanquetServiceCheck"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="noBanquetServiceCheck"
                    value="0"
                    disabled={isView}
                    {...register("glIsBanquest")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="noBanquetServiceCheck"
                  >
                    No
                  </label>
                </div>

                <p className="text-danger"> {errors?.glIsBanquest?.message}</p>
              </div>
            </div>

            <h4 className="wrap-title mb-2">Other</h4>

            <div className="col-md-4">
              <div className="mb-3">
                <label className="brand-label d-grid">Total Payroll</label>
                <Controller
                  name="glTotalPayRoll"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="glTotalPayRoll"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="glTotalPayRoll"
                      onValueChange={(values) => {
                        setValue("glTotalPayRoll", values.floatValue);
                      }}
                      placeholder="Enter total payroll"
                      readOnly={isView}
                      {...register("glTotalPayRoll")}
                    />
                  )}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-3">
                <label className="brand-label d-grid">FEIN Number</label>
                <input
                  class="form-control"
                  type="text"
                  name="glFein"
                  onKeyUp={handleFeinChange}
                  placeholder="Enter FEIN Number"
                  readOnly={isView}
                  {...register("glFein")}
                />
                <p className="text-danger mt-2">
                  {errors.glFein && <p>{errors.glFein.message}</p>}
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-3">
                <label className="brand-label d-grid">No. of Employees</label>
                <Controller
                  name="glNoEmp"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="glNoEmp"
                      className="form-control"
                      thousandSeparator={true}
                      name="glNoEmp"
                      onValueChange={(values) => {
                        setValue("glNoEmp", values.floatValue);
                      }}
                      placeholder="No. of Employees"
                      readOnly={isView}
                      {...register("glNoEmp")}
                    />
                  )}
                />
              </div>
            </div>

            <h6 className="wrap-title mb-2">If Financed from Bank, then:</h6>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">Bank Details</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Bank Details"
                  readOnly={isView}
                  {...register("glBankDetails")}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">
                  What is the Bank Property Value?
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Bank Property Value"
                  readOnly={isView}
                  {...register("glPropValueByBank")}
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-12 mt-1">
              <div className="mb-3">
                <div className="d-flex align-items-baseline gap-10">
                  <input
                    className="me-2"
                    type="checkbox"
                    id="terms"
                    {...register("terms")}
                  />
                  <label className="brand-label ">
                    I understand loss runs are required to receive a quote and I
                    hereby allow the agent to retrieve information using the
                    above-mentioned business information and provide me with
                    quote(s) for my perusal. I also understand that quotes are
                    for information and marketing purpose only.
                  </label>
                </div>

                <p className="text-danger mt-2">
                  {errors.terms && <p>{errors.terms.message}</p>}
                </p>
                <div className="col-md-6">
                  <div className="mb-3">
                    {/*<ReCAPTCHA*/}
                    {/*  sitekey="6LeS4L8mAAAAAEGzyClAMce6777ssfu5VYovTkaW"*/}
                    {/*  onChange={onChangeRecaptcha}*/}
                    {/*/>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="gap-4 text-end">
          <button
            className="btn btn-warning"
            type="button"
            onClick={() => stepHandler(2)}
          >
            Previous
          </button>{" "}
          &nbsp;&nbsp;
          {!isView && (
            <button type="submit" className="btn btn-success" disabled={isView}>
              Submit
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default Step3;
