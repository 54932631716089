import { IconMailFast, IconRefresh } from "@tabler/icons-react";
import { IconMailCog } from "@tabler/icons-react";
import { IconExternalLink } from "@tabler/icons-react";
import { IconClipboard } from "@tabler/icons-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { handleNotifications, showDateFormat } from "../helper/Helper";
import { IconSearch } from "@tabler/icons-react";

const DEVURL = process.env.REACT_APP_ANC_API + "/api";

const SmartCard = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    setValue,
  } = useForm();
  const userData = useSelector((state) => state.auth.user);
  const [showSendMailModal, setShowSendMailModal] = useState(false);
  const [agentList, setAgentList] = useState();
  const [cards, setCards] = useState([]);
  const [loader, setLoader] = useState(null);
  const [nextPage, setNextPage] = useState(0);
  const [previousPage, setPreviousPage] = useState(1);
  const [searchItem, setSearchItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextButtonEnable, setNextButtonEnable] = useState(false);
  const [pervButtonEnable, setPrevButtonEnable] = useState(true);
  const [perPage, setPerPage] = useState(10);

  const handleCloseEmailSendModal = () => {
    setShowSendMailModal(false);
    reset();
  };
  const handleFormSubmit = (formData) => {
    axios
      .post(`${DEVURL}/ControlPanel/CreateCardMapping`, formData)
      .then((res) => {
        if (res.status === 200) {
          getCardList();
          handleNotifications("Successfully assigned!");
        } else {
          handleNotifications("Failed to assign!", "error");
        }
      })
      .catch((error) => {
        handleNotifications("Failed to assign!", "error");
      });
  };

  const getCardList = async (type = null) => {
    setLoader("getCardList");
    let currentP = 1;
    if (type) {
      if (type === "next") {
        currentP = nextPage;
      } else {
        currentP = previousPage <= 1 ? 1 : previousPage;
      }
    }
    let filter = searchItem ?? "";
    if (type === "reset") {
      filter = null;
      setSearchItem(null);
    }
    await axios
      .get(`${DEVURL}/ControlPanel/GetCardData?searchText=${filter}&page=${currentP}&pageSize=${perPage}`)
      .then((response) => {
        if (response.status === 200) {
          setCards(response.data.items);
        }
        setCurrentPage(currentP);
        setNextPage(currentP + 1);
        setPreviousPage(currentP - 1);
        if (response.data.length === 0) {
          setNextButtonEnable(true);
          setPrevButtonEnable(false);
        }
        if (previousPage >= 1) {
          setPrevButtonEnable(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setLoader(null);
  };

  const getAgentList = async () => {
    await axios
      .get(`${DEVURL}/manageagency/GetAgentInfoByAgency/${userData.agencyName}`)
      .then((response) => {
        if (response.status === 200) {
          setAgentList(response.data.agent_info_list);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCardList();
    getAgentList();
  }, []);

  const handleAddClick = () => {
    setLoader("generateCard");
    const data = { CardNo: "" };
    axios
      .post(`${DEVURL}/ControlPanel/CreateCard`, data)
      .then((res) => {
        if (res.status === 200) {
          reset();
          getCardList();
          handleNotifications("Successfully generated new card number!");
        } else {
          handleNotifications("Failed to generate new card!", "error");
        }
        setLoader(null);
      })
      .catch((error) => {
        handleNotifications("Failed to generate new card!", "error");
        setLoader(null);
      });
  };

  return (
    <>
      <div className="col py-3">
        <div className="content-panel">
          <div className="panel-title mb-5 ">
            <h4>Smart Cards</h4>
          </div>

          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="account" role="tabpanel" aria-labelledby="account-tab">
              <div className="filter-box mt-3 mb-2">
                <div className="filter-box-content">
                  <div className="row align-items-center">
                    <div className="col-lg-5">
                      <div className="form-floating">
                        <input
                          type="text"
                          size={500}
                          className="form-control"
                          value={searchItem}
                          onChange={(event) => setSearchItem(event.target.value)}
                          id="floatingInput"
                          placeholder=""
                        />
                        <label htmlFor="floatingInput">Search by card number, agent name, created date</label>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="d-grid d-md-flex justify-content-md-left" style={{ height: "50px" }}>
                        <button className="btn btn-primary" type="button" onClick={() => getCardList()}>
                          <div className="button-body-for-loader">
                            <IconSearch />
                            {loader && <ThreeDots color="#ffdb58" height={30} width={40} />}
                          </div>
                        </button>
                        <button
                          className="mx-2 btn btn-warning"
                          type="button"
                          onClick={() => {
                            setSearchItem("");
                            getCardList("reset");
                          }}
                        >
                          <div className="button-body-for-loader">
                            <IconRefresh />
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="d-grid d-md-flex justify-content-md-evenly">
                        <button className="btn btn-primary" onClick={handleAddClick}>
                          <div className="button-body-for-loader">
                            <span>Genarate Card </span>
                            {loader === "generateCard" && <ThreeDots color="#ffdb58" height={30} width={40} />}
                          </div>
                        </button>
                        <a data-bs-toggle="offcanvas" data-bs-target="#assignAgent" href="#" class="btn btn-primary w-40">
                          &nbsp; Assign agent
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="data-box ">
                <div className="activity-status-table">
                  <div className="table-responsive">
                    <table className="table activity-status-table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Card Number</th>
                          <th scope="col">Assign Agent</th>
                          <th scope="col">Created Date</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody id="body-render">
                        {cards?.length
                          ? cards?.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <p>{item.cardnumber}</p>
                                </td>
                                <td>
                                  <p>{item.agentname !== "" ? item.agentname : "-"}</p>
                                </td>

                                <td>
                                  <div className="last-received-date-time">
                                    <p>{showDateFormat(item.createddate)}</p>
                                  </div>
                                </td>
                                <td></td>
                              </tr>
                            ))
                          : null}
                        {cards.length === 0 && !loader && (
                          <tr>
                            <td colSpan={7} className="text-center text-warning">
                              Data Not found
                            </td>
                          </tr>
                        )}
                        {loader === "getCardList" && (
                          <td colSpan={7} className="text-center">
                            <ThreeDots
                              height="40"
                              width="80"
                              color="#4fa94d"
                              ariaLabel="bars-loading"
                              wrapperStyle={{
                                justifyContent: "center",
                                paddingBottom: "10px",
                              }}
                              wrapperClass=""
                              visible={true}
                            />
                          </td>
                        )}
                      </tbody>
                    </table>

                    <nav aria-label="Page navigation example" className="pull-right">
                      <ul className="pagination">
                        <li className="page-item">
                          <button className="page-link" onClick={() => getCardList("prev")} disabled={pervButtonEnable}>
                            Previous
                          </button>
                        </li>
                        <li className="page-item">
                          <button className="page-link" onClick={() => getCardList("next")} disabled={nextButtonEnable}>
                            Next
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="offcanvas offcanvas-end" tabIndex="-1" id="addCard" style={{ width: "40%" }} aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header activity-status-table brand-offcanvas-header">
              <h5 id="offcanvasRightLabel">Add Card</h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
              <div className="activity-cust-data">
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <section className="mt-3">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="brand-label">
                            Card Number<span className="required-mark">*</span>
                          </label>
                          <input id="firstName" type="text" className="form-control" placeholder="Enter  Card Number" {...register("firstName")} />
                          <p className="text-danger mt-2">{errors.firstName && <p>{errors.firstName.message}</p>}</p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <button className="btn btn-outline-secondary me-md-2" type="button" data-bs-dismiss="offcanvas">
                      Cancel
                    </button>
                    <button className="btn btn-primary" type="submit">
                      <div className="button-body-for-loader">
                        <span>Save</span>
                        {loader && <ThreeDots color="#ffdb58" height={30} width={40} />}
                      </div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div> */}
        <div className="offcanvas offcanvas-end" tabIndex="-1" id="assignAgent" style={{ width: "40%" }} aria-labelledby="offcanvasRightLabel">
          <div className="offcanvas-header activity-status-table brand-offcanvas-header">
            <h5 id="offcanvasRightLabel">Assign Agent</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <div className="activity-cust-data">
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <section className="mt-3">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="brand-label">
                          Assigned Agent<span className="required-mark">*</span>
                        </label>
                        <select class="form-select" aria-label="Default select example" {...register("agentid")}>
                          {agentList?.length ? (
                            agentList.map((agent) => (
                              <option value={agent.id}>
                                {agent?.firstName} {agent?.lastName}
                              </option>
                            ))
                          ) : (
                            <option disabled>No agent found</option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="brand-label">
                          Card Number<span className="required-mark">*</span>
                        </label>
                        <select class="form-select" aria-label="Default select example" {...register("cardnumber")}>
                          {cards?.length ? (
                            cards.filter((card) => card.agentid === "").map((cards) => <option value={cards.id}>{cards?.cardnumber}</option>)
                          ) : (
                            <option disabled>No agent found</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button className="btn btn-outline-secondary me-md-2" type="button" data-bs-dismiss="offcanvas">
                    Cancel
                  </button>
                  <button className="btn btn-primary" type="submit">
                    <div className="button-body-for-loader">
                      <span>Save</span>
                      {loader === "assignAgent" && <ThreeDots color="#ffdb58" height={30} width={40} />}
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmartCard;
