import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import AgencyHeader from "./Agencyheader";
import { useLocation, useParams } from "react-router-dom";
import Footer from "./ancfooter";
import Spinner from "react-bootstrap/Spinner";
import { default as axios } from "../config/axios-config";
import { useNavigate } from "react-router-dom";
import resendImg from "../assets/verification_email.jpg";
import { ThreeDots } from "react-loader-spinner";
import { updateData } from "./redux/action/someDataAction";
import { getAllDataTogether } from "./helper/Helper";
import { useDispatch } from "react-redux";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/Verify";
const DEV_AGENCY_URL = process.env.REACT_APP_ANC_API + "/api/Agency";

const Verification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { agencyName, agentName } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const verifyId = query.get("verifyid");
  const agencyId = query.get("id");
  const loginId = query.get("loginid");
  const type = query.get("type");
  const [passCode, setPassCode] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isGetAllData, setIsGetAllData] = useState(false);

  const getAgencyByLoginId = (loginId) => {
    setIsLoading(true);
    axios({
      method: "post",
      url: `${DEV_AGENCY_URL}/getAgencyByLoginId`,
      headers: { "Content-Type": "application/json" },
      data: loginId,
    })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("AgencyName", response.data.agencyName);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChangePasscode = (e) => {
    let passcode = e.target.value;
    setPassCode(passcode);
    if (passcode.length === 0) {
      setError("Passcode is a required field");
    } else if (passcode.length > 0 && passcode.length < 6) {
      setError("Passcode must be 6 character length");
    } else if (passcode.length == 6) {
      setError("");
    }
  };

  useEffect(() => {
    if (isGetAllData) {
      getAllDataTogether(agencyId, dispatch);
      localStorage.setItem("AgencyId", agencyId);
    }
  }, [isGetAllData]);

  const onClickPasscodeVerify = async (e) => {
    e.preventDefault();
    setMessage("");
    if (passCode.length === 0 || passCode.trim().length === 0) {
      setError("Passcode is a required field");
      return;
    }
    if (passCode && passCode.trim().length > 0 && passCode.trim().length < 6) {
      setError("Passcode must be 6 character length");
      return;
    }
    setError("");
    setIsLoading(true);

    axios
      .get(`${DEVURL}/VerifyPasscode/${passCode}`)
      .then((response) => {
        if (response.status === 200) {
          setPassCode("");
          if (loginId) {
            localStorage.setItem("LoginId", loginId);
            // getAgencyByLoginId(loginId);
          }
          let location_url = response.data;
          const url = new URL(response.data);
          let origin = url?.origin;
          // window.location.replace(url.toString());
          setIsGetAllData(true);
          setTimeout(() => {
            location_url = location_url.replace(origin, "");
            navigate(location_url);
            setIsLoading(false);
          }, 2000);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status === 404) {
          setError("Invalid Passcode");
        } else {
          setError("Failed to verify code");
        }
        setIsLoading(false);
      });
  };

  const onClickResentPasscode = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");
    setIsLoading(true);
    const url = agentName == null ? `${DEVURL}/ResendPasscode/${agencyName}/${verifyId}` : `${DEVURL}/ResendPasscode/${agencyName}/${agentName}/${verifyId}`;
    setTimeout(() => {
      axios
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            setPassCode("");
            setMessage("");
            setMessage("An email message with your code has been sent.");
          }
        })
        .catch((error) => {
          setError("Failed to get resent code.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 1000);
  };

  return (
    <>
      <div className="section-spacing">
        <div className="row">
          <div align="center">
            <Card style={{ width: "28rem", marginTop: "2rem" }}>
              <Card.Img variant="top" />
              <Card.Body>
                <Card.Title>
                  <h1>Two-Factor Authentication</h1>
                </Card.Title>
                <Card.Subtitle className="mt-3 mb-3">Your account is protected with two-factor authentication. We've sent you an email. Please enter the code below.</Card.Subtitle>
                <Card.Text>
                  <input type="tel" className="form-control" placeholder="6-digit code" minLength={6} maxLength={6} value={passCode} onChange={onChangePasscode} />
                  {error && <p className="text-danger mt-3">{error}</p>}
                </Card.Text>
                <Card.Text>
                  <Button variant="primary" onClick={onClickPasscodeVerify}>
                    <div className="button-body-for-loader">
                      <span>Verify Code</span>
                      {isLoading && <ThreeDots color="#ffdb58" height={30} width={40} />}
                    </div>
                  </Button>
                </Card.Text>
                <Card.Text>
                  <a href="#" class="link-underline-primary" role="button" data-bs-toggle="button" onClick={onClickResentPasscode}>
                    Resend the passcode
                  </a>
                </Card.Text>
                {message && <div className="text-success">{message}</div>}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Verification;
