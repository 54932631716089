import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { emailRegex, cellRegex } from "../Common/regex";
import { default as axios } from "../config/axios-config";
import Spinner from "react-bootstrap/Spinner";
import { ThreeDots } from "react-loader-spinner";
import Card from "react-bootstrap/Card";
import { getAllDataTogether } from "./helper/Helper";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess } from "./redux/action/authActions";

const DEVURL = process.env.REACT_APP_ANC_API + "/api";

const signInValidationSchema = yup.object().shape({
  emailAddress: yup
    .string()
    .trim()
    .required()
    .test("emailAddress", "Please enter a valid Email Address or Mobile Number", (value) => {
      return validateEmail(value) || validatePhone(value);
    })
    .max(45)
    .label("Mobile Number or Email Address"),
  password: yup.string().trim().required().min(8).max(45).label("Password"),
});

const validateEmail = (email) => {
  return yup.string().matches(emailRegex).isValidSync(email);
};

const validatePhone = (phone) => {
  return yup.string().matches(cellRegex).isValidSync(phone);
};

const Bodysection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth.user);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isGetAllData, setIsGetAllData] = useState(false);
  const [agencyId, setAgencyId] = useState(null);
  const [agentId, setAgentId] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      emailAddress: "",
      password: "",
    },
    resolver: yupResolver(signInValidationSchema),
  });

  useEffect(() => {
    if (isGetAllData) {
      getAllDataTogether(agencyId, agentId, dispatch);
      localStorage.setItem("AgencyId", agencyId);
    }
  }, [isGetAllData, agencyId]);

  const agencyLogin = async (data) => {
    setErrorMessage("");
    setIsLoading(true);
    await axios
      .post(`${DEVURL}/agency/loginAgency`, data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.isFirstLogin === true) {
            navigate(`/passwordreset/${res.data.emailAddress}`);
          } else {
            dispatch(loginSuccess(res.data));
            localStorage.setItem("LoginId", data.emailAddress);
            setAgencyId(res.data.id);
            setAgentId(res.data.agentId);
            setIsGetAllData(true);
             navigate(`/myvault?verifyid=${res.data.verifyId}`);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage("Invalid user or password!");
      });
  };

  const handleFormSubmit = (formData) => {
    agencyLogin(formData);
  };

  return (
    <section className="hero-section section-spacing">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="hero-form">
              <h4>Sign In</h4>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="mb-md-3">
                  {errorMessage && <p className="text-danger mt-3">{errorMessage}</p>}
                  <div className="form-floating mb-3">
                    <input type="text" className="form-control brand-input" id="floatingEmailAddress" placeholder="name@example.com" {...register("emailAddress")} />
                    <label htmlFor="floatingEmailAddress">Email Address</label>
                    <div className="text-danger mt-1">{errors.emailAddress && <p>{errors.emailAddress.message}</p>}</div>
                  </div>
                  <div className="form-floating">
                    <input type="password" className="form-control brand-input" id="floatingPassword" placeholder="Password" {...register("password")} />
                    <label htmlFor="floatingPassword">Password</label>
                    <div className="text-danger mt-1">{errors.password && <p>{errors.password.message}</p>}</div>
                  </div>
                </div>
                <button type="submit" className="brand-btn-login" align="center" disabled={isLoading}>
                  <div className="button-body-for-loader">
                    <span>Sign In</span>
                    {isLoading && <ThreeDots color="#ffdb58" height={30} width={40} />}
                  </div>
                </button>

                <div className="text-right mt-3">
                  <Link className="brand-link p-0" to="passwordverification">
                    Forgot your password?
                  </Link>
                </div>
                <div className="text-right mt-2">
                  <p>
                    Don't have MyPolicyVault Account?
                    <Link className="brand-link p-2" to="/signup">
                      Sign up now!
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="hero-content">
              <h1>Unleash the Potential of MyPolicyVault</h1>
              <p>
                <a href="https://wwww.agentsandcarriers.com" className="nav-link">
                  Learn more
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-custom-shape-divider-bottom">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
    </section>
  );
};

export default Bodysection;
