import React, { useEffect, useRef, useState } from "react";
import "../../../portal-assets/styles.css";
import { default as axios } from "../../../config/axios-config";
import { ThreeDots } from "react-loader-spinner";
import CustomerDetails from "../CustomerDetails";
import { showDateFormat } from "../../helper/Helper";
import Leads from "./Leads";
import { Link, useLocation } from "react-router-dom";
import { downloadExcel, DownloadTableExcel } from "react-export-table-to-excel";
import EdithAuto from "./healthcare/EdithAuto";
import { IconCirclePlus, IconSearch } from "@tabler/icons-react";
import { IconRefresh } from "@tabler/icons-react";
import { IconFileExport } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import ViewAuto from "./AutoForm/ViewAuto";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/auto";

const Auto = () => {
  const tableRef = useRef(null);
  const query = new URLSearchParams(useLocation().search);
  const tab = query.get("tab");
  const userData = useSelector((state) => state.auth.user);
  const [loader, setLoader] = useState(false);
  const [loader_excel, setLoader_excel] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  let [perPage, setPerPage] = useState(10);
  const [nextButtonEnable, setNextButtonEnable] = useState(false);
  const [pervButtonEnable, setPrevButtonEnable] = useState(true);
  const [activityLists, setActivityLists] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [nextPage, setNextPage] = useState(0);
  const [previousPage, setPreviousPage] = useState(1);
  const [searchItem, setSearchItem] = useState("");
  const header = ["Enrollment Year", "Customer Name", "Customer Phone", "Customer Email", "Gender", "Date Of Birth", "Immigration Status", "Yearly Income"];
  const [exportData, setExportData] = useState([]);

  function handleDownloadExcel() {
    if (exportData.length <= 0) {
      return false;
    }
    downloadExcel({
      fileName: "HealthCare",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header,
        // accept two different data structures
        body: exportData,
      },
    });
  }

  const getHealthApplyAgencyId = (type = null) => {
    const agencyId = localStorage.getItem("AgencyId");
    let currentP = 1;
    if (type) {
      if (type === "next") {
        currentP = nextPage;
      } else {
        currentP = previousPage <= 1 ? 1 : previousPage;
      }
    }
    setLoader(true);
    let filter = searchItem ?? null;
    if (type === "reset") {
      filter = null;
      setSearchItem(null);
    }
    // https://dev-ancapi.azurewebsites.net/api/auto/GetAutoByAgency?agencyId=1&page=1&pageSize=5&searchText=
    axios
      .get(`${DEVURL}/GetAutoByAgency?agencyId=${agencyId}&page=${currentP}&searchText=${filter}&pageSize=${perPage}`)

      // axios.get(`${DEVURL}/GetAutoByAgency/${agencyId}/${filter}/${currentP}/${perPage}`)
      .then((response) => {
        if (response.status === 200) {
          setActivityLists(response?.data);
          let exData = response.data?.items;
          exData = exData.map((item) => {
            return {
              enrollmentYear: item.enrollmentYear,
              fullName: item.firstName + " " + item.lastName,
              phoneNumber: item.phoneNumber,
              emailId: item.emailId,
              gender: item.gender,
              dateOfBirth: showDateFormat(item.dateOfBirth),
              immigratonStatus: item.immigratonStatus,
              annualInome: item.annualInome,
            };
          });
          setExportData(exData);
          setCurrentPage(currentP);
          setNextPage(currentP + 1);
          setPreviousPage(currentP - 1);
          if (response.data.length === 0) {
            setNextButtonEnable(true);
            setPrevButtonEnable(false);
          }
          if (previousPage >= 1) {
            setPrevButtonEnable(false);
          }
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error?.response?.data?.status === 404) {
        } else {
        }
      });
  };

  // const getPertPageData = (e) => {
  //     console.log(e.target.value)
  //     setPerPage(e.target.value);
  //     getHealthApplyAgencyId()
  // }

  const handleDetailsClick = (data) => {
    setSelectedActivity(data);
  };

  const handleEditClick = (data) => {
    setSelectedActivity(data);
  };

  useEffect(() => {
    const agencyId = localStorage.getItem("AgencyId");
    if (agencyId) {
      getHealthApplyAgencyId();
    }
  }, []);

  return (
    <>
      <div className="col py-3">
        <div className="content-panel">
          <div className="panel-title mb-5 ">
            <h4>Auto Vault</h4>
          </div>

          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link brand-tab ${tab != 2 ? "active" : ""}`}
                id="account-tab"
                data-bs-toggle="tab"
                data-bs-target="#account"
                type="button"
                role="tab"
                aria-controls="account"
                aria-selected="true"
              >
                Auto
              </button>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent">
            <div className={`tab-pane fade ${tab != 2 ? "show active" : ""}`} id="account" role="tabpanel" aria-labelledby="account-tab">
              <div className="filter-box mt-3 mb-2">
                <div className="filter-box-content">
                  <div className="row align-items-center">
                    <div className="col-lg-5">
                      <div className="form-floating">
                        <input
                          type="text"
                          size={500}
                          className="form-control"
                          value={searchItem}
                          onChange={(event) => setSearchItem(event.target.value)}
                          id="floatingInput"
                          placeholder=""
                        />
                        <label htmlFor="floatingInput">Search by first name, last name or email</label>
                      </div>
                    </div>
                    <div className="col-lg-1">
                      <div className="d-grid d-md-flex justify-content-md-left" style={{ height: "50px" }}>
                        <button className="btn btn-primary" type="button" onClick={() => getHealthApplyAgencyId()}>
                          <div className="button-body-for-loader">
                            <IconSearch />
                            {loader && <ThreeDots color="#ffdb58" height={30} width={40} />}
                          </div>
                        </button>
                        <button className="mx-2 btn btn-warning" type="button" onClick={() => getHealthApplyAgencyId("reset")}>
                          <div className="button-body-for-loader">
                            <IconRefresh />
                          </div>
                        </button>
                        <button className="mx-2 btn btn-success" type="button"  onClick={handleDownloadExcel}>
                          <div className="button-body-for-loader">
                            <IconFileExport />
                            {loader_excel && <ThreeDots color="#ffdb58" height={30} width={40} />}
                          </div>
                        </button>
                        <Link to={userData.agentId == null ? `/autoform/${userData.agencyName}` : `/autoform/${userData.agencyName}/${userData.userId}`} target="_blank">
                          <button className="mx-2 h-100 btn btn-primary" type="button">
                            <div className="button-body-for-loader">
                              <IconCirclePlus />
                            </div>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="data-box ">
                <div className="activity-status-table">
                  <div className="table-responsive">
                    <table className="table activity-status-table table-striped" ref={tableRef}>
                      <thead>
                        <tr>
                          <th scope="col">Agent Info</th>
                          <th scope="col">Address </th>
                          <th scope="col">Effective Date</th>
                          <th scope="col">Drivers Name</th>
                          <th scope="col">Vehicles Info</th>
                          <th scope="col">Created Date</th>
                          <th scope="col">View / Edit</th>
                        </tr>
                      </thead>
                      <tbody id="body-render">
                        {activityLists?.items?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <p>
                                {" "}
                                {item.firstName + " " + item.lastName}
                                <br />
                                {item.email}
                                <br />
                                {item.cell}
                              </p>
                            </td>
                            <td>
                              <div className="cust-info">
                                <p>{item.apt}</p>
                              </div>
                            </td>
                            <td>
                              <div className="last-received-date-time">
                                <p>{showDateFormat(item.garragingEffectiveDate)}</p>
                              </div>
                            </td>

                            <td>
                              <p>
                                {" "}
                                {item?.drivers?.map((dri) => (
                                  <span>
                                    {" "}
                                    {dri.name + "-" + dri.lic}
                                    <br />
                                  </span>
                                ))}
                              </p>
                            </td>
                            <td>
                              <p>
                                {" "}
                                {item?.vehicles?.map((dri) => (
                                  <span>
                                    {" "}
                                    {dri.type}
                                    <br />
                                  </span>
                                ))}
                              </p>
                            </td>
                            <td>
                              <p>{showDateFormat(item.createdAt)} </p>
                            </td>
                            <td>
                              <a data-bs-toggle="offcanvas" onClick={() => handleDetailsClick(item)} data-bs-target="#auto-view" href="#">
                                <i className="bi bi-file-earmark"></i>
                              </a>
                              &nbsp;
                              <a data-bs-toggle="offcanvas" onClick={() => handleEditClick(item)} data-bs-target="#editHealthcare" href="#">
                                <i className="bi bi-pencil-square"></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                        {activityLists?.items?.length <= 0 && !loader && (
                          <tr>
                            <td colSpan={7} className="text-center text-warning">
                              Data Not found
                            </td>
                          </tr>
                        )}
                        {loader && (
                          <td colSpan={7} className="text-center">
                            <ThreeDots
                              height="40"
                              width="80"
                              color="#4fa94d"
                              ariaLabel="bars-loading"
                              wrapperStyle={{ justifyContent: "center", paddingBottom: "10px" }}
                              wrapperClass=""
                              visible={true}
                            />
                          </td>
                        )}
                      </tbody>
                    </table>

                    <nav aria-label="Page navigation example" className="pull-right">
                      <ul className="pagination">
                        <li className="page-item">
                          <button className="page-link" onClick={() => getHealthApplyAgencyId("prev")} disabled={!activityLists?.hasPreviousPage}>
                            Previous
                          </button>
                        </li>
                        <li className="page-item">
                          <button className="page-link" onClick={() => getHealthApplyAgencyId("next")} disabled={!activityLists?.hasNextPage}>
                            Next
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ViewAuto details={selectedActivity} />
      <EdithAuto details={selectedActivity} setSelectedActivity={setSelectedActivity}/>
    </>
  );
};
export default Auto;
