import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Auto from "./Auto";
import GasStation from "./GasStation";
import GeneralContractor from "./GeneralContractor";
import GeneralStore from "./GeneralStore";
import HealthCare from "./HealthCare";
import Home from "./Home";
import Restaurant from "./Restaurant";
import Salon from "./Salon";
import ShoppingMall from "./ShoppingMall";
import HotelForm from "./HotelForm";

const Activities = () => {
  useEffect(() => {}, []);
  const { type } = useParams();
  return (
    <>
      {type === "myhealthcare" && <HealthCare />}
      {type === "restaurantform" && <Restaurant />}
      {type === "gasstation" && <GasStation />}
      {type === "generalContractor" && <GeneralContractor />}
      {type === "shoppingMall" && <ShoppingMall />}
      {type === "salon" && <Salon />}
      {type === "auto" && <Auto />}
      {type === "home" && <Home />}
      {type === "generalStore" && <GeneralStore />}
      {type === "hotelForm" && <HotelForm />}
    </>
  );
};

export default Activities;
