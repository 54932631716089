import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AccordForm from "./Components/AccordForm";
import ExitResponse from "./Components/ExitPage";
import Home from "./Components/Home";
import HomeApplication from "./Components/PersonalLine/HomeApplication";
import AutoForm1 from "./Components/PersonalLine/Master";
import ThankyouResponse from "./Components/Thankyoupage";
import EmailVerification from "./Components/email_verification/EmailVerification";
import gasstationform from "./Components/gasstationform/Master";
import generalStoreForm from "./Components/generalStoreForm/Master";
import generalContractorForm from "./Components/generalcontractorform/Master";
import HealthForm from "./Components/healthcare/Master";
import ViewHealthApp from "./Components/healthcare/View/ViewDetails";
import Quote from "./Components/healthcare/quote/Search";
import SearchResult from "./Components/healthcare/quote/SearchResult";
import HotelForm from "./Components/hotelForm/Master";
import EmailForPasswordVerification from "./Components/password_recovery/EmailForPasswordVerification";
import PasswordRecovery from "./Components/password_recovery/PasswordRecovery";
import SignUp from "./Components/portal/signup/index";
import Previewforms from "./Components/preview/Previewforms";
import Restaurant from "./Components/restaurant/Master";
import salonForm from "./Components/salonform/Master";
import shoppingMallForm from "./Components/shopping_mall_form/Master";
import Master from "./Components/tank/Master";
import Verification from "./Components/verification";
import PortalRoutes from "./PortalRoutes";
import PasswordReset from "./Components/password_reset/PasswordReset";
import SmartLink from "./Components/SmartLink/SmartLinkConfiguation";
import SmartLinkConfiguation from "./Components/SmartLink/SmartLinkConfiguation";
import InvalidURL from "./Components/InvalidURL/InvalidURL";
import AdminDashboard from "./Components/portal/AdminDashboard";
import SmartCardMapping from "./Components/SmartCard/SmartCardMapping";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/control-panel" element={<AdminDashboard />} />
          <Route path="/HealthForm/:agencyName/:agentName?" Component={HealthForm} />
          <Route path="/restaurantform/:agencyName/:agentName?" Component={Restaurant} />
          <Route path="/hotelForm/:agencyName/:agentName?" Component={HotelForm} />
          <Route path="/tankform" Component={Master} />
          <Route path="/gasstationform/:agencyName/:agentName?" Component={gasstationform} />
          <Route path="/generalContractorForm/:agencyName/:agentName?" Component={generalContractorForm} />
          <Route path="/generalStoreForm/:agencyName/:agentName?" Component={generalStoreForm} />
          <Route path="/salonForm/:agencyName/:agentName?" Component={salonForm} />
          <Route path="/shoppingMallForm/:agencyName/:agentName?" Component={shoppingMallForm} />
          <Route path="/Quote/:agencyName?/:agentName?" Component={Quote} />
          <Route path="/retrieve/:formName/:agencyName/:agentName?" Component={EmailVerification} />
          <Route path="/result/:agencyName?/:agentName?" Component={SearchResult} />
          <Route path="/ViewHealthApp" Component={ViewHealthApp} />
          <Route path="/Thankyoupage" Component={ThankyouResponse} />
          <Route path="/ExitPage/:agencyName/:agentName?" Component={ExitResponse} />
          <Route path="/Verification/:agencyName?/:agentName?" Component={Verification} />
          <Route path="/signup" Component={SignUp} />
          <Route path="/AccordForm" Component={AccordForm} />
          <Route path="/AutoForm/:agencyName/:agentName?" element={<AutoForm1 />} />
          <Route path="/homeform/:agencyName/:agentName?" element={<HomeApplication />} />
          <Route path="/passwordverification" Component={EmailForPasswordVerification} />
          <Route path="/passwordrecovery/:emailAddress" Component={PasswordRecovery} />
          <Route path="/passwordreset/:emailAddress" Component={PasswordReset} />
          <Route path="/smartLink/:agencyName?/:agentName?" element={<SmartLinkConfiguation />} />
          <Route path="/smartCard/:cardNumber" element={<SmartCardMapping />} />
          <Route path="/myvault/*" element={<PortalRoutes />} />
          <Route path="/previewforms" element={<Previewforms />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
