import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { default as axios } from "../../config/axios-config";
import Footer from "../ancfooter";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

const DEVURL = process.env.REACT_APP_ANC_API + "/api";

const passwordRecoveryValidationSchema = yup.object().shape({
    password: yup.string().trim().required().min(8).max(45).label('Password'),
    confirmPassword: yup.string().trim().required().min(8).max(45).label('Confirm Password')
        .oneOf([yup.ref('password'), null], 'Passwords must match')
});

const PasswordReset = () => {

    let navigate = useNavigate();
    const { emailAddress } = useParams();
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        defaultValues: {
            password: "",
            confirmPassword: ""
        },
        resolver: yupResolver(passwordRecoveryValidationSchema),
    });

    const handleFormSubmit = (formData) => {
        setSuccessMessage("");
        const passwordChangeObj = {
            emailAddress: emailAddress,
            password: formData.password
        }
        console.log('passwordChangeObj',passwordChangeObj)
        axios
            .post(`${DEVURL}/agent/setNewPassword`, passwordChangeObj)
            .then(res => {
                if (res.status === 200) {
                    reset();
                    navigate("/");
                }
            })
            .catch(error => {                
                setErrorMessage(error?.response?.data);
            });
    };

    return (
        <div className="container">
            <div className="section-spacing m-lg-5">
                <div className="row">
                    <div align="center">
                        <Card style={{ width: "28rem", marginTop: "2rem" }}>
                            <Card.Img variant="top" />
                            <Card.Body>
                                <form onSubmit={handleSubmit(handleFormSubmit)}>
                                    <Card.Title><h1>Password Reset</h1></Card.Title>
                                    <Card.Subtitle className="mt-3 mb-3">
                                        {errorMessage &&
                                            <div className="text-danger mt-3">{errorMessage}</div>
                                        }
                                        {successMessage &&
                                            <div className="text-success mt-3">{successMessage}</div>
                                        }
                                    </Card.Subtitle>
                                    <Card.Text>
                                        <input
                                            type="password"
                                            className="form-control"
                                            maxLength={45}
                                            placeholder="New Password"
                                            {...register("password")}
                                        />
                                        <div className="text-danger mt-2">
                                            {errors?.password?.message}
                                        </div>
                                    </Card.Text>
                                    <Card.Text>
                                        <input
                                            type="password"
                                            className="form-control"
                                            maxLength={45}
                                            placeholder="Confirm Password"
                                            {...register("confirmPassword")}
                                        />
                                        <div className="text-danger mt-2">
                                            {errors?.confirmPassword?.message}
                                        </div>
                                    </Card.Text>
                                    <Card.Text>
                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Card.Text>
                                </form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PasswordReset;
