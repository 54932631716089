import React, { useState } from 'react';
import './../../../src/portal-assets/image-upload.css';

function ImageUpload({ isEdit, onImageUpload }) {
    
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
        onImageUpload(file);
    };

    return (
        <div>
            {/*<input type="file" onChange={handleImageChange} />*/}
            <label htmlFor="image-upload" className="upload-button">
                Change Logo
            </label>
            <input
                type="file"
                id="image-upload"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: 'none' }}
                disabled={!isEdit}
            />
        </div>
    );
}

export default ImageUpload;
