import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Badge, Card } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import axiosInstance from "../../../config/axios-config";
import { NumericFormat } from "react-number-format";

const validationSchema = yup.object().shape({
  carrierName: yup.string().nullable(true).label("Carrier name"),
  renewalDate: yup.string().nullable(true).label("Renewal date"),
  insurancePremium: yup.string().matches(/^\d+$/, "Please enter a valid amount").nullable(true).label("Insurance premium"),
  carrierDuration: yup.string().nullable(true).label("Carrier duration"),
  current_Insurance_Policy: yup.string().label("Current insurance policy"),
});

const DEVURL = process.env.REACT_APP_ANC_API + "/api/Auto/";

const Step2 = ({ stepHandler, loader, details }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    watch,
  } = useForm({
    defaultValues: {
      drivers: [],
      vehicles: [],
      carrierName: "",
      insurancePremium: null,
      carrierDuration: "",
      current_Insurance_Policy: "",
      renewalDate: null,
    },
    resolver: yupResolver(validationSchema),
  });
  const terms = watch("terms");
  const carrierName = watch("carrierName");
  const insurancePremium = watch("insurancePremium");
  const carrierDuration = watch("carrierDuration");
  const current_Insurance_Policy = watch("current_Insurance_Policy");
  const renewalDate = watch("renewalDate");
  const [disableAdd, setDisableAdd] = useState(false);
  const isPrevAddDriver = JSON.parse(localStorage.getItem("isAddDriver"));
  const isPrevAddVehicle = JSON.parse(localStorage.getItem("isAddVehicle"));
  const [isAddDriver, setIsAddDriver] = useState(isPrevAddDriver !== null ? isPrevAddDriver : true);
  const [isLoading, setLoading] = useState(false);
  const [isAddVehicle, setIsAddVehicle] = useState(isPrevAddVehicle !== null ? isPrevAddVehicle : true);

  const [drivers, setDrivers] = useState([]);
  const [newDriver, setNewDriver] = useState({
    name: "",
    dob: "",
    lic: "",
    error: "",
    dobError: "",
    licError: "",
  });
  const [vehicles, setVehicles] = useState([]);
  const [newVehicle, setNewVehicle] = useState({
    type: "",
    description: "",
    error: "",
  });
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);

  const fillupStep2DefaultValues = (step2) => {
    setValue("drivers", step2?.drivers);
    if (step2) {
      setDrivers(step2?.drivers ?? []);
      setVehicles(step2?.vehicles ?? []);
    }
    setValue("vehicles", step2?.vehicles);
    setValue("carrierDuration", step2?.carrierDuration);
    setValue("carrierName", step2?.carrierName);
    setValue("current_Insurance_Policy", step2?.current_Insurance_Policy);
    setValue("insurancePremium", step2?.insurancePremium);
    setValue("renewalDate", step2?.renewalDate);

    if (step2?.vehicles?.length > 0) {
      setIsAddVehicle(false);
    }
    if (step2?.drivers?.length > 0) {
      setIsAddDriver(false);
    }
  };
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("step2"));
    if (data) fillupStep2DefaultValues(data);
  }, []);

  const handleFormSubmit = async (data) => {
    console.log("da", data);
    localStorage.setItem("step2", JSON.stringify(data));
    Swal.fire({
      title: "Are you sure you want to submit the form?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        updateAutoFormInfo();
      } else {
        return;
      }
    });
  };
  const updateAutoFormInfo = async () => {
    setLoading(true);
    const step1 = localStorage.getItem("step1");
    const step2 = localStorage.getItem("step2");
    console.log("submit", step1, step2);

    const autoInfo = {
      ...JSON.parse(step1),
      ...JSON.parse(step2),
      terms: true,
      
    };
    console.log(autoInfo);
    await axiosInstance
      .put(`${DEVURL}updateauto`, autoInfo)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          clearLocalStorage();
          reset();
          stepHandler(3);
          setTimeout(() => {
            stepHandler(1);
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const clearLocalStorage = () => {
    localStorage.removeItem("step1");
    localStorage.removeItem("step2");
    localStorage.removeItem("isAddVehicle");
    localStorage.removeItem("isAddDriver");
  };

  const addMoreDriver = () => {
    if (newDriver.name === "" || newDriver.dob === "" || newDriver.lic === "" || newDriver.dobError !== "" || newDriver.error !== "" || newDriver.licError !== "") {
      return false;
    }
    const newDriver_ = [...drivers, newDriver];
    setDrivers(newDriver_);
    setNewDriver({ name: "", dob: "", lic: "" });
    setIsAddDriver(false);
    setValue("drivers", newDriver_);
  };

  const addMoreVehicle = () => {
    if (newVehicle.type === "" || newVehicle.description === "" || newVehicle.error !== "") {
      return false;
    }
    const newVehicle_ = [...vehicles, newVehicle];
    setVehicles(newVehicle_);
    setNewVehicle({ type: "", description: "" });
    setIsAddVehicle(false);
    setValue("vehicles", newVehicle_);
  };

  const isAge15OrOlder = (value) => {
    if (!value) {
      return true;
    }
    const birthYear = value.getFullYear();
    const currentYear = new Date().getFullYear();
    return currentYear - birthYear >= 15;
  };

  const newDriverHandler = async (e) => {
    // console.log("e", e);
    const { id, value } = e.target;
    const updatedDriver = { ...newDriver, [id]: value };
    if (id === "name") {
      if (value.trim() === "") {
        updatedDriver.error = "Driver name is required";
      } else if (!/^[a-zA-Z\s]+$/.test(value)) {
        updatedDriver.error = "Please enter a valid name";
      } else {
        updatedDriver.error = ""; // Reset the error if valid
      }
    }
    if (id === "lic") {
      if (value.trim() === "") {
        updatedDriver.licError = "Primary driver lic is required";
      } else if (!/^[A-Z0-9]{8}$/i.test(value)) {
        updatedDriver.licError = "Please enter valid driver license";
      } else if (drivers.some((driver) => driver.lic === value)) {
        updatedDriver.licError = "This driver license is already in use";
      } else {
        updatedDriver.licError = ""; // Reset the error if valid
      }
    }
    if (id === "dob") {
      const dobDate = new Date(value);
      if (!isAge15OrOlder(dobDate)) {
        updatedDriver.dobError = "Driver age must be 15 or older";
      } else {
        updatedDriver.dobError = ""; // Reset the error if valid
      }
    }
    setNewDriver(updatedDriver);
  };

  const newVehicleHandle = (e) => {
    let { id, value } = e.target;
    id = id === "description" ? "description" : "type";
    const updatedVehicle = { ...newVehicle, [id]: value };
    if (id === "description") {
      if (value.trim() === "") {
        updatedVehicle.error = "Vehicle vin number is required";
      } else if (!/^[A-HJ-NPR-Z0-9]{17}$/.test(value)) {
        updatedVehicle.error = "Please enter valid vin number";
      } else if (vehicles.some((vehicle) => vehicle.description === value)) {
        updatedVehicle.error = "This vehicle vin number is already in use";
      } else {
        updatedVehicle.error = ""; // Reset the error if valid
      }
    }
    setNewVehicle(updatedVehicle);
  };

  const removeElement = (index, type) => {
    if (type === "driver") {
      const driversCopy = [...drivers];
      driversCopy.splice(index, 1);
      if (driversCopy.length === 0) {
        setIsAddDriver(true);
      }
      setDrivers(driversCopy);
      setValue("drivers", driversCopy);
    } else {
      // Create a copy of the vehicles array
      const vehiclesCopy = [...vehicles];
      // Use splice to remove the element at the specified index
      vehiclesCopy.splice(index, 1);
      // Update the state with the modified copy
      if (vehiclesCopy.length === 0) {
        setIsAddVehicle(true);
      }
      setVehicles(vehiclesCopy);
      setValue("vehicles", vehiclesCopy);
    }
  };

  const handlePrevious = (e) => {
    let data = {
      terms: terms,
      carrierDuration: carrierDuration,
      insurancePremium: insurancePremium,
      current_Insurance_Policy: current_Insurance_Policy,
      renewalDate: renewalDate,
      carrierName: carrierName,
      drivers: [...drivers],
      vehicles: [...vehicles],
    };

    localStorage.setItem("isAddDriver", JSON.stringify(isAddDriver));
    localStorage.setItem("isAddVehicle", JSON.stringify(isAddVehicle));
    stepHandler(1);
  };
  if (isLoading) {
    return loader();
  }

  return (
    <>
      <div className="container">
        <div className="wizard-content">
          <form onSubmit={handleSubmit(handleFormSubmit)} className="tab-wizard wizard-circle wizard needs-validation">
            <section>
              <h4 className="wrap-title mb-3">Driver(s) Information</h4>
              <div className="row">
                {drivers.length > 0 && (
                  <div className="table-responsive">
                    <table className="table table-striped activity-status-table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" width="100">
                            #
                          </th>
                          <th scope="col">Driver Name</th>
                          <th scope="col">Date of Birth</th>
                          <th scope="col"> Driver Lic</th>
                          <th scope="col" width="100">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {drivers &&
                          drivers.map((driver, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{driver.name}</td>
                              <td>{driver.dob}</td>
                              <td>{driver.lic}</td>
                              <td>
                                <a onClick={() => removeElement(index, "driver")} className="btn btn-danger">
                                  Remove
                                </a>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {!isAddDriver && (
                  <div className="text-end">
                    <a onClick={() => setIsAddDriver(true)} className="btn btn-primary m-2">
                      Add More Driver
                    </a>
                  </div>
                )}

                {isAddDriver && (
                  <Card className="p-3 mb-3">
                    <div className="row">
                      <div className="col-md-4 col-lg-4">
                        <div className="mb-3">
                          <label className="brand-label">
                            Driver Name <span className="required-mark">*</span>
                          </label>
                          <input
                            id="name"
                            type="text"
                            required
                            className="form-control"
                            placeholder="Enter Primary Driver Name"
                            onChange={newDriverHandler}
                            value={newDriver.name}
                          />

                          <p className="text-danger mt-2">{newDriver.error && <p>{newDriver.error}</p>}</p>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="mb-3">
                          <label className="brand-label">
                            Primary Driver Date of Birth
                            <span className="required-mark"> *</span>
                          </label>
                          <input
                            id="dob"
                            type="date"
                            max="2099-12-31"
                            className="form-control"
                            placeholder="Enter Enrollment Date"
                            onChange={newDriverHandler}
                            value={newDriver.dob}
                            required
                          />
                          <p className="text-danger mt-2">{newDriver.dobError && <p>{newDriver.dobError}</p>}</p>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-5 ">
                        <div className="">
                          <label className="brand-label">
                            Primary Driver Lic <span className="required-mark">*</span>
                          </label>
                          <div style={{ display: "flex" }}>
                            <div>
                              <input
                                id="lic"
                                type="text"
                                className="form-control"
                                placeholder="Enter Primary Driver Lic"
                                onChange={newDriverHandler}
                                value={newDriver.lic}
                                required={true}
                              />
                              <p className="text-danger mt-2">{newDriver.licError && <p>{newDriver.licError}</p>}</p>
                            </div>
                            <div>
                              {" "}
                              <a onClick={addMoreDriver} className="btn btn-primary mx-2" style={{ "min-width": "30%" }}>
                                Add
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                )}

                <h4 className=" wrap-title mb-3">Vehicle(s) Information:</h4>

                {vehicles.length > 0 && (
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered activity-status-table">
                      <thead>
                        <tr>
                          <th scope="col" width="100">
                            #
                          </th>
                          <th scope="col">Vehicle Coverage Type</th>
                          <th scope="col">Vehicle Vin Number</th>
                          <th scope="col" width="100">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {vehicles.map((driver, ij) => (
                          <tr key={ij}>
                            <td>{ij + 1}</td>
                            <td>{driver.type}</td>
                            <td>{driver.description}</td>
                            <td>
                              <a onClick={() => removeElement(ij, "vehicle")} className="btn btn-danger">
                                Remove
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

                {!isAddVehicle && (
                  <div className="text-end">
                    <a onClick={() => setIsAddVehicle(true)} className="btn btn-primary m-2">
                      Add More Vehicle
                    </a>
                  </div>
                )}
                {isAddVehicle && (
                  <Card className="p-2 mb-3">
                    <div className="row">
                      <div className="col-md-4 col-lg-4">
                        <div className="mb-3">
                          <label className="brand-label">
                            Vehicle Coverage Type <span className="required-mark">*</span>
                          </label>

                          <div>
                            <input className="mx-2" type="radio" name="vin1" id="full" checked={newVehicle.type === "full"} value="full" onChange={newVehicleHandle} />
                            <label className={"brand-label"} htmlFor="vin1_Full">
                              Full
                            </label>
                            <input
                              className="mx-2"
                              type="radio"
                              name="vin1"
                              checked={newVehicle.type === "liability"}
                              id="liability"
                              value="liability"
                              onChange={newVehicleHandle}
                            />
                            <label className={"brand-label"} htmlFor="vin1_Liability">
                              Liability Only
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4 ">
                        <div className="">
                          <label className="brand-label">
                            Vehicle Vin Number <span className="required-mark">*</span>
                          </label>
                          <div style={{ display: "flex" }}>
                            <div>
                              <input
                                id="description"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Enter Vin Number"
                                onChange={newVehicleHandle}
                                value={newVehicle.description}
                              />
                              <p className="text-danger mt-2">{newVehicle.error && <p>{newVehicle.error}</p>}</p>
                            </div>
                            <div>
                              <a onClick={addMoreVehicle} className="btn btn-primary mx-2" style={{ "min-width": "30%" }}>
                                Add
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                )}

                <h4 className=" wrap-title  mb-3">Current insurance Detail:</h4>
                <div className="col-md-6 col-lg-3">
                  <div className="mb-3">
                    <label className="brand-label">Carrier Name:</label>
                    <input id="carrierName" type="text" className="form-control" placeholder="Enter  Carrier Name" {...register("carrierName")} />
                    <p className="text-danger mt-2">{errors.carrierName && <p>{errors.carrierName.message}</p>}</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="mb-3">
                    <label className="brand-label">Renewal Date:</label>
                    <input id="renewalDate" type="date" max="2099-12-31" className="form-control" placeholder="Enter  Renewal Date " {...register("renewalDate")} />
                    <p className="text-danger mt-2">{errors.renewalDate && <p>{errors.renewalDate.message}</p>}</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="mb-3">
                    <label className="brand-label">Premium Amount:</label>
                    
                    <Controller
                      name="insurancePremium"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <NumericFormat
                          allowNegative={false}
                          {...field}
                          className="form-control"
                          thousandSeparator={true}
                          prefix="$"
                          onValueChange={(values) => {
                            setValue("insurancePremium", values.floatValue);
                          }}
                          placeholder="Current insurance premium"
                          {...register("insurancePremium")}
                        />
                      )}
                    />
                    <p className="text-danger mt-2">{errors.insurancePremium && <p>{errors.insurancePremium.message}</p>}</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="mb-3">
                    <label className="brand-label">Duration With Current Carrier:</label>
                    <input id="carrierDuration" type="text" className="form-control" placeholder="Carrier Duration" {...register("carrierDuration")} />
                    <p className="text-danger mt-2">{errors.carrierDuration && <p>{errors.carrierDuration.message}</p>}</p>
                  </div>
                </div>
              </div>
            </section>
            <div className="d-flex justify-content-end">
              <button type="button" className="btn btn-outline-primary" onClick={handlePrevious}>
                Previous
              </button>
              <button type="submit" className="btn btn-primary ms-2" disabled={!drivers.length > 0 || !vehicles.length > 0}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Step2;
