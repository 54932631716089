import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import BusinessHour from "../../../../Common/BusinessHour";
import { emailRegex, nameRegex } from "../../../../Common/regex";
import axiosInstance from "../../../../config/axios-config";
import PlaceComponent from "../../../healthcare/GooglePlace";
import { formatTelNumber } from "../../../helper/Helper";
const formattedDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const DEVURL =
  process.env.REACT_APP_ANC_API +
  "/api/GeneralContractor/GetGeneralContractorInfoById";

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required("First name is required")
    .matches(nameRegex, "Please enter a valid name")
    .label("First name"),
  lastName: yup
    .string()
    .trim()
    .required("Last name is required")
    .matches(nameRegex, "Please enter a valid name")
    .label("Last name"),
  businessName: yup
    .string()
    .trim()
    .required("Business name is required")
    .label("Business name"),
  dbaname: yup
    .string()
    .nullable(true)
    .matches(nameRegex, "Please enter a valid dba")
    .label("DBA"),
  hoursOfOperation: yup
    .string()
    .trim()
    .typeError("Please enter a valid time")
    .required("Hours of operation is required")
    .label("Hours of operation"),
  typeOfBusiness: yup
    .string()
    .trim()
    .required("Business type is required")
    .label("Business type"),

  locationAddress: yup
    .string()
    .trim()
    .required("Location address is required")
    .label("Location address"),
  mailingAddress: yup
    .string()
    .trim()
    .required("Mailing address is required")
    .label("Mailing address"),
  mobileNum: yup
    .string()
    .trim()
    .required("Mobile number is required")
    .matches(
      /^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/,
      "Please enter valid mobile number"
    )
    .label("Mobile number"),
  workNum: yup
    .string()
    .nullable(null)
    .matches(
      /^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[0-9]{3}[\s\-]?[0-9]{4}$|^$/,
      "Please enter valid work number"
    )
    .label("Work number"),
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .matches(emailRegex, "Enter a valid email")
    .label("Email address"),
  // coverage: yup
  //   .array()
  //   .typeError("Coverage is required")
  //   .min(1, "Coverage is required")
  //   .of(yup.string())
  //   .required("Coverage is required")
  //   .label("Coverage"),

  effectiveDate: yup
    .string()
    .trim()
    .required("Effective date is required")
    .test("is-future-date", "Date must be today or future date", (value) => {
      if (!value) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate >= currentDate;
    })
    .label("Effective date"),
});

const Step1 = ({ stepHandler, loader, details }) => {
  const [sameAsAddress, setSameAsAddress] = useState(false);
  const [garageAddress, setGarageAddress] = useState("");
  const queryParams = new URLSearchParams(window.location.search);
  const agentId = queryParams.get("agentid");
  const [hoursOfOperation, setHoursOfOperation] = useState(["", ""]);
  const [isLoading, setLoading] = useState(false);
  const [addesss, setAddress] = useState("");
  const agencyId = details?.customerInfo?.agencyId;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      businessName: "",
      dbaname: "",
      hoursOfOperation: "",
      typeOfBusiness: "",
      locationAddress: "",
      locationCity: "",
      locationState: "",
      locationZip: "",
      mailingAddress: "",
      mailingCity: "",
      mailingState: "",
      mailingZip: "",
      mobileNum: "",
      workNum: "",
      email: "",
      coverage: [],
      effectiveDate: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const mobileNum = watch("mobileNum");
  const workNum = watch("workNum");
  const locationCity = watch("locationCity");
  const locationState = watch("locationState");
  const locationZip = watch("locationZip");

  const getLocation = async (data) => {
    let address_components = data.address_components;
    let formatted_address = data.formatted_address;
    setValue("locationAddress", formatted_address);
    setAddress(formatted_address);
    formatted_address = formatted_address.split(",");
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        zip_code = data.long_name + zip_code;
      }
      if (data.types.includes("locality")) {
        setValue("locationCity", data.long_name);
      }
      if (data.types.includes("administrative_area_level_1")) {
        setValue("locationState", data.long_name);
      }
    });
    setValue("locationZip", zip_code);
  };

  const getGarageAddress = async (data) => {
    let address_components = data?.address_components;
    let formatted_address = data?.formatted_address;
    setValue("mailingAddress", formatted_address);
    formatted_address = formatted_address.split(",");
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        zip_code = data.long_name + zip_code;
      }
      // if (data.types.includes("postal_code_suffix")) {
      //     zip_code =  zip_code +'-'+ data.long_name;
      // }
      if (data.types.includes("locality")) {
        setValue("mailingCity", data.long_name);
      }
      if (data.types.includes("administrative_area_level_1")) {
        setValue("mailingState", data.long_name);
      }
    });
    setValue("mailingZip", zip_code);
    return false;
  };

  const getTimeRange = (range) => {
    setValue("hoursOfOperation", range);
  };

  const fillupStep1HotelInfo = (genContract) => {
    if (genContract) {
      setValue("agencyId", agencyId);
      setValue("agentId", agentId);
      setValue("customerId", genContract?.customerInfo?.customerId);
      setValue("firstName", genContract.customerInfo.firstName);
      setValue("lastName", genContract.customerInfo.lastName);
      setValue("businessName", genContract.customerInfo.businessName);
      setValue("dbaname", genContract.customerInfo.dbaname);
      setValue(
        "hoursOfOperation",
        genContract.generalContractorInfo.hoursOfOperation
      );
      let hrOfOperation = genContract.generalContractorInfo.hoursOfOperation;
      hrOfOperation = hrOfOperation.split("-");
      setHoursOfOperation(hrOfOperation);
      setValue(
        "typeOfBusiness",
        genContract.generalContractorInfo.typeOfBusiness
      );
      setValue("locationAddress", genContract.customerInfo.locationAddress);
      setValue("locationCity", genContract.customerInfo.locationCity);
      setValue("locationState", genContract.customerInfo.locationState);
      setValue("locationZip", genContract.customerInfo.locationZip);
      setValue("mailingAddress", genContract.customerInfo.mailingAddress);
      setValue("mailingCity", genContract.customerInfo.mailingCity);
      setValue("mailingState", genContract.customerInfo.mailingState);
      setValue("mailingZip", genContract.customerInfo.mailingZip);
      setValue("workNum", genContract.customerInfo.workNum);
      setValue("mobileNum", genContract.customerInfo.mobileNum);
      setValue("email", genContract.customerInfo.email);
      setValue("coverage", genContract.generalContractorInfo?.coverage);
      setValue(
        "effectiveDate",
        formattedDate(genContract?.generalContractorInfo?.effectiveDate)
      );
      setAddress(genContract.customerInfo.locationAddress);
      setGarageAddress(genContract.customerInfo.mailingAddress);
      if (
        genContract.customerInfo.mailingAddress ===
        genContract.customerInfo.locationAddress
      ) {
        setSameAsAddress(true);
      }
    }
  };

  const getStep1HotelInfo = (genContract) => {
    const generalContractorInfoOb = {};
    generalContractorInfoOb.customerId = genContract.customerInfo.customerId;
    generalContractorInfoOb.firstName = genContract.customerInfo.firstName;
    generalContractorInfoOb.lastName = genContract.customerInfo.lastName;
    generalContractorInfoOb.businessName =
      genContract.customerInfo.businessName;
    generalContractorInfoOb.dbaname = genContract.customerInfo.dbaname;
    generalContractorInfoOb.hoursOfOperation =
      genContract.generalContractorInfo.hoursOfOperation;
    generalContractorInfoOb.typeOfBusiness =
      genContract.generalContractorInfo.typeOfBusiness;
    generalContractorInfoOb.locationAddress =
      genContract.customerInfo.locationAddress;
    generalContractorInfoOb.locationCity =
      genContract.customerInfo.locationCity;
    generalContractorInfoOb.locationState =
      genContract.customerInfo.locationState;
    generalContractorInfoOb.locationZip = genContract.customerInfo.locationZip;
    generalContractorInfoOb.mailingAddress =
      genContract.customerInfo.mailingAddress;
    generalContractorInfoOb.mailingCity = genContract.customerInfo.mailingCity;
    generalContractorInfoOb.mailingState =
      genContract.customerInfo.mailingState;
    generalContractorInfoOb.mailingZip = genContract.customerInfo.mailingZip;
    generalContractorInfoOb.workNum = genContract.customerInfo.workNum;
    generalContractorInfoOb.mobileNum = genContract.customerInfo.mobileNum;
    generalContractorInfoOb.email = genContract.customerInfo.email;
    generalContractorInfoOb.coverage =
      genContract.generalContractorInfo.coverage;
    generalContractorInfoOb.effectiveDate =
      genContract.generalContractorInfo.effectiveDate;
    return generalContractorInfoOb;
  };

  const getStep2HotelInfo = (genContract) => {
    const generalContractorInfoOb = {};
    setValue(
      "generalContractId",
      genContract.generalContractorInfo.generalContractId
    );
    generalContractorInfoOb.generalContractId =
      genContract.generalContractorInfo.generalContractId;
    generalContractorInfoOb.annualGrossSale =
      genContract.generalContractorInfo.annualGrossSale;

    generalContractorInfoOb.selectedWork =
      genContract.generalContractorInfo.selectedWork;
    generalContractorInfoOb.isWorkInHospital =
      genContract?.generalContractorInfo?.isWorkInHospital.toString();
    generalContractorInfoOb.totalPayRoll =
      genContract.generalContractorInfo.totalPayRoll;
    generalContractorInfoOb.feinNum = genContract.generalContractorInfo.feinNum;
    generalContractorInfoOb.isMasonryWrk =
      genContract?.generalContractorInfo?.isMasonryWrk?.toString();
    generalContractorInfoOb.isCustomHomeWrk =
      genContract?.generalContractorInfo?.isCustomHomeWrk?.toString();
    generalContractorInfoOb.isCarpentry =
      genContract?.generalContractorInfo?.isCarpentry?.toString();
    generalContractorInfoOb.isBoilerInstall =
      genContract?.generalContractorInfo?.isBoilerInstall?.toString();
    generalContractorInfoOb.isConcreteWrk =
      genContract?.generalContractorInfo?.isConcreteWrk?.toString();
    generalContractorInfoOb.isAirConditionHeating =
      genContract?.generalContractorInfo?.isAirConditionHeating?.toString();
    generalContractorInfoOb.isEnvirnomentalCleanUp =
      genContract?.generalContractorInfo?.isEnvirnomentalCleanUp?.toString();
    generalContractorInfoOb._1099employee =
      genContract.generalContractorInfo._1099employee;
    generalContractorInfoOb.payrollOfEmp =
      genContract.generalContractorInfo.payrollOfEmp;
    generalContractorInfoOb.howmanyw2 =
      genContract?.generalContractorInfo?.howmanyw2?.toString();
    generalContractorInfoOb.costOfSubContractor =
      genContract.generalContractorInfo.costOfSubContractor;
    generalContractorInfoOb.persentOfSubContractorWrk =
      genContract.generalContractorInfo.persentOfSubContractorWrk;
    generalContractorInfoOb.persentOfEmprWrk =
      genContract.generalContractorInfo.persentOfEmprWrk;
    generalContractorInfoOb.doEmpNeedToAddCoverage =
      genContract.generalContractorInfo.doEmpNeedToAddCoverage;
    generalContractorInfoOb.subContractorCoverage =
      genContract?.generalContractorInfo?.subContractorCoverage?.toString();
    generalContractorInfoOb.priorLoss =
      genContract.generalContractorInfo.priorLoss;
    generalContractorInfoOb.claimType =
      genContract.generalContractorInfo.claimType;
    generalContractorInfoOb.claimDate = formattedDate(
      genContract.generalContractorInfo.claimDate
    );
    generalContractorInfoOb.anyClaim =
      genContract?.generalContractorInfo?.anyClaim?.toString();

    return generalContractorInfoOb;
  };

  const fillupStep1HotelInfoFromLocalStorage = (formData) => {
    if (formData) {
      setValue("firstName", formData.firstName);
      setValue("lastName", formData.lastName);
      setValue("businessName", formData.businessName);
      setValue("dbaname", formData.dbaname);
      setValue("hoursOfOperation", formData.hoursOfOperation);
      let hrOfOperation = formData.hoursOfOperation;
      hrOfOperation = hrOfOperation.split("-");
      setHoursOfOperation(hrOfOperation);
      setValue("typeOfBusiness", formData.typeOfBusiness);
      setValue("locationAddress", formData.locationAddress);
      setValue("locationCity", formData.locationCity);
      setValue("locationState", formData.locationState);
      setValue("locationZip", formData.locationZip);
      setValue("mailingAddress", formData.mailingAddress);
      setValue("mailingCity", formData.mailingCity);
      setValue("mailingState", formData.mailingState);
      setValue("mailingZip", formData.mailingZip);
      setValue("mobileNum", formData.mobileNum);
      setValue("workNum", formData.workNum);
      setValue("email", formData.email);
      setValue("coverage", formData.coverage);
      setValue("effectiveDate", formattedDate(formData.effectiveDate));
      setValue("agencyId", agencyId);
      setAddress(formData.locationAddress);
      setGarageAddress(formData.mailingAddress);
      if (formData.mailingAddress === formData.locationAddress) {
        setSameAsAddress(true);
      }
    }
  };

  const setHotelInfoInLocalStorage = (data) => {
    localStorage.setItem("step1", JSON.stringify(getStep1HotelInfo(data)));
    localStorage.setItem("step2", JSON.stringify(getStep2HotelInfo(data)));
    localStorage.setItem("customerId", data?.customerInfo?.customerId);
  };

  const loadInitialGasStationInfo = () => {
    const formData = JSON.parse(localStorage.getItem("step1"));
    if (true) {
      setLoading(true);
      axiosInstance
        .get(`${DEVURL}/${details?.generalContractorInfo?.generalContractId}`)
        .then((response) => {
          setLoading(false);
          fillupStep1HotelInfo(response.data);
          setHotelInfoInLocalStorage(response.data);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else if (formData) {
      fillupStep1HotelInfoFromLocalStorage(formData);
    }
  };

  useEffect(() => {
    loadInitialGasStationInfo();
  }, [details]);

  const handleFormSubmit = (data) => {
    stepHandler(2);
    localStorage.setItem("step1", JSON.stringify(data));
  };

  if (isLoading) {
    return loader();
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="tab-wizard wizard-circle wizard needs-validation"
      >
        <section>
          <h4 class="wrap-title mb-3">General Information</h4>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  First Name<span className="required-mark">*</span>
                </label>
                <input
                  id="firstName"
                  type="text"
                  className="form-control"
                  placeholder="Enter First Name"
                  {...register("firstName")}
                />
                <p className="text-danger">
                  {errors.firstName && <p>{errors.firstName.message}</p>}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  Last Name<span className="required-mark">*</span>
                </label>
                <input
                  id="lastName"
                  type="text"
                  className="form-control"
                  placeholder="Enter Last Name"
                  {...register("lastName")}
                />
                <p className="text-danger">
                  {errors.lastName && <p>{errors.lastName.message}</p>}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">
                  Business Name<span className="required-mark">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Business Name"
                  {...register("businessName")}
                />
                <p className="text-danger">{errors.businessName?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">DBA</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter DBA"
                  {...register("dbaname")}
                />
                <p className="text-danger">{errors.dbaname?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  <div className="d-flex">
                    Hours of Operation<span className="required-mark">*</span>
                    &nbsp;
                    <div>
                      <Badge bg="info">(Start and End Time)</Badge>
                    </div>
                  </div>
                </label>
                <BusinessHour
                  onTimeRangeChange={getTimeRange}
                  timeRange={hoursOfOperation}
                />

                <p className="text-danger">
                  {errors.hoursOfOperation && (
                    <p>{errors.hoursOfOperation.message}</p>
                  )}
                </p>
              </div>
            </div>

            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">
                  Mobile Number<span className="required-mark">*</span>
                </label>

                <Controller
                  name="mobileNum"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      class="form-control"
                      max={12}
                      placeholder="(XXX) XXX-XXXX"
                      value={formatTelNumber(mobileNum)}
                      {...register("mobileNum")}
                    />
                  )}
                />

                <p className="text-danger">{errors.mobileNum?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">Work Number</label>
                <Controller
                  name="workNum"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      class="form-control"
                      placeholder="(XXX) XXX-XXXX"
                      value={formatTelNumber(workNum)}
                      {...register("workNum")}
                    />
                  )}
                />
                <p className="text-danger">{errors.workNum?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">
                  Email Address<span className="required-mark">*</span>
                </label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Enter Email Address"
                  {...register("email")}
                />
                <p className="text-danger">{errors.email?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  Effective Date<span className="required-mark">*</span>
                </label>
                <input
                  id="effectiveDate"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Effective Date"
                  {...register("effectiveDate")}
                />
                <p className="text-danger">
                  {errors.effectiveDate && (
                    <p>{errors.effectiveDate.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="mb-3">
                <label className="brand-label">
                  Location Address<span className="required-mark">*</span>
                </label>
                <PlaceComponent
                  getLocationAddress={getLocation}
                  value={addesss}
                  type="profile-update"
                  name="locationAddress"
                  control={control}
                ></PlaceComponent>
                <p className="text-danger">
                  {errors.locationAddress && (
                    <p>{errors.locationAddress?.message}</p>
                  )}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mb-3">
                <label className="brand-label" style={{ display: "flex" }}>
                  Mailing Address<span className="required-mark">*</span>&nbsp;
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={sameAsAddress}
                      onChange={(e) => {
                        setSameAsAddress(e.target.checked);
                        setValue("mailingAddress", addesss);
                        setValue("mailingCity", locationCity);
                        setValue("mailingState", locationState);
                        setValue("mailingZip", locationZip);
                        setGarageAddress(addesss);
                      }}
                      id="same-as"
                    />
                    <label
                      className="form-check-label text-capitalize"
                      htmlFor="flexCheckDefault"
                    >
                      Same As Location Address
                    </label>
                  </div>
                </label>

                <PlaceComponent
                  getLocationAddress={getGarageAddress}
                  value={garageAddress}
                  type="profile-update"
                  readOnly={sameAsAddress}
                  name="mailingAddress"
                  control={control}
                ></PlaceComponent>
                <p className="text-danger">
                  {errors.mailingAddress && (
                    <p>{errors.mailingAddress.message}</p>
                  )}
                </p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <label className="brand-label">
                Business Type<span className="required-mark">*</span>
              </label>
              <div className="mb-3">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="typeOfBusiness"
                    id="yesCheck1"
                    value="GC"
                    {...register("typeOfBusiness")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    title="Full coverage, gross income, sub-cont cost, payroll"
                    htmlFor="yesCheck1"
                    data-bs-toggle="tooltip"
                    style={{ cursor: "pointer" }}
                  >
                    GC
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="typeOfBusiness"
                    id="noCheck1"
                    value="Artisan"
                    {...register("typeOfBusiness")}
                  />
                  <label
                    title="Plumbing, electrical, installation, flooring, handyman, etc."
                    className="form-check-label text-capitalize"
                    htmlFor="noCheck1"
                    data-bs-toggle="tooltip"
                    style={{ cursor: "pointer" }}
                  >
                    Artisan
                  </label>
                </div>
                <p className="text-danger">{errors.typeOfBusiness?.message}</p>
              </div>
            </div>
          </div>
        </section>
        <div className="text-end">
          <button className="btn btn-primary">Next</button>
        </div>
      </form>
    </>
  );
};

export default Step1;
