import React from "react";
import {
  NumberFormat,
  ThousandSeparator,
  formatDate,
  formatHours,
} from "../../../Common/Utils";

const HotelView = ({ details }) => {
  console.log("details gas", details);

  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="salon-view"
        style={{ width: "80%" }}
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header activity-status-table brand-offcanvas-header">
          <h5 id="offcanvasRightLabel">Hotel Commercial Application</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="activity-cust-data">
            <h6 className="mb-3">Details</h6>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab active`}
                  id="build-info"
                  data-bs-toggle="tab"
                  data-bs-target="#building-info-details"
                  type="button"
                  role="tab"
                  aria-controls="building-info-details"
                  aria-selected="true"
                >
                  General Information
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab`}
                  id="geo-location1"
                  data-bs-toggle="tab"
                  data-bs-target="#annual-info"
                  type="button"
                  role="tab"
                  aria-controls="annual-info"
                  aria-selected="false"
                >
                  Property Information
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab`}
                  id="geo-location1"
                  data-bs-toggle="tab"
                  data-bs-target="#general-liability"
                  type="button"
                  role="tab"
                  aria-controls="general-liability"
                  aria-selected="false"
                >
                  General Liability Information
                </button>
              </li>
            </ul>
            {details && (
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade active show"
                  id="building-info-details"
                  role="tabpanel"
                  aria-labelledby="build-info"
                >
                  <div className="activity-status-table">
                    <div className="table-responsive">
                      <table className="table table-striped table table-bordered">
                        <thead></thead>
                        <tbody id="body-render">
                          <tr>
                            <td>First Name</td>
                            <td>{details?.customerInfo?.firstName}</td>
                          </tr>
                          <tr>
                            <td>Last Name</td>
                            <td>{details?.customerInfo?.lastName}</td>
                          </tr>
                          <tr>
                            <td>Business Name</td>
                            <td>{details?.customerInfo?.businessName}</td>
                          </tr>
                          <tr>
                            <td>DBA</td>
                            <td>{details?.customerInfo?.dbaname}</td>
                          </tr>

                          <tr>
                            <td>Work Number</td>
                            <td>{details?.customerInfo?.workNum}</td>
                          </tr>
                          <tr>
                            <td>Mobile Number</td>
                            <td>{details?.customerInfo?.mobileNum}</td>
                          </tr>
                          <tr>
                            <td>Location Address</td>
                            <td>{details?.customerInfo?.locationAddress}</td>
                          </tr>
                          <tr>
                            <td>Mailing Address</td>
                            <td>{details?.customerInfo?.mailingAddress}</td>
                          </tr>
                          <tr>
                            <td>Email Address</td>
                            <td>{details?.customerInfo?.email}</td>
                          </tr>
                          <tr>
                            <td>Effective Date</td>
                            <td>
                              {details?.hotelInfo?.effectiveDate &&
                                formatDate(details?.hotelInfo?.effectiveDate)}
                            </td>
                          </tr>

                          <tr>
                            <td>Coverage</td>
                            <td>
                              {details?.hotelInfo?.coverage?.map(
                                (type, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge rounded-pill bg-info text-light me-1"
                                    >
                                      {type}
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade`}
                  id="annual-info"
                  role="tabpanel"
                  aria-labelledby="build-info"
                >
                  <div className="activity-status-table">
                    <div className="table-responsive">
                      <table className="table table-striped table table-bordered">
                        <thead></thead>
                        <tbody id="body-render">
                          <tr>
                            <td>Year Built</td>
                            <td>{details?.hotelInfo?.yearBuilt}</td>
                          </tr>
                          <tr>
                            <td>No. of Buildings</td>
                            <td>
                              <ThousandSeparator
                                value={details?.hotelInfo?.noOfBuilding}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>BPP/Inventory Limit?</td>
                            <td>
                              <NumberFormat
                                value={details?.hotelInfo?.inventoryLimit}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Sq. Ft. Of Each Building?</td>
                            <td>
                              <ThousandSeparator
                                value={details?.hotelInfo?.eachBuilding}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Sign Value</td>
                            <td>
                              <NumberFormat
                                value={details?.hotelInfo?.signValue}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Total Area?</td>
                            <td>
                              <ThousandSeparator
                                value={details?.hotelInfo?.totalArea}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>No. Of Stories(Each Building)?</td>
                            <td>
                              <ThousandSeparator
                                value={
                                  details?.hotelInfo?.noOfStoriesEachBuilding
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Any Registered Franchise?</td>
                            <td>
                              {details?.hotelInfo?.isRegisteredFranchise === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Buildings Stairs With?</td>
                            <td>
                              {details?.hotelInfo?.isBuildingsStairs === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Any Elevators?</td>
                            <td>
                              {details?.hotelInfo?.isElevators === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Any Update In Construction?</td>
                            <td>
                              {details?.hotelInfo?.isUpdateConstruction === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Any Banquet?</td>
                            <td>
                              {details?.hotelInfo?.isBanquet === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Banquet Sq. Ft.?</td>
                            <td>
                              <ThousandSeparator
                                value={details?.hotelInfo?.banquetSqFt}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Banquet Capacity?</td>
                            <ThousandSeparator
                              value={details?.hotelInfo?.banquetCapacity}
                            />
                          </tr>
                          <tr>
                            <td>Any GYM?</td>
                            <td>
                              {details?.hotelInfo?.isGym === "1" ? "Yes" : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Maintenance Available?</td>
                            <td>
                              {details?.hotelInfo?.isGymMaintenanceAvailable ===
                              "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>

                          <tr>
                            <td>GYM Sq. Ft.?</td>
                            <td>
                              <ThousandSeparator
                                value={details?.hotelInfo?.gymSqft}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>GYM Hours</td>
                            <td>{details?.hotelInfo?.gymHour}</td>
                          </tr>

                          <tr>
                            <td>Any Swimming Pools?</td>
                            <td>
                              {details?.hotelInfo?.isSwimmingPool === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Swimming Pool Location?</td>
                            <td>
                              {details?.hotelInfo?.isSwimmingPoolLocation ===
                              "1"
                                ? "Inside"
                                : "Outside"}
                            </td>
                          </tr>
                          <tr>
                            <td>Well Fenced?</td>
                            <td>
                              {details?.hotelInfo?.isSwimmingWellFenced === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Life Guard Available?</td>
                            <td>
                              {details?.hotelInfo?.isSwimmingLifeGuard === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>

                          <tr>
                            <td>Automatic Door Shut Off?</td>
                            <td>
                              {details?.hotelInfo?.isSwimmingDoorShutOff === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Diving Boards?</td>
                            <td>
                              {details?.hotelInfo?.isSwimmingDivingBoard === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Any Hot Tub?</td>
                            <td>
                              {details?.hotelInfo?.isSwimmingHotTub === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>

                          <tr>
                            <td>Hot Tub Location?</td>
                            <td>
                              {details?.hotelInfo?.isSwimmingHotTubLocation ===
                              "1"
                                ? "Inside"
                                : "Outside"}
                            </td>
                          </tr>
                          <tr>
                            <td>Swimming Pool Depth(in ft.)</td>
                            <td>
                              <ThousandSeparator
                                value={details?.hotelInfo?.swimmingPoolDepth}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>No. Of Swimming Pools?</td>
                            <td>
                              <ThousandSeparator
                                value={details?.hotelInfo?.swimmingNoOfPools}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>No. Of Hot Tubs?</td>
                            <td>
                              <ThousandSeparator
                                value={details?.hotelInfo?.swimmingNoOfHotTubs}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Bar Or Dance Floor?</td>
                            <td>
                              {details?.hotelInfo?.isBarDanceFloor === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Any Happy Hours?</td>
                            <td>
                              {details?.hotelInfo?.isHappyHours === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Age Group?</td>
                            <td>{details?.hotelInfo?.ageGroup}</td>
                          </tr>
                          <tr>
                            <td>Bar/Dance Hours</td>
                            <td>
                              {details?.hotelInfo?.barDanceHours &&
                                formatHours(details?.hotelInfo?.barDanceHours)}
                            </td>
                          </tr>

                          <tr>
                            <td>Any Restaurant?</td>
                            <td>
                              {details?.hotelInfo?.isRestaurant === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Seating Capacity?</td>
                            <td>
                              <ThousandSeparator
                                value={details?.hotelInfo?.seatingCapacity}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Restaurant Hours</td>
                            <td>
                              {details?.hotelInfo?.restaurantHours &&
                                formatHours(
                                  details?.hotelInfo?.restaurantHours
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>No. Of Emergencies Exits?</td>
                            <td>
                              <ThousandSeparator
                                value={details?.hotelInfo?.noOfEmergencyExits}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Crime Coverage Limit?</td>
                            <td>
                              <NumberFormat
                                value={details?.hotelInfo?.crimeCoverageLimit}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>No. Of Managers Per Shift?</td>
                            <td>
                              <ThousandSeparator
                                value={details?.hotelInfo?.noOfManagersPerShift}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Managers Checkin & Checkout Time</td>
                            <td>
                              {details?.hotelInfo?.managersCheckinCheckout &&
                                formatHours(
                                  details?.hotelInfo?.managersCheckinCheckout
                                )}
                            </td>
                          </tr>

                          <tr>
                            <td>Last Roof Updated</td>
                            <td>
                              {details?.hotelInfo?.lastRoofUpdated &&
                                formatDate(details?.hotelInfo?.lastRoofUpdated)}
                            </td>
                          </tr>
                          <tr>
                            <td>Last Wiring Updated</td>
                            <td>
                              {details?.hotelInfo?.lastWiringUpdated &&
                                formatDate(
                                  details?.hotelInfo?.lastWiringUpdated
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Last Plumbing Updated</td>
                            <td>
                              {details?.hotelInfo?.lastPlumbingUpdated &&
                                formatDate(
                                  details?.hotelInfo?.lastPlumbingUpdated
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Last Heating Updated</td>
                            <td>
                              {details?.hotelInfo?.lastHeatingUpdated &&
                                formatDate(
                                  details?.hotelInfo?.lastHeatingUpdated
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Room Rent Out Basis?</td>
                            <td>
                              {details?.hotelInfo?.isRoomRentOutBasis === "1"
                                ? "Per Day"
                                : "Monthly"}
                            </td>
                          </tr>
                          <tr>
                            <td>Shuttle Service?</td>
                            <td>
                              {details?.hotelInfo?.isShuttleService === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Rooms With Kitchen?</td>
                            <td>
                              {" "}
                              {details?.hotelInfo?.isRoomWithKitchen === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>

                          <tr>
                            <td>Play Ground?</td>
                            <td>
                              {details?.hotelInfo?.isPlayGround === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Entertainment Rooms?</td>
                            <td>
                              {" "}
                              {details?.hotelInfo?.isEntertainmentRoom === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>

                          <tr>
                            <td>Need Cyber Liability Coverage?</td>
                            <td>
                              {" "}
                              {details?.hotelInfo?.isCyberLiabilityCoverage ===
                              "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Subsidized Rates(Students/Elder)?</td>
                            <td>
                              {" "}
                              {details?.hotelInfo?.isSubsidizedRate === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>How Many Rooms?</td>
                            <td>
                              <ThousandSeparator
                                value={details?.hotelInfo?.howManySubRooms}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Average Occupancy Rate?</td>
                            <td>
                              <NumberFormat
                                value={details?.hotelInfo?.averageOccupancyRate}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Construction Type?</td>
                            <td>
                              {details?.hotelInfo?.constructionType?.map(
                                (type, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge rounded-pill bg-info text-light me-1"
                                    >
                                      {type}
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Protective Safeguard?</td>
                            <td>
                              {details?.hotelInfo?.protectiveSafeguard?.map(
                                (type, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge rounded-pill bg-info text-light me-1"
                                    >
                                      {type}
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade`}
                  id="general-liability"
                  role="tabpanel"
                  aria-labelledby="build-info"
                >
                  <div className="activity-status-table">
                    <div className="table-responsive">
                      <table className="table table-striped table table-bordered">
                        <thead></thead>
                        <tbody id="body-render">
                          <tr>
                            <td>Room rates per day?</td>
                            <td>
                              <NumberFormat
                                value={details?.hotelInfo?.roomRatesPerDay}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Liquor Sales?</td>
                            <td>
                              <NumberFormat
                                value={details?.hotelInfo?.liquorSales}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Restaurant Sales (if any)?</td>
                            <td>
                              <NumberFormat
                                value={details?.hotelInfo?.restaurantSales}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Annual Gross Sales?</td>
                            <td>
                              <NumberFormat
                                value={details?.hotelInfo?.annualGrossSales}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Total Payroll</td>
                            <td>
                              <NumberFormat
                                value={details?.hotelInfo?.totalPayroll}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>FEIN Number</td>
                            <td>{details?.hotelInfo?.feinNumer}</td>
                          </tr>
                          <tr>
                            <td>No. of Employees</td>
                            <td>
                              <ThousandSeparator
                                value={details?.hotelInfo?.noOfEmployees}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Bank Details</td>
                            <td>{details?.hotelInfo?.bankDetails}</td>
                          </tr>
                          <tr>
                            <td>What is the Bank Property Value?</td>
                            <td>
                              <NumberFormat
                                value={details?.hotelInfo?.bankPropertyValue}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Umbrella Limit?</td>
                            <td>
                              <NumberFormat
                                value={details?.hotelInfo?.umbrellaLimit}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Worker’s Comp Limit?</td>
                            <td>
                              <NumberFormat
                                value={details?.hotelInfo?.workersCompLimit}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>No. Of Part Timer?</td>
                            <td>
                              <ThousandSeparator
                                value={details?.hotelInfo?.noOfPartTimer}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>No. Of Full Timer?</td>
                            <td>
                              <ThousandSeparator
                                value={details?.hotelInfo?.noOfFullTimer}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>No. Of Clerical Staffs?</td>
                            <td>
                              <ThousandSeparator
                                value={details?.hotelInfo?.noOfClericalStaffs}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>No. Of Non-Clerical Staffs?</td>
                            <td>
                              {" "}
                              <ThousandSeparator
                                value={
                                  details?.hotelInfo?.noOfNonClericalStaffs
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Any Previous Workers Comp Claim?</td>
                            <td>
                              {details?.hotelInfo
                                ?.isPreviousWorkersCompClaim === "1"
                                ? "Yes"
                                : details?.hotelInfo
                                ?.isPreviousWorkersCompClaim === "0" && "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Owner Wants To Be Included/Excluded From The
                              Payroll?
                            </td>
                            <td>
                              {" "}
                              {details?.hotelInfo
                                ?.isOwnerIncludedExcludedPayroll === "1"
                                ? "Included"
                                : details?.hotelInfo
                                ?.isOwnerIncludedExcludedPayroll === "0" && "Excluded"}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Return Policy Available For Employee After An
                              Accident Or Sickness?
                            </td>
                            <td>
                              {" "}
                              {details?.hotelInfo
                                ?.isReturnPolicyEmployeeAccident === "1"
                                ? "Yes"
                                :details?.hotelInfo
                                ?.isReturnPolicyEmployeeAccident === "0" && "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Prior Losses?</td>
                            <td>
                              <NumberFormat
                                value={details?.hotelInfo?.priorLosses}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Any Claim in past 5 years?</td>
                            <td>
                              {details?.hotelInfo?.isAnyClaims === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>

                          <tr>
                            <td>Claim Type?</td>
                            <td>
                              {details?.hotelInfo?.isClaimType === "1"
                                ? "Property"
                                :details?.hotelInfo?.isClaimType === "0" && "GL"}
                            </td>
                          </tr>
                          <tr>
                            <td>Claim Date?</td>
                            <td>
                              {details?.hotelInfo?.claimDate &&
                                formatDate(details?.hotelInfo?.claimDate)}
                            </td>
                          </tr>
                          <tr>
                            <td>Loss Run (Years)</td>
                            <td>{details?.hotelInfo?.lossRunYears}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HotelView;
