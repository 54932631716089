import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import "sweetalert2/dist/sweetalert2.min.css";
import * as yup from "yup";
import { currentYear, floatRegex } from "../../Common/regex";
import { useLocation } from "react-router-dom";
import useExitConfirmationHandler from "../../hooks/useExitConfirmationHandler.hook";

const validationSchema = yup.object().shape({
  lastRoofUpdate: yup
    .string()
    .nullable(true)
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);
      return inputDate < currentDate;
    })
    .label("Roof date"),

  lastWiringUpdate: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Electrical date"),

  lastPlumbingUpdate: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Plumbing date"),
  lastHeating: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Havc date"),
  claimDate: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .when("anyClaim", (anyClaim, schema) => {
      if (anyClaim && anyClaim.includes("1")) {
        return schema.required("Claim date is required");
      } else return schema.notRequired();
    })
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Claim date"),
  workType: yup
    .array()
    .typeError("Work type is required")
    .min(1, "Work type is required")
    .of(yup.string())
    .required("Work type is required")
    .label("Work type"),

  yearOfConstruction: yup
    .string()
    .trim()
    .required("Year of construction is required")
    .matches(floatRegex, "Please enter a valid no. of years")
    .test(
      "is-past-or-current-year",
      "Year must be in the past or current year",
      function (value) {
        return value <= currentYear;
      }
    )
    .label("Year of construction"),
  noOfLocation: yup
    .string()
    .trim()
    .required("No. of locations is required")
    .matches(floatRegex, "Please enter a valid no. of locations")
    .label("No. of locations"),
  noOfBuildingEachLocation: yup
    .string()
    .trim()
    .required("No. of buildings in each location is required")
    .matches(floatRegex, "Please enter a valid no. of buildings")
    .label("No. of buildings in each location"),
  noOfBeauticians: yup
    .string()
    .trim()
    .required("No. of beauticians in each service is required")
    .matches(floatRegex, "Please enter a valid no. of beauticians")
    .label("No. of beauticians in each service"),
  busInterruptionLimit: yup
    .string()
    .trim()
    .required("Business interruption is required")
    .label("Business interruption"),
  signLimit: yup
    .string()
    .trim()
    .required("Sign limit is required")
    .label("Sign limit"),
  moneyNsecurity: yup
    .string()
    .trim()
    .required("Money & securities limit is required")
    .matches(floatRegex, "Please enter valid money & securities limit")
    .label("Money & securities limit"),
  theftLimit: yup
    .string()
    .trim()
    .required("Theft limit is required")
    .label("Theft limit"),
  buildingValue: yup
    .string()
    .trim()
    .required("Building value is required")
    .label("Building value"),
  equipmentValue: yup
    .string()
    .trim()
    .required("Equipments value is required")
    .label("Equipments value"),
  sqftspace: yup.string().trim().required("Sq. ft. is required").label("Sq. ft."),
  anyClaim: yup.string().required("Any claim is required").label("Any claim"),
  // priorLoss: yup
  //   .string()
  //   .nullable(true)
  //   .when("anyClaim", (anyClaim, schema) => {
  //     if (anyClaim && anyClaim?.includes("1")) {
  //       return schema.required("Prior losses is required");
  //     } else return schema.notRequired();
  //   })
  //   .label("Prior losses"),
  claimType: yup
    .string()
    .when("anyClaim", (anyClaim, schema) => {
      if (anyClaim && anyClaim?.includes("1")) {
        return schema.required("Claim type is required");
      } else return schema.notRequired();
    })
    .label("Claim type"),
  lossRunYear: yup
    .string()
    .trim()
    .nullable(true)
    .matches(floatRegex, "Please enter a valid no. of years")
    .test(
      "is-past-or-current-year",
      "Year must be in the past or current year",
      function (value) {
        return value <= currentYear;
      }
    )
    .label("Loss run"),
  licensedStatus: yup
    .string()
    .trim()
    .required("License is required")
    .label("License"),
});

const Step2 = ({ stepHandler, loader }) => {
  const  handleExitButton  = useExitConfirmationHandler();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    reset,
    watch,
    control,
  } = useForm({
    defaultValues: {
      yearOfConstruction: null,
      noOfLocation: null,
      noOfBuildingEachLocation: null,
      noOfBeauticians: null,
      busInterruptionLimit: null,
      signLimit: null,
      moneyNsecurity: null,
      workType: [],
      theftLimit: null,
      buildingValue: null,
      equipmentValue: null,
      sqftspace: null,
      totalFullTimeEmployee: null,
      partTimeEmployee: null,
      priorLoss: null,
      licensedStatus: "",
      lastRoofUpdate: null,
      lastWiringUpdate: null,
      lastPlumbingUpdate: null,
      lastHeating: null,
      claimDate: null,
      lossRunYear: null,
      anyClaim: "",
      claimType: "",
    },
    resolver: yupResolver(validationSchema),
  });
  const query = new URLSearchParams(useLocation().search);
  const salonId = query.get("primaryid");

  const claimsValue = watch("anyClaim");

  const fillupStep2HotelInfoFromLocalStorage = (formData) => {
    setValue("yearOfConstruction", formData.yearOfConstruction);
    setValue("workType", formData.workType);
    setValue("noOfLocation", formData.noOfLocation);
    setValue("noOfBuildingEachLocation", formData.noOfBuildingEachLocation);
    setValue("noOfBeauticians", formData.noOfBeauticians);
    setValue("busInterruptionLimit", formData.busInterruptionLimit);
    setValue("signLimit", formData.signLimit);
    setValue("moneyNsecurity", formData.moneyNsecurity);
    setValue("theftLimit", formData.theftLimit);
    setValue("buildingValue", formData.buildingValue);
    setValue("equipmentValue", formData.equipmentValue);
    setValue("sqftspace", formData.sqftspace);
    setValue("partTimeEmployee", formData.partTimeEmployee);
    setValue("totalFullTimeEmployee", formData.totalFullTimeEmployee);
    setValue("licensedStatus", formData.licensedStatus);
    setValue("priorLoss", formData.priorLoss);
    setValue("lastRoofUpdate", formData.lastRoofUpdate);
    setValue("lastWiringUpdate", formData.lastWiringUpdate);
    setValue("lastPlumbingUpdate", formData.lastPlumbingUpdate);
    setValue("lastHeating", formData.lastHeating);
    setValue("lossRunYear", formData.lossRunYear);
    setValue("anyClaim", formData.anyClaim);
    setValue("claimDate", formData.claimDate);
    setValue("claimType", formData.claimType);
  };

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("step2"));
    if (formData) {
      fillupStep2HotelInfoFromLocalStorage(formData);
    }
  }, []);

  useEffect(() => {
    if (claimsValue?.includes("0")) {
      setValue("claimDate", null);
      setValue("claimType", "");
      // setValue("priorLoss", null);
    }
  }, [claimsValue]);

  const setStep2ToLocalStorage = (data) => {
    localStorage.setItem("step2", JSON.stringify(data));
  };

  const handleFormSubmit = async (data) => {
    if (data.lastRoofUpdate?.trim().length === 0) {
      data.lastRoofUpdate = null;
    }
    if (data.lastWiringUpdate?.trim().length === 0) {
      data.lastWiringUpdate = null;
    }
    if (data.lastPlumbingUpdate?.trim().length === 0) {
      data.lastPlumbingUpdate = null;
    }
    if (data.lastHeating?.trim().length === 0) {
      data.lastHeating = null;
    }
    stepHandler(3);
    setStep2ToLocalStorage(data);
  };

  return (
    <>
      <form
        className="tab-wizard wizard-circle wizard needs-validation"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <section>
          <h4 className="wrap-title mb-3">Building Information</h4>
          <div className="row">
            <div class="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Year of Construction?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="yearOfConstruction"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="yearOfConstruction"
                      className="form-control"
                      name="yearOfConstruction"
                      onValueChange={(values) => {
                        setValue("yearOfConstruction", values.value);
                      }}
                      placeholder="Enter Year of Construction"
                      {...register("yearOfConstruction")}
                    />
                  )}
                />

                <p className="text-danger">
                  {errors.yearOfConstruction?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  No. of Locations?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="noOfLocation"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="noOfLocation"
                      className="form-control"
                      thousandSeparator={true}
                      name="noOfLocation"
                      onValueChange={(values) => {
                        setValue("noOfLocation", values.floatValue);
                      }}
                      placeholder="Enter No. of Locations"
                      {...register("noOfLocation")}
                    />
                  )}
                />
                <p className="text-danger">{errors.noOfLocation?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  No. of Buildings in Each Location?
                  <span className="required-mark">*</span>
                </label>
                <Controller
                  name="noOfBuildingEachLocation"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="noOfBuildingEachLocation"
                      className="form-control"
                      thousandSeparator={true}
                      name="noOfBuildingEachLocation"
                      onValueChange={(values) => {
                        setValue("noOfBuildingEachLocation", values.floatValue);
                      }}
                      placeholder="Enter No. of Buildings in Each Location?"
                      {...register("noOfBuildingEachLocation")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.noOfBuildingEachLocation?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Business Interruption Limit?
                  <span className="required-mark">*</span>
                </label>
                <Controller
                  name="busInterruptionLimit"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="busInterruptionLimit"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="busInterruptionLimit"
                      onValueChange={(values) => {
                        setValue("busInterruptionLimit", values.floatValue);
                      }}
                      placeholder="Enter Business Interruption Limit"
                      {...register("busInterruptionLimit")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.busInterruptionLimit?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Sign Limit?
                </label>
                <span className="required-mark">*</span>
                <Controller
                  name="signLimit"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="signLimit"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="signLimit"
                      onValueChange={(values) => {
                        setValue("signLimit", values.floatValue);
                      }}
                      placeholder="Enter Sign Limit"
                      {...register("signLimit")}
                    />
                  )}
                />
                <p className="text-danger">{errors.signLimit?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label d-grid">
                  <div>
                    Money & Securities Limit?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <Controller
                  name="moneyNsecurity"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="moneyNsecurity"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="moneyNsecurity"
                      onValueChange={(values) => {
                        setValue("moneyNsecurity", values.floatValue);
                      }}
                      placeholder="Enter Money & Securities Limit"
                      {...register("moneyNsecurity")}
                    />
                  )}
                />
                <p className="text-danger">{errors.moneyNsecurity?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label d-grid">
                  <div>
                    Theft Limit?<span className="required-mark">*</span>
                  </div>
                </label>
                <Controller
                  name="theftLimit"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="theftLimit"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="theftLimit"
                      onValueChange={(values) => {
                        setValue("theftLimit", values.floatValue);
                      }}
                      placeholder="Enter Theft Limit"
                      {...register("theftLimit")}
                    />
                  )}
                />
                <p className="text-danger">{errors.theftLimit?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Building Value?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="buildingValue"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      id="buildingValue"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="buildingValue"
                      onValueChange={(values) => {
                        setValue("buildingValue", values.floatValue);
                      }}
                      placeholder="Enter Building Value"
                      {...register("buildingValue")}
                    />
                  )}
                />
                <p className="text-danger">{errors.buildingValue?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Equipments Value?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="equipmentValue"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      id="equipmentValue"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="equipmentValue"
                      onValueChange={(values) => {
                        setValue("equipmentValue", values.floatValue);
                      }}
                      placeholder="Enter Equipments Value"
                      {...register("equipmentValue")}
                    />
                  )}
                />
                <p className="text-danger">{errors.equipmentValue?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Sq. Ft.?<span className="required-mark">*</span>
                </label>

                <Controller
                  name="sqftspace"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="sqftspace"
                      className="form-control"
                      thousandSeparator={true}
                      name="sqftspace"
                      onValueChange={(values) => {
                        setValue("sqftspace", values.floatValue);
                      }}
                      placeholder="Enter Sq. Ft."
                      {...register("sqftspace")}
                    />
                  )}
                />
                <p className="text-danger">{errors.sqftspace?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  No. of beauticians for each service?
                  <span className="required-mark">*</span>
                </label>
                <Controller
                  name="noOfBeauticians"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="noOfBeauticians"
                      className="form-control"
                      thousandSeparator={true}
                      name="noOfBeauticians"
                      onValueChange={(values) => {
                        setValue("noOfBeauticians", values.floatValue);
                      }}
                      placeholder="Enter No. of beauticians for each service"
                      {...register("noOfBeauticians")}
                    />
                  )}
                />
                <p className="text-danger">{errors.noOfBeauticians?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label d-grid">
                  <div>
                    Are They Licensed?<span className="required-mark">*</span>
                  </div>
                </label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="yesLicensed"
                    value="1"
                    {...register("licensedStatus")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="yesLicensed"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="noLicensed"
                    value="0"
                    {...register("licensedStatus")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="noLicensed"
                  >
                    No
                  </label>
                </div>
                <p className="text-danger">{errors.licensedStatus?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  How Many Part Timer?
                </label>
                <Controller
                  name="partTimeEmployee"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="partTimeEmployee"
                      className="form-control"
                      thousandSeparator={true}
                      name="partTimeEmployee"
                      onValueChange={(values) => {
                        setValue("partTimeEmployee", values.value);
                      }}
                      placeholder="Enter No. of Part Timer"
                      {...register("partTimeEmployee")}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  How Many Full Timer?
                </label>
                <Controller
                  name="totalFullTimeEmployee"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="totalFullTimeEmployee"
                      className="form-control"
                      thousandSeparator={true}
                      name="totalFullTimeEmployee"
                      onValueChange={(values) => {
                        setValue("totalFullTimeEmployee", values.value);
                      }}
                      placeholder="Enter No. of Full Timer"
                      {...register("totalFullTimeEmployee")}
                    />
                  )}
                />
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label d-grid">Loss Runs (Year)</label>
                <Controller
                  name="lossRunYear"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="lossRunYear"
                      className="form-control"
                      name="lossRunYear"
                      onValueChange={(values) => {
                        setValue("lossRunYear", values.value);
                      }}
                      placeholder="Enter Loss Run (Years)"
                      {...register("lossRunYear")}
                    />
                  )}
                />
                <p className="text-danger">{errors.lossRunYear?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label text-capitalize">
                  Last Roof Updated?
                </label>
                <input
                  id="lastRoofUpdate"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Last Roof Update"
                  {...register("lastRoofUpdate")}
                />
                <p className="text-danger">
                  {errors.lastRoofUpdate && (
                    <p>{errors.lastRoofUpdate.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label text-capitalize">
                  Last Wiring Updated?
                </label>
                <input
                  id="lastWiringUpdate"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Last Wiring Update"
                  {...register("lastWiringUpdate")}
                />
                <p className="text-danger">
                  {errors.lastWiringUpdate && (
                    <p>{errors.lastWiringUpdate.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label text-capitalize">
                  Last Plumbing Updated?
                </label>
                <input
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Last Plumbing Update"
                  id="lastPlumbingUpdate"
                  {...register("lastPlumbingUpdate")}
                />
                <p className="text-danger">
                  {errors.lastPlumbingUpdate && (
                    <p>{errors.lastPlumbingUpdate.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label text-capitalize">
                  Last Heating Updated?
                </label>
                <input
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Last Heating Update"
                  id="lastHeating"
                  name="lastHeating"
                  {...register("lastHeating")}
                />
                <p className="text-danger">
                  {errors.lastHeating && <p>{errors.lastHeating.message}</p>}
                </p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                    {" "}
                    Any Claim in past 5 years?
                    <span className="required-mark">*</span>
                  </div>
                </label>

                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="claimYes"
                    value="1"
                    onSelect={(e) => {
                      console.log("yesbutton", e);
                    }}
                    {...register("anyClaim")}
                  />
                  <label class="form-check-label" for="claimYes">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="claimNo"
                    value="0"
                    {...register("anyClaim")}
                  />
                  <label class="form-check-label" for="claimNo">
                    No
                  </label>
                </div>
                <p className="text-danger">
                  {errors.anyClaim && <p>{errors.anyClaim.message}</p>}
                </p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>Claim Type?</div>
                </label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="propertyCheck"
                    value="Property"
                    disabled={!claimsValue?.includes("1")}
                    {...register("claimType")}
                  />
                  <label class="form-check-label" for="propertyCheck">
                    Property
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="GLICheck"
                    value="GL"
                    disabled={!claimsValue?.includes("1")}
                    {...register("claimType")}
                  />
                  <label class="form-check-label" for="GLICheck">
                    GL
                  </label>
                </div>

                <p className="text-danger">
                  {errors.claimType && <p>{errors.claimType.message}</p>}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label text-capitalize">
                  Claim Date?
                </label>
                <input
                  id="claimDate"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Claim Date"
                  disabled={!claimsValue?.includes("1")}
                  {...register("claimDate")}
                />
                <p className="text-danger">
                  {errors.claimDate && <p>{errors.claimDate.message}</p>}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label d-grid">Prior Losses</label>
                <Controller
                  name="priorLoss"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="priorLoss"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="priorLoss"
                      onValueChange={(values) => {
                        setValue("priorLoss", values.floatValue);
                      }}
                      placeholder="Enter if any prior losses"
                      {...register("priorLoss")}
                    />
                  )}
                />
                <p className="text-danger">{errors.priorLoss?.message}</p>
              </div>
            </div>
            <div class="col-md-12 col-lg-12">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                    What Is The Type Of Work?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div className="row">
                  <div className="col-md-3 col-lg-3">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="hairCuttingCheck"
                        value="Hair Cutting"
                        {...register("workType")}
                      />
                      <label class="form-check-label" for="hairCuttingCheck">
                        Hair Cutting
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="hairStylingCheck"
                        value="Hair Styling"
                        {...register("workType")}
                      />
                      <label class="form-check-label" for="hairStylingCheck">
                        Hair Styling
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="nailsCheck"
                        value="Nails"
                        {...register("workType")}
                      />
                      <label class="form-check-label" for="nailsCheck">
                        Nails
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="spasCheck"
                        value="Spas"
                        {...register("workType")}
                      />
                      <label class="form-check-label" for="spasCheck">
                        Spas
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="falseLashesCheck"
                        value="False Lashes"
                        {...register("workType")}
                      />
                      <label class="form-check-label" for="falseLashesCheck">
                        False Lashes
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="makeupCheck"
                        value="Makeup"
                        {...register("workType")}
                      />
                      <label class="form-check-label" for="makeupCheck">
                        Makeup
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="tattoosCheck"
                        value="Tattoos"
                        {...register("workType")}
                      />
                      <label class="form-check-label" for="tattoosCheck">
                        Tattoos
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="laserCheck"
                        value="Laser"
                        {...register("workType")}
                      />
                      <label class="form-check-label" for="laserCheck">
                        Laser
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="hairRemovalCheck"
                        value="Hair Removal"
                        {...register("workType")}
                      />
                      <label class="form-check-label" for="hairRemovalCheck">
                        Hair Removal
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="pedicureCheck"
                        value="Pedicure"
                        {...register("workType")}
                      />
                      <label class="form-check-label" for="pedicureCheck">
                        Pedicure
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="manicureCheck"
                        value="Manicure"
                        {...register("workType")}
                      />
                      <label class="form-check-label" for="manicureCheck">
                        Manicure
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="threadingCheck"
                        value="Threading"
                        {...register("workType")}
                      />
                      <label class="form-check-label" for="threadingCheck">
                        Threading
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="nosePiercingCheck"
                        value="Nose piercing"
                        {...register("workType")}
                      />
                      <label class="form-check-label" for="nosePiercingCheck">
                        Nose piercing
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="waxingCheck"
                        value="Waxing"
                        {...register("workType")}
                      />
                      <label class="form-check-label" for="waxingCheck">
                        Waxing
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="henaCheck"
                        value="Henna"
                        {...register("workType")}
                      />
                      <label class="form-check-label" for="henaCheck">
                        Henna
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="massageCheck"
                        value="Massage"
                        {...register("workType")}
                      />
                      <label class="form-check-label" for="massageCheck">
                        Massage
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="tanningCheck"
                        value="Tanning"
                        {...register("workType")}
                      />
                      <label class="form-check-label" for="tanningCheck">
                        Tanning
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="earPiercingCheck"
                        value="Ear Piercing"
                        {...register("workType")}
                      />
                      <label class="form-check-label" for="earPiercingCheck">
                        Ear Piercing
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="othersWorkTypeCheck"
                        value="Others"
                        {...register("workType")}
                      />
                      <label class="form-check-label" for="othersWorkTypeCheck">
                        Others
                      </label>
                    </div>
                  </div>
                </div>
                <p className="text-danger">{errors.workType?.message}</p>
              </div>
            </div>

            <div className="d-flex justify-content-end">
            {salonId && (
                <button
                  type="button"
                  onClick={()=>handleExitButton('Salon Insurance Application')}
                  className="btn btn-danger ms-2 text-capitalize"
                >
                  Exit if no changes required
                </button>
              )}
              <button
                className="btn btn-warning ms-2"
                type="button"
                onClick={() => stepHandler(1)}
              >
                Previous
              </button>
              <button className="btn btn-primary ms-2">Next</button>
            </div>
          </div>
        </section>
      </form>
    </>
  );
};

export default Step2;
