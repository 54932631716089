import Card from "react-bootstrap/Card";

const Footer = () => {
  return (
    <>
      <div align="center">
        <Card border="light">
          <Card.Body>
            <Card.Text>
              © 2019 - {new Date().getFullYear()} Agents And Carriers, Inc. All rights reserved
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default Footer;
