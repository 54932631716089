import { IconCirclePlus, IconFileExport, IconRefresh, IconSearch } from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";
import { downloadExcel } from "react-export-table-to-excel";
import { ThreeDots } from "react-loader-spinner";
import { Link, useLocation } from "react-router-dom";
import { default as axios } from "../../../config/axios-config";
import "../../../portal-assets/styles.css";
import { showDateFormat } from "../../helper/Helper";
import CustomerDetails from "../CustomerDetails";
import EditHealthcare from "../activities/healthcare/EditHealthcare";
import Leads from "./Leads";
import { useSelector } from "react-redux";
import ViewHealthcare from "./healthcare/ViewHealthcare";
import Group from "./healthcare/Group";
import Life from "./healthcare/Life";
const DEVURL = process.env.REACT_APP_ANC_API + "/api/Health";

const HealthCare = () => {
  const userData = useSelector((state) => state.auth.user);
  const query = new URLSearchParams(useLocation().search);
  const tab = query.get("tab");
  const agencyId = localStorage.getItem("AgencyId");
  const [loader, setLoader] = useState(false);
  const [loader_excel, setLoader_excel] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  let [perPage, setPerPage] = useState(10);
  const [nextButtonEnable, setNextButtonEnable] = useState(false);
  const [pervButtonEnable, setPrevButtonEnable] = useState(true);
  const [activityLists, setActivityLists] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [nextPage, setNextPage] = useState(0);
  const [previousPage, setPreviousPage] = useState(1);
  const [searchItem, setSearchItem] = useState("");

  const healthCareTableRef = useRef(null);
  const getHealthApplyAgencyId = async (type = null) => {
    setLoader(true);
    let currentP = 1;
    if (type) {
      if (type === "next") {
        currentP = nextPage;
      } else {
        currentP = previousPage <= 1 ? 1 : previousPage;
      }
    }
    let filter = searchItem ?? null;
    if (type === "reset") {
      setSearchItem("");
    }

    await axios
      .get(`${DEVURL}/getHealthAppbyAgencyId?agencyId=${agencyId}&page=${currentP}&pageSize=${perPage}&searchText=${searchItem}&agentId=${userData.agentId}`)
      .then((response) => {
        if (response.status === 200) {
          setActivityLists(response.data);
          setCurrentPage(currentP);
          setNextPage(currentP + 1);
          setPreviousPage(currentP - 1);
          if (response.data.length === 0) {
            setNextButtonEnable(true);
            setPrevButtonEnable(false);
          }
          if (previousPage >= 1) {
            setPrevButtonEnable(false);
          }
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (agencyId) {
      getHealthApplyAgencyId();
    }
  }, []);

  const handleDetailsClick = (data) => {
    setSelectedActivity(data);
  };

  const handleEditClick = (data) => {
    setSelectedActivity(data);
  };

  const handleDownloadExcel = () => {
    if (!healthCareTableRef.current) {
      setLoader_excel(false);
      return;
    }
    setLoader_excel(true);
    // Access the table and retrieve the visible rows
    const tableRows = healthCareTableRef.current.querySelectorAll("tbody tr");
    // Prepare data for export with specific columns
    const exportDataSpecificColumns = Array.from(tableRows).map((row) => {
      const columns = row.querySelectorAll("td");
      return {
        EnrollmentYear: columns[0].innerText,
        CustomerInfo: columns[1].innerText,
        Gender: columns[2].innerText,
        DateOfBirth: columns[3].innerText,
        ImmigrationStatus: columns[4].innerText,
        YearlyIncome: columns[5].innerText,
      };
    });
    // Use the prepared data for export
    downloadExcel({
      fileName: "Healthcare_Table",
      sheet: "healthcare_table",
      tablePayload: {
        header: ["EnrollmentYear", "CustomerInfo", "Gender", "DateOfBirth", "ImmigrationStatus", "YearlyIncome"],
        body: exportDataSpecificColumns,
      },
    });
    setLoader_excel(false);
  };
  console.log("selectedActivity", selectedActivity);
  return (
    <>
      <div className="col py-3">
        <div className="content-panel">
          <div className="panel-title mb-5 ">
            <h4>HealthCare Vault</h4>
          </div>

          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link brand-tab ${tab != 2 ? "active" : ""}`}
                id="account-tab"
                data-bs-toggle="tab"
                data-bs-target="#account"
                type="button"
                role="tab"
                aria-controls="account"
                aria-selected="true"
              >
                HealthCare
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link brand-tab ${tab == 2 ? "active" : ""}`}
                id="geo-location1"
                data-bs-toggle="tab"
                data-bs-target="#geo-location"
                type="button"
                role="tab"
                aria-controls="geo-location"
                aria-selected="false"
              >
                Individual 
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link brand-tab ${tab == 3 ? "active" : ""}`}
                id="group-tab"
                data-bs-toggle="tab"
                data-bs-target="#grouptab"
                type="button"
                role="tab"
                aria-controls="grouptab"
                aria-selected="false"
              >
                Group
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link brand-tab ${tab == 4 ? "active" : ""}`}
                id="life-tab"
                data-bs-toggle="tab"
                data-bs-target="#lifetab"
                type="button"
                role="tab"
                aria-controls="lifetab"
                aria-selected="false"
              >
                Life
              </button>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent">
            <div className={`tab-pane fade ${tab != 2 ? "show active" : ""}`} id="account" role="tabpanel" aria-labelledby="account-tab">
              <div className="filter-box mt-3 mb-2">
                <div className="filter-box-content">
                  <div className="row align-items-center">
                    <div className="col-lg-5">
                      <div className="form-floating">
                        <input
                          type="text"
                          size={500}
                          className="form-control"
                          value={searchItem}
                          onChange={(event) => setSearchItem(event.target.value)}
                          id="floatingInput"
                          placeholder=""
                        />
                        <label htmlFor="floatingInput">Search by first name, last name, email or business name </label>
                      </div>
                    </div>
                    <div className="col-lg-1">
                      <div className="d-grid d-md-flex justify-content-md-left" style={{ height: "50px" }}>
                        <button className="btn btn-primary" type="button" onClick={() => getHealthApplyAgencyId()}>
                          <div className="button-body-for-loader">
                            <IconSearch />
                            {loader && <ThreeDots color="#ffdb58" height={30} width={40} />}
                          </div>
                        </button>
                        <button className="mx-2 btn btn-warning" type="button" onClick={() => getHealthApplyAgencyId("reset")}>
                          <div className="button-body-for-loader">
                            <IconRefresh />
                          </div>
                        </button>

                        <button
                          className="mx-2 btn btn-success"
                          type="button"
                          disabled={(!activityLists?.length > 0 && !loader) || loader_excel || loader}
                          // disabled={
                          //   (!activityLists && !loader) ||
                          //   loader_excel ||
                          //   loader
                          // }
                          onClick={handleDownloadExcel}
                        >
                          <div className="button-body-for-loader">
                            <IconFileExport />
                            {loader_excel && <ThreeDots color="#ffdb58" height={30} width={40} />}
                          </div>
                        </button>
                        <Link to={userData.agentId == null ? `/HealthForm/${userData.agencyName}` : `/HealthForm/${userData.agencyName}/${userData.userId}`} target="_blank">
                          <button className="mx-2 h-100 btn btn-primary" type="button">
                            <div className="button-body-for-loader">
                              <IconCirclePlus />
                            </div>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="data-box ">
                <div className="activity-status-table">
                  <div className="table-responsive">
                    <table className="table activity-status-table table-striped" ref={healthCareTableRef}>
                      <thead>
                        <tr>
                          <th scope="col">Enrollment Year</th>
                          <th scope="col">Customer Info</th>
                          <th scope="col">Gender</th>
                          <th scope="col">Date Of Birth</th>
                          <th scope="col">Immigration Status</th>
                          <th scope="col">Yearly Income</th>
                          <th scope="col">Assigned To</th>
                          <th scope="col">Created Date</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody id="body-render">
                        {activityLists?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <p> {item.enrollmentYear}</p>
                            </td>
                            <td>
                              <div className="cust-info">
                                <h6>
                                  {" "}
                                  {item.firstName} {item.lastName}
                                </h6>
                                <p> {item.phoneNumber} </p>
                                <p> {item.emailId} </p>
                              </div>
                            </td>
                            <td>
                              <div className="cust-info">
                                <p>{item.gender}</p>
                              </div>
                            </td>
                            <td>
                              <div className="last-received-date-time">
                                <p>{showDateFormat(item.dateOfBirth)}</p>
                              </div>
                            </td>

                            <td>
                              <p> {item.immigratonStatus}</p>
                            </td>
                            <td>
                              <p> ${item.annualInome}</p>
                            </td>
                            <td>
                              <p> {item.agentName || "-"}</p>
                            </td>
                            <td>
                              <p>{showDateFormat(item.createdAt)} </p>
                            </td>
                            <td>
                              {/* <a data-bs-toggle="offcanvas" onClick={() => handleDetailsClick(item)} data-bs-target="#healthcare-view" href="#">
                                <i className="bi bi-file-earmark"></i>
                              </a>
                              &nbsp; */}
                              <a data-bs-toggle="offcanvas" onClick={() => handleEditClick(item)} data-bs-target="#editHealthcare" href="#">
                                <i className="bi bi-pencil-square"></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                        {!activityLists && !loader && (
                          <tr>
                            <td colSpan={7} className="text-center text-warning">
                              Data Not found
                            </td>
                          </tr>
                        )}
                        {loader && (
                          <td colSpan={7} className="text-center">
                            <ThreeDots
                              height="40"
                              width="80"
                              color="#4fa94d"
                              ariaLabel="bars-loading"
                              wrapperStyle={{
                                justifyContent: "center",
                                paddingBottom: "10px",
                              }}
                              wrapperClass=""
                              visible={true}
                            />
                          </td>
                        )}
                      </tbody>
                    </table>

                    <nav aria-label="Page navigation example" className="pull-right">
                      <ul className="pagination">
                        <li className="page-item">
                          <button className="page-link" onClick={() => getHealthApplyAgencyId("prev")} disabled={pervButtonEnable}>
                            Previous
                          </button>
                        </li>
                        <li className="page-item">
                          <button className="page-link" onClick={() => getHealthApplyAgencyId("next")} disabled={nextButtonEnable}>
                            Next
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className={`tab-pane fade ${tab == 2 ? "show active" : ""}`} id="geo-location" role="tabpanel" aria-labelledby="account-tab">
              <Leads />
            </div>
            <div className={`tab-pane fade ${tab == 3 ? "show active" : ""}`} id="grouptab" role="tabpanel" aria-labelledby="grouptab">
             <Group/>
            </div>
            <div className={`tab-pane fade ${tab == 4 ? "show active" : ""}`} id="lifetab" role="tabpanel" aria-labelledby="lifetab">
              <Life/>
            </div>
          </div>
        </div>
      </div>
      <ViewHealthcare selectedHealthcare={selectedActivity} />
      <EditHealthcare selectedHealthcare={selectedActivity} />
    </>
  );
};
export default HealthCare;
